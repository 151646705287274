/* eslint-disable */
import React, { Fragment, useEffect, useState, Dispatch, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, Select, MenuItem, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ActionButtons from './ActionButtons';
import { get } from 'lodash';
import { saveKamReviewDetails, getKamDetails, } from '../../../store/actions/onboard.actions';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from "moment-timezone";
import { deleteKamReviewsDocument, resetValues, updateDocument, changeCustomerType, downloadCategoryCertificate } from '../../../store/actions/onboard.actions';
import DeleteDialog from '../../../common/components/deleteDialog';
import Snackbar from '../../../common/components/CreatedEnquirySnackbar';
import { KamReviewValidationMessages } from '../../../constants/messages';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FullscreenLoader from '../../../common/components/FullscreenLoader';
import { getIndustryType } from '../../../store/actions/onboard.actions';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Prompt, useHistory } from 'react-router-dom';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const AddNewDealerKamReview = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openDeleteDialogkam, setOpenDeleteDialogkam] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [loading, setLoading] = React.useState<any>(false);
  const [setSave, SetSave] = React.useState<boolean>(false);
  const [setSize, SetSize] = React.useState<string>("");
  const [setSizeError, SetSizeError] = React.useState<boolean>(false);
  const [invalidSetSize, setInvalidSetSize] = React.useState<boolean>(false);
  const [setSizeValidationMessage, SetSizeValidationMessage] = React.useState<string>("");
  const [setStock, SetStock] = React.useState<string>("");
  const [setStockError, SetStockError] = React.useState<boolean>(false);
  const [invalidSetStock, setInvalidSetStock] = React.useState<boolean>(false);
  const [setStockValidationMessage, SetStockValidationMessage] = React.useState<string>("");
  const [setNumberOfStaff, SetNumberOfStaff] = React.useState<string>("");
  const [setNumberOfStaffError, SetNumberOfStaffError] = React.useState<boolean>(false);
  const [invalidSetNumberOfStaff, setInvalidSetNumberOfStaff] = React.useState<boolean>(false);
  const [setNumberOfStaffValidationMessage, SetNumberOfStaffValidationMessage] = React.useState<string>("");
  const [setSalesToCustomer, SetSalesToCustomer] = React.useState<string>("");
  const [setSalesToCustomerError, SetSalesToCustomerError] = React.useState<boolean>(false);
  const [invalidSetSalesToCustomer, setInvalidSetSalesToCustomer] = React.useState<boolean>(false);
  const [setSalesToCustomerValidationMessage, SetSalesToCustomerValidationMessage] = React.useState<string>("");
  const [setSalesToCustomerCurrencyType, SetSalesToCustomerCurrencyType] = React.useState<any>("AED");
  const [setSalesToCustomerCurrencyTypeError, SetSalesToCustomerCurrencyTypeError] = React.useState<boolean>(false);
  const [invalidSetSalesToCustomerCurrencyType, setInvalidSetSalesToCustomerCurrencyType] = React.useState<boolean>(false);
  const [setSalesToCustomerCurrencyTypeValidationMessage, SetSalesToCustomerCurrencyTypeValidationMessage] = React.useState<string>("");
  const [setSalesSince, SetSalesSince] = React.useState<any>(null);
  const [setSalesSinceError, SetSalesSinceError] = React.useState<boolean>(false);
  const [invalidSetSalesSince, setInvalidSetSalesSince] = React.useState<boolean>(false);
  const [setSalesSinceValidationMessage, SetSalesSinceValidationMessage] = React.useState<string>("");
  const [setPaymentHistory, SetPaymentHistory] = React.useState<string>("");
  const [setPaymentHistoryError, SetPaymentHistoryError] = React.useState<boolean>(false);
  const [invalidSetPaymentHistory, setInvalidSetPaymentHistory] = React.useState<boolean>(false);
  const [setPaymentHistoryValidationMessage, SetPaymentHistoryValidationMessage] = React.useState<string>("");
  const [setGrowthPlan, SetGrowthPlan] = React.useState<string>("");
  const [setGrowthPlanError, SetGrowthPlanError] = React.useState<boolean>(false);
  const [invalidSetGrowthPlan, setInvalidSetGrowthPlan] = React.useState<boolean>(false);
  const [setGrowthPlanValidationMessage, SetGrowthPlanValidationMessage] = React.useState<string>("");
  const [setPictureOfHo, SetPictureOfHo] = React.useState<any>([]);
  const [setPictureOfHoError, SetPictureOfHoError] = React.useState<boolean>(false);
  const [invalidSetPictureOfHo, setInvalidSetPictureOfHo] = React.useState<boolean>(false);
  const [setPictureOfHoValidationMessage, SetPictureOfHoValidationMessage] = React.useState<string>("");
  const [setSapCustomerId, SetSapCustomerId] = React.useState<any>(null);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState<any>();
  const [companyIndustrySector, setCompanyIndustrySector] = React.useState<any>('');
  const [selectedIndustryTypeError, setSelectedIndustryTypeError] = React.useState<boolean>(false);
  const [invalidSelectedIndustryType, setInvalidSelectedIndustryType] = React.useState<boolean>(false);
  const currencies = ["AED"];
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [error, setError] = React.useState<any>(null);
  const [invalidDate, setInvalidDate] = React.useState<boolean>(false);
  const [dealerDetails, setDealerDetails] = React.useState<any>();
  const { customerDetails }: any = useSelector((state: any) => state.onboard);
  // const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const { getIndustryTypeAPI }: any = useSelector((state: any) => state.onboard);
  const sapCustomerId = dealerDetails && dealerDetails.data && isExistingUser
    ? dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId : customerDetails && customerDetails.data && customerDetails.data.customerInformation.customerCode;
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const { selectedNewDealer, generateB2bId }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(generateB2bId, "data.createdByB2bUserId", "");
  // let sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [kamReviewDetailsObj, setKamReviewDetailsObj] = React.useState<any>([
    {
      size: "",
      stock: "",
      numberOfStaff: "",
      growthPlan: "",
      salesSince: "",
      paymentHistory: "",
      salesToCutomerCurrencyType: "",
      salesToCutomer: "",
      pictureOfHo: [{}],
      // companyIndustrySector:"",
      addedBy: "",
      lastUpdateDate: ""
    }
  ])
  const [customerAgreement, setCustomerAgreement] = React.useState<any>([]);
  const [categoryCertificate, setCategoryCertificate] = React.useState<any>([]);
  const [annexureToAgreement, setAnnexureToAgreement] = React.useState<any>([]);
  const [deleteDocumentRemovalType, setDeleteDocumentRemovalType] = React.useState<any>("");
  const customerFirstName = sessionStorage.getItem('customerFirstName');
  const userType: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType) || "";

  // ON CHANGE SELECT DEALER TYPE 
  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  // ON CHANGE OF DEALER TYPE 
  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  // ON CHANGE OPEN CLOSE - SUCCESS SNACKBAR
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  // ON CHANGE OPEN CLOSE - ERORR SNACKBAR
  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEdited(false);
  };

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
  };

  const handleopenCloseCustomerType = (open?: any) => {
    setOpenDeleteDialogkam(open ? true : false);
  };

  const onClickEditAddress = async () => {
    setIsEdited(true);
    setOpen(true);
  }

  // ON CLICK OF TAB - API DISPATCH
  useEffect(() => {
    loadKamDetails();
    dispatch(getIndustryType(sessionStorage.getItem('customerOnboardingType')));
  }, []);

  // TO GET THE DETAILS OF KAM REVIEW TAB - API INTEGRATION
  const loadKamDetails = async () => {
    setLoading(true);
    let response: any = await dispatch(getKamDetails(b2bUserId, 'kamReview'));
    if (response) {
      setLoading(false);
      if (response.data) {
        KamReviewDetails(response);
      }
    }
  }

  const deleteCustomerData = async () => {
    let data: any = await dispatch(changeCustomerType({ b2bUserId: b2bUserId }));
    if (data && data.message && data.message.status === 'S') {
      history.push('/dealers');
    } else {
      setSuccessMessage(data.error);
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
    }
  }

  // ON CLICK OF DELETE - DIALOG OPEN & CLOSE FUNCTIONALITY
  function deleteDocumentDialog(type: any) {
    setDeleteMessage("Are you sure you want to delete this document ?");
    // setOpenDeleteDialog(true);
    setOpenDeleteDialog(true);
    setDeleteDocumentRemovalType(type);
  }

  // ONCHANGE OF SELECT INDUSTRY TYPE
  const onChangeIndustryType = (e: any) => {
    setCompanyIndustrySector(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setSelectedIndustryTypeError(true);
    }
    else {
      setSelectedIndustryTypeError(false);
    }
  }

  // UPDATED DATA - BY USER ON CLICK OF KAM REVIEW TAB
  function KamReviewDetails(data: any) {
    if (data.data.kamReview) {
      let duplicate = data.data.kamReview;
      if (data.data.sapCustomerId) {
        SetSapCustomerId(data.data.sapCustomerId);
      }
      if (duplicate.size) {
        SetSize(duplicate.size);
      }
      if (duplicate.stock) {
        SetStock(duplicate.stock);
      }
      if (duplicate.numberOfStaff) {
        SetNumberOfStaff(duplicate.numberOfStaff)
      }
      if (duplicate.salesToCutomer) {
        SetSalesToCustomer(duplicate.salesToCutomer);
      }
      if (duplicate.salesToCutomerCurrencyType) {
        SetSalesToCustomerCurrencyType(duplicate.salesToCutomerCurrencyType)
      }
      if (duplicate.salesSince) {
        SetSalesSince(duplicate.salesSince);
      }
      if (duplicate.paymentHistory) {
        SetPaymentHistory(duplicate.paymentHistory);
      }
      if (duplicate.companyIndustrySector) {
        setCompanyIndustrySector(duplicate.companyIndustrySector);
      }
      if (duplicate.growthPlan) {
        SetGrowthPlan(duplicate.growthPlan);
      }
      if (duplicate.pictureOfHo && duplicate.pictureOfHo.length) {
        let pictures = duplicate.pictureOfHo.filter((data: any) => data.documentId != null && data.documentId)
        SetPictureOfHo(pictures);
      }
      if (duplicate.customerAgreement && duplicate.customerAgreement.length) {
        let customerAgreement = duplicate.customerAgreement.filter((data: any) => data.documentId != null && data.documentId)
        setCustomerAgreement(customerAgreement);
      }
      if (duplicate.categoryCertificate && duplicate.categoryCertificate.length) {
        let categoryCertificate = duplicate.categoryCertificate.filter((data: any) => data.documentId != null && data.documentId)
        setCategoryCertificate(categoryCertificate);
      }
      if (duplicate.annexureToAgreement && duplicate.annexureToAgreement.length) {
        let annexureToAgreement = duplicate.annexureToAgreement.filter((data: any) => data.documentId != null && data.documentId)
        setAnnexureToAgreement(annexureToAgreement);
      }
    }
  }

  // ON CLICK OF SAVE & NEXT - KAM REVIEW TAB VALIDATIONS
  function saveValidation(type: any) {
    SetSave(true);
    let validationSuccess = true;

    if (!setSize || setSizeError) {
      SetSizeError(true);
      validationSuccess = false
    }
    else {
      SetSizeError(false);
    }

    if (!setStock || setStockError) {
      SetStockError(true);
      validationSuccess = false;
    }
    else {
      SetStockError(false);
    }
    if (!setNumberOfStaff || setNumberOfStaffError) {
      SetNumberOfStaffError(true);
      validationSuccess = false;
    }
    else {
      SetNumberOfStaffError(false);
    }
    if (!setSalesToCustomer || setSalesToCustomerError) {
      SetSalesToCustomerError(true);
      validationSuccess = false;
    }
    else {
      SetSalesToCustomerError(false);
    }
    if (!setSalesToCustomerCurrencyType || setSalesToCustomerCurrencyTypeError) {
      SetSalesToCustomerCurrencyTypeError(true);
      validationSuccess = false;
    }
    else {
      SetSalesToCustomerCurrencyTypeError(false);
    }
    if (!setPaymentHistory || setPaymentHistoryError) {
      SetPaymentHistoryError(true);
      validationSuccess = false;
    }
    else {
      SetPaymentHistoryError(false);
    }
    if (!setGrowthPlan || setGrowthPlanError) {
      SetGrowthPlanError(true);
      validationSuccess = false;
    }
    else {
      SetGrowthPlanError(false);
    }
    if (!companyIndustrySector && companyIndustrySector == "" || companyIndustrySector == undefined) {
      setSelectedIndustryTypeError(true);
      validationSuccess = false;
    }
    else {
      setSelectedIndustryTypeError(false);
    }
    if (!setSalesSince || setSalesSinceError === undefined || setSalesSince == "Invalid date") {
      SetSalesSinceError(true);
      validationSuccess = false;
    }
    else {
      SetSalesSinceError(false);
    }
    let selectedPO: any = [];
    if (setPictureOfHo && setPictureOfHo.length > 0) {
      setPictureOfHo.map((po: any) => {
        if (po.documentId && po.documentId.id) {
          selectedPO.push({ documentId: po.documentId.id })
        }
      })
    }
    if (setPictureOfHo.length == 0) {
      validationSuccess = false;
    }

    let selectedCustomerAgreement: any = [];
    if (customerAgreement && customerAgreement.length > 0) {
      customerAgreement.map((po: any) => {
        if (po.documentId && po.documentId.id) {
          selectedCustomerAgreement.push({ documentId: po.documentId.id })
        }
      })
    }

    let selectedAnnexureToAgreement: any = [];
    if (annexureToAgreement && annexureToAgreement.length > 0) {
      annexureToAgreement.map((po: any) => {
        if (po.documentId && po.documentId.id) {
          selectedAnnexureToAgreement.push({ documentId: po.documentId.id })
        }
      })
    }

    let kamReviewPayload = {
      size: setSize,
      stock: setStock,
      numberOfStaff: setNumberOfStaff,
      growthPlan: setGrowthPlan,
      salesSince: setSalesSince,
      paymentHistory: setPaymentHistory,
      salesToCutomerCurrencyType: setSalesToCustomerCurrencyType,
      salesToCutomer: setSalesToCustomer,
      pictureOfHo: selectedPO,
      customerAgreement: selectedCustomerAgreement,
      annexureToAgreement: selectedAnnexureToAgreement,
      companyIndustrySector: companyIndustrySector,
      addedBy: sessionStorage.getItem('webApplicationLoginId'),
      lastUpdateDate: moment(new Date()).utc().format()
    }
    setKamReviewDetailsObj(kamReviewPayload);
    let finalPayload = {
      b2bUserId: b2bUserId,
      sapCustomerId: sapCustomerId,
      kamReview: kamReviewPayload
    }
    if (validationSuccess) {
      saveKamReview(finalPayload);
      return true;
    } else {
      showErrorMessage('Please fill all mandatory fields');
      handleopenCloseErrorSnackbar(true);
    }
  }

  // ON CLICK OF SAVE & NEXT - KAM REVIEW TAB - DISPATCH SAVE API
  const saveKamReview = async (payload: any) => {
    setLoading(true);
    let response: any = await dispatch(saveKamReviewDetails(payload));
    if (response && response.data) {
      setDataUpdated(false);
      setOpenSnackbar(true);
      SetSave(false);
      setSuccessMessage(response.message.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    }
    setLoading(false);
  }

  const downloadCertificate = async () => {
    let payload = {
      "customerName": userType === "FSE" && sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Workshop" ? ("New Workshop") : userType === "FSE" && sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Fleet" ? ("New Fleet") : userType === "FSE" && sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" ? ("New Government") : sessionStorage.getItem('customerType') === 'existing' ? customerFirstName : ("New Dealer"),
      "currentYear": new Date().getUTCFullYear()
    }
    let responseData: any = await dispatch(downloadCategoryCertificate(payload))

    if (responseData && responseData.status === 400) {

    } else {
      let ele: any = JSON.parse(responseData && responseData.data)
      // const tempDiv = document.createElement('div');

      // tempDiv.innerHTML = ele;
      // document.body.appendChild(tempDiv);
      // const canvas = await html2canvas(tempDiv, { useCORS: true });
      // const imgData = canvas.toDataURL('image/png');
      // const pdf = new jsPDF('p', 'mm', 'a4');
      // pdf.addImage(imgData, 'PNG', 0, 0, 210, (canvas.height * 210) / canvas.width);
      // pdf.save('Certificate.pdf');
      // document.body.removeChild(tempDiv);

      // const tempDiv = document.createElement('div');
      // tempDiv.innerHTML = ele;
      // tempDiv.style.position = 'absolute';
      // tempDiv.style.top = '-9999px';
      // document.body.appendChild(tempDiv);
      // html2canvas(tempDiv, { useCORS: true, scale: 2 }).then((canvas) => {
      //   const imgData = canvas.toDataURL('image/png');
      //   const pdf = new jsPDF();
      //   // const imgWidth = 210;
      //   // let imgHeight = (canvas.height * imgWidth) / canvas.width;
      //   // pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      //   pdf.addImage(imgData, 'PNG', 0, 0, 210, 290)
      //   pdf.save('Certificate.pdf');
      //   document.body.removeChild(tempDiv);
      //   setLoading(false);

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = ele;
        tempDiv.style.width = '800px';
        tempDiv.style.position = 'absolute';
        tempDiv.style.top = '-9999px';
        document.body.appendChild(tempDiv);
        html2canvas(tempDiv, { scale: 2, useCORS: true }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdfDOC = new jsPDF();
          const width = pdfDOC.internal.pageSize.getWidth();
          let height = pdfDOC.internal.pageSize.getHeight();
          pdfDOC.addImage(imgData, 'PNG', 0, 0, width, height);
          pdfDOC.save('certificate.pdf');
          document.body.removeChild(tempDiv);
          setLoading(false);
        });
      }
  }

    // ON CLICK OF BROWSE FILE DOCUMENTS - FUNCTIONALITY & VALIDATIONS
    const onClickBrowseFileasync = async (type: any, item: any) => {
      setLoading(true);
      setDataUpdated(true);
      showErrorMessage(null);
      setSuccessMessage(null);
      handleopenCloseSnackbar(false);
      handleopenCloseErrorSnackbar(false);
      const name = item && item.name ? item.name : '',
        doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),

        doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc'],
        doctExtensionsforHO = ['jpg', 'png'],
        canUploadFile = (type === 'annexureToAgreement' || type === 'customerAgreement') && type !== 'pictureOfHo' ? doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1 : doctExtensionsforHO.indexOf(doctExtension.toLowerCase()) !== -1;
      if (!canUploadFile) {
        showErrorMessage('Invalid File type format');
        handleopenCloseErrorSnackbar(true);
        setLoading(false);
        return;
      }
      if ((type !== 'annexureToAgreement' && type === 'customerAgreement' && type !== 'pictureOfHo')) {
        if ((item && item.size < 25000000 && customerAgreement.length < 10)) {
          let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;

          const customHeaders = {
            "Accept": "application/json",
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": "",
            "Accept-Language": "EN",
          };

          let docType = '';
          if (type === 'customerAgreement') { docType = 'customerAgreement' }

          const formData: any = new FormData();
          formData.append("entityName", "CUSTOMER");
          formData.append("keyAttribute", keyAttribute);
          formData.append("fileUpload", item);
          formData.append("document", 'true');
          formData.append("Attributes", `key=Brand&value=${item}`);
          formData.append("sapCustomerId", sapCustomerId);
          formData.append("docType", docType);
          formData.append("label", type);
          formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
          formData.append("uploadedByRole", 'kam');
          formData.append("uploadedBywebApplicationLoginId", '');
          formData.append("selectedFileName", item.name);
          let documentRes: any = await dispatch(updateDocument(formData, customHeaders));
          if (documentRes && documentRes.message && documentRes.message.status === 'S') {
            props.setDataUpdated(true);
            setLoading(false);
            setSuccessMessage('Document Uploaded Successfully');
            handleopenCloseSnackbar(true);
            setOpenDeleteDialog(false);

            const { label, id } = documentRes.data;

            if (label === "customerAgreement") {
              let po = [...customerAgreement];
              po.push({ documentId: documentRes.data });
              setCustomerAgreement(po);
            }
          } else {
            showErrorMessage('Document upload failed');
            handleopenCloseErrorSnackbar(true);
            setLoading(false);
          }
        } else if (customerAgreement.length >= 10 && type !== 'annexureToAgreement' && type === 'customerAgreement' && type !== 'pictureOfHo' && item && item.size < 25000000) {
          showErrorMessage('Should not exceed 10 files.');
          setLoading(false);
          handleopenCloseErrorSnackbar(true);
        } else if (type !== 'annexureToAgreement' && type === 'customerAgreement' && type !== 'pictureOfHo' && item && item.size > 25000000) {
          showErrorMessage('Please upload less than 25MB file.');
          handleopenCloseErrorSnackbar(true);
          setLoading(false);
        }
      } else if ((type === 'annexureToAgreement' && type !== 'customerAgreement' && type !== 'pictureOfHo')) {
        if ((item && item.size < 5000000 && annexureToAgreement.length < 10)) {
          let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;

          // if (type == "customerAgreement") {
          //   let po = [...customerAgreement];
          //   po.push({ documentId: item });
          //   setCustomerAgreement(po);
          // }

          // if (type == "annexureToAgreement") {
          //   let po = [...annexureToAgreement];
          //   po.push({ documentId: item });
          //   setAnnexureToAgreement(po);
          // }

          const customHeaders = {
            "Accept": "application/json",
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": "",
            "Accept-Language": "EN",
          };

          let docType = '';
          if (type === 'annexureToAgreement') { docType = 'annexureToAgreement' }

          const formData: any = new FormData();
          formData.append("entityName", "CUSTOMER");
          formData.append("keyAttribute", keyAttribute);
          formData.append("fileUpload", item);
          formData.append("document", 'true');
          formData.append("Attributes", `key=Brand&value=${item}`);
          formData.append("sapCustomerId", sapCustomerId);
          formData.append("docType", docType);
          formData.append("label", type);
          formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
          formData.append("uploadedByRole", 'kam');
          formData.append("uploadedBywebApplicationLoginId", '');
          formData.append("selectedFileName", item.name);
          let documentRes: any = await dispatch(updateDocument(formData, customHeaders));
          if (documentRes && documentRes.message && documentRes.message.status === 'S') {
            props.setDataUpdated(true);
            setLoading(false);
            setSuccessMessage('Document Uploaded Successfully');
            handleopenCloseSnackbar(true);
            setOpenDeleteDialog(false);

            const { label, id } = documentRes.data;

            if (label === "annexureToAgreement") {
              let po = [...annexureToAgreement];
              po.push({ documentId: documentRes.data });
              setAnnexureToAgreement(po);
            }
          } else {
            showErrorMessage('Document upload failed');
            handleopenCloseErrorSnackbar(true);
            setLoading(false);
          }
        } else if (annexureToAgreement.length >= 10 && type === 'annexureToAgreement' && type !== 'customerAgreement' && type !== 'pictureOfHo' && item && item.size < 5000000) {
          showErrorMessage('Should not exceed 10 files.');
          setLoading(false);
          handleopenCloseErrorSnackbar(true);
        } else if (type === 'annexureToAgreement' && type !== 'customerAgreement' && type !== 'pictureOfHo' && item && item.size > 5000000) {
          showErrorMessage('Please upload less than 5MB file.');
          handleopenCloseErrorSnackbar(true);
          setLoading(false);
        }
      } else if (type !== 'annexureToAgreement' && type !== 'customerAgreement' && type === 'pictureOfHo') {
        if ((item && item.size < 2000000 && setPictureOfHo.length < 10)) {
          let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
          const customHeaders = {
            "Accept": "application/json",
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": "",
            "Accept-Language": "EN",
          };
          let docType = '';
          if (type === 'pictureOfHo') { docType = 'pictureOfHo' }
          const formData: any = new FormData();
          formData.append("entityName", "CUSTOMER");
          formData.append("keyAttribute", keyAttribute);
          formData.append("fileUpload", item);
          formData.append("document", 'true');
          formData.append("Attributes", `key=Brand&value=${item}`);
          formData.append("sapCustomerId", sapCustomerId);
          formData.append("docType", docType);
          formData.append("label", type);
          formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
          formData.append("uploadedByRole", 'kam');
          formData.append("uploadedBywebApplicationLoginId", '');
          formData.append("selectedFileName", item.name);
          let documentRes: any = await dispatch(updateDocument(formData, customHeaders));
          if (documentRes && documentRes.message && documentRes.message.status === 'S') {
            setDataUpdated(true);
            setLoading(false);
            setSuccessMessage('Document Uploaded Successfully');
            handleopenCloseSnackbar(true);
            setOpenDeleteDialog(false);

            const { label, id } = documentRes.data;
            if (label === "pictureOfHo") {
              let po = [...setPictureOfHo];
              po.push({ documentId: documentRes.data });
              SetPictureOfHo(po);
            }
          } else {
            showErrorMessage('Document upload failed');
            handleopenCloseErrorSnackbar(true);
            setLoading(false);
          }
        } else if (setPictureOfHo.length >= 10 && type !== 'annexureToAgreement' && type !== 'customerAgreement' && type === 'pictureOfHo' && item && item.size < 2000000) {
          showErrorMessage('Should not exceed 10 files.');
          handleopenCloseErrorSnackbar(true);
          setLoading(false);
        } else if (type !== 'annexureToAgreement' && type !== 'customerAgreement' && type === 'pictureOfHo' && item && item.size > 2000000) {
          showErrorMessage('Please upload less than 2MB file');
          handleopenCloseErrorSnackbar(true);
          setLoading(false);
        }
      }
      setLoading(false);
    }

    // ON CLICK OF DELETE ICON - DELETE API INTEGRATION FOR DOCUMENTS
    const removeDocument = async (type: any) => {
      setDataUpdated(true);
      dispatch(resetValues());
      let po = [...setPictureOfHo]
      let customerAgreementDoc = [...customerAgreement]
      let annexureToAgreementDoc = [...annexureToAgreement]

      const obj = {
        "type": type
      }
      if (type === "kamReview") {
        const documentId = po[currentIndex].documentId && po[currentIndex].documentId.id;
        let response: any = await dispatch(deleteKamReviewsDocument(b2bUserId, documentId, obj));
        if (response && response.message && response.message.status === 'S') {
          po[currentIndex].documentId = null;
          po.splice(currentIndex, 1);
          SetPictureOfHo(po);
          setSuccessMessage(response.message.message);
          handleopenCloseSnackbar(true);
          setOpenDeleteDialog(false);
        } else {
          showErrorMessage(response.error);
          handleopenCloseErrorSnackbar(true);
        }
      } else if (type === "customerAgreement") {
        const documentId = customerAgreementDoc[currentIndex].documentId && customerAgreementDoc[currentIndex].documentId.id;
        let response: any = await dispatch(deleteKamReviewsDocument(b2bUserId, documentId, obj));
        if (response && response.message && response.message.status === 'S') {
          customerAgreementDoc[currentIndex].documentId = null;
          customerAgreementDoc.splice(currentIndex, 1);
          setCustomerAgreement(customerAgreementDoc);
          setSuccessMessage(response.message.message);
          handleopenCloseSnackbar(true);
          setOpenDeleteDialog(false);
        } else {
          showErrorMessage(response.error);
          handleopenCloseErrorSnackbar(true);
        }
      } else if (type === "annexureToAgreement") {
        const documentId = annexureToAgreementDoc[currentIndex].documentId && annexureToAgreementDoc[currentIndex].documentId.id;
        let response: any = await dispatch(deleteKamReviewsDocument(b2bUserId, documentId, obj));
        if (response && response.message && response.message.status === 'S') {
          annexureToAgreementDoc[currentIndex].documentId = null;
          annexureToAgreementDoc.splice(currentIndex, 1);
          setAnnexureToAgreement(annexureToAgreementDoc);
          setSuccessMessage(response.message.message);
          handleopenCloseSnackbar(true);
          setOpenDeleteDialog(false);
        } else {
          showErrorMessage(response.error);
          handleopenCloseErrorSnackbar(true);
        }
      }
    }

    // ON CHANGE OF SIZE - VALIDATIONS
    function onChangeSize(event: any, type: string) {
      setDataUpdated(true);
      let size = event.target.value;
      SetSize(size);
      if (size.length > 50) {
        SetSizeError(true);
        SetSizeValidationMessage(KamReviewValidationMessages.SIZE)
      } else {
        SetSizeError(false);
      }
    }

    // ON CHANGE OF STOCK - VALIDATIONS
    function onChangeStock(event: any, type: string) {
      setDataUpdated(true);
      let stock = event.target.value
      SetStock(stock);
      if (stock.length > 50) {
        SetStockError(true);
        SetStockValidationMessage(KamReviewValidationMessages.STOCK)
      } else {
        SetStockError(false);
      }
    }

    // ON CHANGE OF NUMBER OF STAFF - VALIDATIONS
    function onChangeNumberOfStaff(event: any, type: string) {
      setDataUpdated(true);
      let numberOfStaff = event.target.value;
      SetNumberOfStaff(numberOfStaff);
      if (numberOfStaff.length > 100) {
        SetNumberOfStaffError(true);
        SetNumberOfStaffValidationMessage(KamReviewValidationMessages.NO_OF_STAFF)
      } else {
        SetNumberOfStaffError(false);
      }
    }

    // ON CHANGE OF SALES TO CUSTOMER - VALIDATIONS
    function onChangeSalesToCustomer(event: any, type: string) {
      setDataUpdated(true);
      let salesToCustomer = event.target.value;
      SetSalesToCustomer(salesToCustomer);
      // validateFormFields(type, salesToCustomer);
      if (salesToCustomer.length > 100) {
        SetSalesToCustomerError(true);
        SetSalesToCustomerValidationMessage(KamReviewValidationMessages.SALES_TO_CUSTOMER)
      } else {
        SetSalesToCustomerError(false);
      }
    }

    //  ON CHANGE OF PAYMENT HISTORY - VALIDATIONS
    function onChangePaymentHistory(event: any, type: string) {
      setDataUpdated(true);
      let paymentHistory = event.target.value
      SetPaymentHistory(paymentHistory);
      if (paymentHistory.length > 500) {
        SetPaymentHistoryError(true);
        SetPaymentHistoryValidationMessage(KamReviewValidationMessages.PAYMENT_HISTORY)
      } else {
        SetPaymentHistoryError(false);
      }
    }

    //  ON CHANGE OF GROWTH PLAN - VALIDATIONS
    function onChangeGrowthPlan(event: any, type: string) {
      setDataUpdated(true);
      let growthPlan = event.target.value;
      SetGrowthPlan(growthPlan);
      if (growthPlan.length > 500) {
        SetGrowthPlanError(true);
        SetGrowthPlanValidationMessage(KamReviewValidationMessages.GROWTH_PLAN)
      } else {
        SetGrowthPlanError(false);
      }
    }

    //  ON CHANGE OF SALES SINCE - VALIDATIONS
    function onChangeSalesSince(event: any, type: string) {
      setDataUpdated(true);
      let salesSince = event.target.value;
      SetSalesSince(salesSince);
      // validateFormFields(type, salesSince);
      if (salesSince.length) {
        SetSalesSinceError(true);
        SetSalesSinceValidationMessage(KamReviewValidationMessages.SALES_SINCE)
      } else {
        SetSalesSinceError(false);
      }
    }

    const handleNextClick = async () => {
      if (saveValidation('next')) {
        props.setTabValue(4);
      }
    }

    // ON CLCIK OF BACK BUTTON FUNCTIONALITY
    const handleBackClick = () => {
      if (dataUpdated) {
        setDeleteMessage('Do you wish to proceed without saving the data?')
        setOpenDeleteDialog(true);
      } else {
        props.setTabValue(2)
      }
    }

    // ON CHANGE OF DATE - DATE PICKER FUNCTIONALITY & VALIDATIONS
    function datePickerValid(event: any, state: any) {
      if (event === "") {
        setError("");
        state(true);
      } else if (event === "Invalid Date Format") {
        setError("Invalid Date Format");
        state(true);
      } else {
        var varDate = new Date(setSalesSince);
        var today = new Date();
        if (varDate > today) {
          setInvalidDate(true);
          setError('Date should be less than today date.')
          SetSalesSinceError(true);
        }
        else {
          SetSalesSinceError(false);
          state(false);
          setError(null)
        }
      }
    }

    return (
      <Fragment>
        <Prompt
          when={dataUpdated}
          message='Do you wish to proceed without saving the data?'
        />
        {loading && <FullscreenLoader />}
        <div className="row-space-start col-12 p-3">
          <>
            <div className="col-12 m-0 p-0">
              <div className="row mt-0 mb-1">
                <h6 className="dealer-table-content mb-1 px-4 mt-3 mb-0">KAM Review</h6>
              </div>
              <div className="row mb-1 mt-0 px-3">
                <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-1 mt-2 row-space-between ">
                  <TextField id="size"
                    required
                    type="text"
                    value={setSize}
                    error={setSizeError}
                    helperText={setSizeError && setSize && <span style={{ color: 'red' }}>{setSizeValidationMessage}</span>}
                    onChange={(e: any) => onChangeSize(e, "size")}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Size" variant="outlined">
                  </TextField>
                </div>
                <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-1 mt-2 row-space-between">
                  <TextField id="stockQuantityValue"
                    required
                    value={setStock}
                    error={setStockError}
                    helperText={setStockError && setStock && <span style={{ color: 'red' }}>{setStockValidationMessage}</span>}
                    onChange={(e: any) => onChangeStock(e, "stock")}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Stock(quantity & value)" variant="outlined" margin="dense">
                  </TextField>
                </div>
                <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-1 mt-2 row-space-between">
                  <TextField id="noOfStaff"
                    required
                    value={setNumberOfStaff}
                    error={setNumberOfStaffError}
                    helperText={setNumberOfStaffError && setNumberOfStaff && <span style={{ color: 'red' }}>{setNumberOfStaffValidationMessage}</span>}
                    onChange={(e: any) => onChangeNumberOfStaff(e, "numberOfStaff")}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="No. of Staff" variant="outlined" margin="dense">
                  </TextField>
                </div>
              </div>
              <div className="row mb-1 mt-2 px-3">
                <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between salestoCustomerKam">
                  <TextField
                    select id="salesToCustomer"
                    defaultValue={setSalesToCustomerCurrencyType}
                    value={setSalesToCustomerCurrencyType} className="col-sm-2 commonRoundedInputs-dropdown"
                    margin="dense"
                    error={setSalesToCustomerCurrencyTypeError}
                    helperText={setSalesToCustomerCurrencyTypeError && setSalesToCustomerCurrencyType && <span style={{ color: 'red' }}>{setSalesToCustomerCurrencyTypeValidationMessage}</span>}
                    variant="outlined" style={{ width: '74px' }}>
                    {currencies.map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>))}
                    {/* <MenuItem>{setSalesToCustomerCurrencyType}</MenuItem> */}
                  </TextField>
                  <TextField id="salesToCustomer"
                    required
                    value={setSalesToCustomer}
                    error={setSalesToCustomerError}
                    helperText={setSalesToCustomerError && setSalesToCustomer && <span style={{ color: 'red' }}>{setSalesToCustomerValidationMessage}</span>}
                    onChange={(e: any) => onChangeSalesToCustomer(e, "salesToCustomer")}
                    size="small" className="col-sm-10 commonRoundedInputs-textfield" style={{ maxHeight: '36px' }}
                    label="Sales to customer in last 6 months" variant="outlined" margin="dense" />
                </div>
                <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-1 row-space-between">
                  <KeyboardDatePicker required id="salesSince"
                    disableFuture
                    views={["year"]}
                    openTo="year"
                    autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                    onError={(event, v) => datePickerValid(event, setInvalidDate)}
                    format="yyyy" margin="normal" label="After Sales Since" value={setSalesSince}
                    onChange={(date: any | null) => {
                      setDataUpdated(true);
                      if (date) {
                        let newDate = moment(date).format('YYYY');
                        SetSalesSince(newDate);
                        if (newDate != "Invalid Date") {
                          SetSalesSinceError(false)
                        }
                      }
                      else {
                        SetSalesSince("");
                        SetSalesSinceError(true)
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="rounded-input-dealer w-100 mt-2 cal-icon"
                    error={setSalesSinceError}
                    helperText={invalidDate && setSalesSinceError && <span style={{ color: 'red' }}>{error}</span>}
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-1 row-space-between">
                  <TextField id="paymentHistory"
                    select
                    required value={setPaymentHistory}
                    error={setPaymentHistoryError}
                    helperText={setPaymentHistoryError && setPaymentHistory && <span style={{ color: 'red' }}>{setPaymentHistoryValidationMessage}</span>}
                    onChange={(e: any) => onChangePaymentHistory(e, "paymentHistory")}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Payment History" variant="outlined" margin="dense">
                    <MenuItem key={'good'} value={'good'}>{'Good'}</MenuItem>
                    <MenuItem key={'bad'} value={'bad'}>{'Bad'}</MenuItem>
                    <MenuItem key={'excellent'} value={'excellent'}>{'Excellent'}</MenuItem>
                    <MenuItem key={'newCustomer'} value={'newCustomer'}>{'New Customer'}</MenuItem>
                  </TextField>
                </div>
              </div>
              <div className="row mb-1 mt-1 px-3">
                <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between salestoCustomerKam">
                  <TextField select id="selectIndustry" value={companyIndustrySector}
                    label="Select Industry Type" required variant="outlined" margin="dense"
                    size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo" type="number"
                    error={selectedIndustryTypeError || invalidSelectedIndustryType}
                    helperText={invalidSelectedIndustryType && <span style={{ color: 'red' }}>Please select the industry type.</span>}
                    onChange={(e: any) => { setDataUpdated(true); setCompanyIndustrySector(e.target.value) }}
                  >
                    {getIndustryTypeAPI && getIndustryTypeAPI.data && getIndustryTypeAPI.data.length > 0 &&
                      getIndustryTypeAPI.data[0] && getIndustryTypeAPI.data[0].companyIndustrySector &&
                      getIndustryTypeAPI.data[0].companyIndustrySector.length > 0 && getIndustryTypeAPI.data[0].companyIndustrySector.map((item: any) => (
                        <MenuItem key={item.code} value={item.code}>{item.title} </MenuItem>))}
                  </TextField>
                </div>
                <div className="px-3 col-sm-12 col-md-8 col-sm-8 px-1 pr-2 mb-3 mt-0 row-space-between salestoCustomerKam">
                  <TextField id="growthPlan"
                    required value={setGrowthPlan}
                    error={setGrowthPlanError}
                    helperText={setGrowthPlanError && setGrowthPlan && <span style={{ color: 'red' }}>{setGrowthPlanValidationMessage}</span>}
                    onChange={(e: any) => onChangeGrowthPlan(e, "growthPlan")}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Growth Plan" variant="outlined" margin="dense">
                  </TextField>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="card col-12 m-0 p-0">
          <div className="row px-3 mb-3">
            <div className="col-md-3 col-lg-3 pr-2 mb-4 px-4 pl-0 ">
              <h6 className="document-content pt-3 pb-2">Picture Of HO <span style={{ color: 'grey' }}>*</span></h6>
              <small className="dealer-table-content">Photos(.jpg, .png  and maximum 10 files upto 2 MB can be uploaded)</small>
            </div>
            <div className="col-md-2 col-lg-2 mb-2 pl-0 mt-4 px-4">
              <Button variant="contained"
                component="label" color="secondary"
                className="rounded-button-dealer pl-4 pr-4 mt-0">
                Browse File
                <input type="file" hidden multiple
                  onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFileasync("pictureOfHo", e.target.files[0]) : ""
                  }
                />
              </Button>
              {(!setPictureOfHo || setPictureOfHo.length == 0) && setSave ?
                <div style={{ color: 'red' }}>Please upload atleast one picture</div> : ""}
            </div>
            <div className="col-md-7 col-lg-7 mb-2 pl-0 mt-4">
              {setPictureOfHo && setPictureOfHo.length > 0 && setPictureOfHo.map((file: any, index: any) => (
                <div className="file-name-kam">
                  <span className="cursor-pointer"
                    onClick={() => {
                      window.open(file.documentId.url)
                    }}
                  >{file.documentId && file.documentId.name ? file.documentId.name : file.documentId && file.documentId.selectedFileName}</span>
                  {file.documentId && file.documentId.selectedFileName ? (
                    <IconButton className="p-1" aria-label="delete"
                      onClick={(e: any) => { deleteDocumentDialog("kamReview"); setCurrentIndex(index) }}>
                      <CancelIcon className="file-close-color" fontSize="small" />
                    </IconButton>
                  ) : ''}
                </div>
              ))}
            </div>
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" :
              <div className='card col-12 border-right-0 border border-left-0'>
                <h6 className="page-sub-title pt-3 pb-2 px-3">Customer Agreement Docs<span style={{ color: 'grey' }}></span></h6>
              </div>
            }
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" :
              <>
                <div className="col-12 m-0 p-0 border-top border-bottom">
                  <div className="row px-3 mb-2">
                    <div className="col-md-3 col-lg-3 pr-2 pt-3 mb-4 px-4 pl-0 ">
                      <h6 className="document-content pt-3 pb-2">Customer Agreement <span style={{ color: 'grey' }}></span></h6>
                      <small className="dealer-table-content">('jpg', 'jpeg', 'png', 'pdf', 'doc' and maximum 10 files upto 25 MB can be uploaded)</small>
                    </div>
                    <div className="col-md-2 col-lg-2 pt-2 mb-2 pl-0 mt-4 px-4">
                      <Button variant="contained"
                        component="label" color="secondary"
                        className="rounded-button-dealer pl-4 pr-4 mt-0">
                        Browse File
                        <input type="file" hidden multiple
                          onChange={(e) =>
                            (e.target && e.target.files) ?
                              onClickBrowseFileasync("customerAgreement", e.target.files[0]) : ""
                          }
                        />
                      </Button>
                    </div>
                    <div className="col-md-7 col-lg-7 mb-2 pl-0 mt-3">
                      {customerAgreement && customerAgreement.length > 0 && customerAgreement.map((file: any, index: any) => (
                        <div className="file-name-kam mt-2">
                          <span className="cursor-pointer"
                            onClick={() => {
                              window.open(file.documentId.url)
                            }}
                          >{file.documentId && file.documentId.name ? file.documentId.name : file.documentId && file.documentId.selectedFileName}</span>
                          {file.documentId && file.documentId.selectedFileName ? (
                            <IconButton className="p-1" aria-label="delete"
                              onClick={(e: any) => { deleteDocumentDialog("customerAgreement"); setCurrentIndex(index) }}>
                              <CancelIcon className="file-close-color" fontSize="small" />
                            </IconButton>
                          ) : ''}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className='col-md-6 col-lg-6 col-sm-6 row border-left'>
                    <div className="col-md-4 col-lg-4 col-sm-4 pt-3 pr-2 mb-4 px-4 pl-0 ">
                      <h6 className="document-content pt-3 pb-2">Category Certificate <span style={{ color: 'grey' }}></span></h6>
                      <small className="dealer-table-content">(Silver/Gold/Platinum/Elite)</small>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4 mb-2 pl-0 mt-4">
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4 mb-2 pt-2 pl-0 mt-2">
                      <div className="file-name-kam mt-2">
                        <span className="cursor-pointer"
                          onClick={() => {
                          }}
                        >{"Certificate.Pdf"}</span>
                        <IconButton className="p-1" aria-label="delete"
                          onClick={(e: any) => { downloadCertificate(); }}>
                          <AssignmentReturnedIcon className="file-close-color" fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                  </div> */}
                <div className="col-12 m-0 p-0 border-top">
                  <div className="row px-3 mb-2">
                    <div className="col-md-3 col-lg-3 pr-2 pt-3 mb-4 px-4 pl-0 ">
                      <h6 className="document-content pt-3 pb-2">Annexure To Agreement <span style={{ color: 'grey' }}></span></h6>
                      <small className="dealer-table-content">('jpg', 'jpeg', 'png', 'pdf', 'doc' and maximum 10 files upto 5 MB can be uploaded)</small>
                    </div>
                    <div className="col-md-2 col-lg-2 pt-2 mb-2 pl-0 mt-4 px-4">
                      <Button variant="contained"
                        component="label" color="secondary"
                        className="rounded-button-dealer pl-4 pr-4 mt-0">
                        Browse File
                        <input type="file" hidden multiple
                          onChange={(e) =>
                            (e.target && e.target.files) ?
                              onClickBrowseFileasync("annexureToAgreement", e.target.files[0]) : ""
                          }
                        />
                      </Button>
                    </div>
                    <div className="col-md-7 col-lg-7 mb-2 pl-0 mt-3">
                      {annexureToAgreement && annexureToAgreement.length > 0 && annexureToAgreement.map((file: any, index: any) => (
                        <div className="file-name-kam">
                          <span className="cursor-pointer"
                            onClick={() => {
                              window.open(file.documentId.url)
                            }}
                          >{file.documentId && file.documentId.name ? file.documentId.name : file.documentId && file.documentId.selectedFileName}</span>
                          {file.documentId && file.documentId.selectedFileName ? (
                            <IconButton className="sp-1" aria-label="delete"
                              onClick={(e: any) => { deleteDocumentDialog("annexureToAgreement"); setCurrentIndex(index) }}>
                              <CancelIcon className="file-close-color" fontSize="small" />
                            </IconButton>
                          ) : ''}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            }
            <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
              <div className="col-12 display-flex p-0">
                <div className="col-8 p-0">
                  <div className="row m-0">
                    {/* <div className="col-5 p-0">
                <p className="mt-2 ml-4 shipAdd">Dealer Type</p>
                {dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' ? (
                  <p className="ml-4 mt-2 text-capitalize">{sessionStorage.getItem('customerType')} User</p>
                ) : (
                  <p className="cursor-pointer ml-4 mt-2" onClick={() => { changeDealerType() }}>
                    <span className="document-content text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                      Dealer</span><KeyboardArrowDownIcon />{'  '}<span className="info-sub-header-document">Change Type</span>
                  </p>
                )}
              </div> */}
                    {/* {dealerDetails && dealerDetails.data && dealerDetails.data.status !== 'Submitted' && ( */}
                    <div className="col-4 mt-3 p-0">
                      <Button variant="contained" className="border-left rounded-pill ml-5 w-150p mb-2"
                        onClick={() => saveValidation('save')}
                      >Save Form</Button>
                    </div>
                    {/* )} */}
                  </div>
                </div>
                <div className="col-4 onboard-footer float-right">
                  <div className="p-3 mr-3">
                    <Button variant="contained" className="rounded-pill pl-0 pr-0 w-150p"
                      onClick={() => handleBackClick()}
                    >Back</Button>
                  </div>
                  <div className="p-3 mr-3">
                    <Button variant="contained" color="primary"
                      onClick={() => { handleNextClick(); }}
                      className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2" >{props.showSave ? 'Next' : 'Next'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
        <DeleteDialog
          open={openDeleteDialog}
          text={deleteMessage}
          handleopenClose={(data: any) => {
            if (data === 'delete') {
              removeDocument(deleteDocumentRemovalType);
            }
            else if (data === 'back') {
              props.setTabValue(2);
            }
            if (!data) handleopenClose(false);
          }} />
        <DeleteDialog
          open={openDeleteDialogkam}
          text={deleteMessage}
          handleopenCloseCustomerType={(data: any) => {
            if (dataUpdated) {
              props.setTabValue(0);
              setDataUpdated(false);
            } else {
            }
            if (!data) handleopenCloseCustomerType(false);
          }} />
        <DeleteDialog
          open={confirmDialog}
          text="Are you sure you want to switch from the current Customer type. By doing so, all your saved data will be lost."
          handleopenCloseCustomerType={(data: any) => {
            if (data === 'delete') deleteCustomerData();
            if (!data) {
              handleopenCloseConfirmPopup(false);
            }
          }} />
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} message={successMessage} />
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
      </Fragment >
    )
  }

  export default AddNewDealerKamReview;