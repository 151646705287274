/* eslint-disable */
import { useMsal } from "@azure/msal-react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { signOut, useUserDispatch } from "../../context/UserContext";
import { logout } from "../../store/actions/account.actions";
import lock from "./lock.png"
import logoicon from "../../assets/mainLogoBlue.png";
import { logoutUser } from "../../store/actions/logon.actions";
import _ from "lodash";
import { useSelector } from "react-redux";
import React, { Fragment, Dispatch, useEffect, useCallback } from 'react';
import moment from "moment-timezone";

interface OnboardingErrorPageProps {
    status: string;
    message?: string;
}

export const OnboardingErrorPage = (props: OnboardingErrorPageProps) => {

    const { status, message } = props;
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const dispatch = useDispatch();
    const userDispatch = useUserDispatch();
    const { instance } = useMsal();
    const userType = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType;

    const callLogout = async () => {
        await dispatch(logoutUser());
        await dispatch(logout());
        signOut(userDispatch);
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    }

    let inactive = status === "inactive";
    let noAccess = status === "noAccess";
    let locked = status === "locked";
    let loginError = status === "loginError";
    let expiredLink = status === "expiredLink";
    let userDoesNotExist = status === "noUser";
    let signUpFailed = status === 'signUpFailed';
    let idExpired = status === 'idExpired';
    let sessionFailed = status === 'sessionGenerationFailed';
    let registeredUser = status === "registeredUser";
    let tradepointAccessToCustomer = status === "tradepointAccessToCustomerDenied";
    let subUserBlocked = status === "isReadTandCAccessDenied";

    let blockIntent = status === "“Kindly upload the revised document to regain access to the portal.";

    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            {/* Al-Futtaim Logo */}
            <div className="center-alignment mt-5">
                <img src={logoicon} alt="Logo" className="h-40 mt-1" />
            </div>
            {/* Error Icon */}
            <div className="center-alignment mt-4">
                {(noAccess || locked || inactive) &&
                    <img width="200px" src={lock} alt="lock-indicator" style={{ marginBottom: '50px' }} />}
                {(loginError || userDoesNotExist || signUpFailed || idExpired || sessionFailed) && <div className="user-already-registered-error"><i className="fas fa-user-astronaut"></i></div>}
                {expiredLink && <div className="expired-error">504</div>}
            </div>
            {/* Body Message */}
            {idExpired &&
                <>
                    <div className="center-alignment">
                        <div className="expired-link-message">Your login ID has expired.</div>
                    </div>
                    <div className="center-alignment mt-3">
                        <div className="expired-link-desc">Please contact your administrator.</div>
                    </div>
                </>
            }
            {/* Body Message */}
            {registeredUser &&
                <>
                    <div className="center-alignment">
                        <div className="expired-link-message">{"User is already registered"}</div>
                    </div>
                    <div className="center-alignment mt-3">
                        <div className="expired-link-desc">{props.message}</div>
                    </div>
                </>
            }
            {signUpFailed && <>
                <div className="center-alignment">
                    <div className="expired-link-message">Sign up has failed. Please try again.</div>
                </div>
                <div className="center-alignment mt-3">
                    <div className="expired-link-desc">If the issue persists please contact your administrator.</div>
                </div>
            </>}
            {userDoesNotExist &&
                <>
                    <div className="center-alignment">
                        <div className="expired-link-message">User does not exist.</div>
                    </div>
                    <div className="center-alignment mt-3">
                        <div className="expired-link-desc">Please contact your administrator.</div>
                    </div>
                </>}
            {!tradepointAccessToCustomer && (userType !== "Admin" && !blockIntent) && subUserBlocked === false &&
                <>
                    <div className="center-alignment">
                        <div className="expired-link-message">We are sorry that you no longer have access to the portal.</div>
                    </div>
                    <div className="center-alignment mt-3">
                        <div className="expired-link-desc">Please contact Al-Futtaim Sales Support for more information.</div>
                    </div>
                </>
            }
            {(userType !== "Admin" && blockIntent) &&
                <>
                    <div className="center-alignment">
                        <div className="expired-link-message">We are sorry that you no longer have access to the portal.</div>
                    </div>
                    <div className="center-alignment mt-3">
                        <div className="expired-link-desc">Please contact Al-Futtaim Sales Support for more information.</div>
                    </div>
                </>
            }
            {(subUserBlocked === true && userType !== "Admin" ) &&
                <>
                 <div className="center-alignment mt-4">
                        <img width="200px" src={lock} alt="lock-indicator" style={{ marginBottom: '50px' }} />
                    </div>
                    <div className="center-alignment">
                        <div className="expired-link-message">We are sorry that you no longer have access to the portal.</div>
                    </div>
                    <div className="center-alignment mt-3">
                        <div className="expired-link-desc">Your Admin needs to accept the T&C for portal access.</div>
                    </div>
                </>
            }
            {expiredLink &&
                <>
                    <div className="center-alignment">
                        <div className="expired-link-message">Your link has expired.</div>
                    </div>
                    <div className="center-alignment mt-3">
                        <div className="expired-link-desc">Hi, your login link has expired. Login links expire every 2hrs and can be used once.</div>
                    </div>
                    <div className="center-alignment mt-1">
                        <div className="expired-link-desc">You can create one by clicking on the Request button.</div>
                    </div>
                </>
            }
            {loginError && <div className="center-alignment">
                <div className="expired-link-message">There was a problem logging you in, please try again.</div>
            </div>}
            {sessionFailed && <div className="center-alignment">
                <div className="expired-link-message">There was a problem logging you in{message && `. ${message}`}. Session generation has failed.</div>
            </div>}
            {(noAccess || inactive || locked) &&
                <>
                    <div className="center-alignment">
                        <div className="expired-link-message">Your account {noAccess ? "does not have access." : `is ${locked ? 'locked' : 'inactive'}.`}</div>
                    </div>
                    <div className="center-alignment mt-3">
                        <div className="expired-link-desc">Please contact your administrator.</div>
                    </div>
                </>
            }
            {(!inactive && !noAccess && !locked && !loginError && !expiredLink && !userDoesNotExist && !signUpFailed && !idExpired && !sessionFailed && !registeredUser && subUserBlocked === false) &&
                <>
                    <div className="center-alignment mt-4">
                        <img width="200px" src={lock} alt="lock-indicator" style={{ marginBottom: '50px' }} />
                    </div>
                    <div className="center-alignment">
                        <div className="expired-link-message">Your account has been deactivated.</div>
                    </div>
                    <div className="center-alignment mt-3">
                        <div className="expired-link-desc">{status}</div>
                    </div>
                </>
            }
            {/* Button */}
            {sessionFailed ?
                <div className="center-alignment mt-4">
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        className="rounded-pill go-to-home-button font-size-11"
                        onClick={() => window.location.reload()}
                    >
                        Reload Page
                    </Button>
                </div>
                :
                <div className="center-alignment mt-4">
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        className="rounded-pill go-to-home-button font-size-11"
                        onClick={() => callLogout()}
                    >
                        Back to Login Page
                    </Button>
                </div>
            }
        </div>
    )
}