import { cmsAPIService } from "../../services/cms.service";
import { TERMS_AND_CONDITIONS } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";

export const specialOffersActions = {
  getTermsAndConditions,
  acceptTermsAndConditions
};

export function getTermsAndConditions() {
  return async (dispatch: any) => {
    dispatch(apiStart(TERMS_AND_CONDITIONS.GET_TERMS_AND_CONDITIONS_FETCHING));
    try {
      const response = await cmsAPIService.getTermsAndConditions();
      dispatch(apiSuccess(TERMS_AND_CONDITIONS.GET_TERMS_AND_CONDITIONS_SUCCESS, response.data.articleList));
      return response.data.articleList || [];
    } catch (error) {
      dispatch(apiError(TERMS_AND_CONDITIONS.GET_TERMS_AND_CONDITIONS_ERROR, error));
      return error;
    }
  };
}

export function acceptTermsAndConditions(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(TERMS_AND_CONDITIONS.ACCEPT_TERMS_AND_CONDITIONS_FETCHING));
    try {
      const response = await cmsAPIService.acceptTermsAndConditions(data);
      dispatch(apiSuccess(TERMS_AND_CONDITIONS.ACCEPT_TERMS_AND_CONDITIONS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(TERMS_AND_CONDITIONS.ACCEPT_TERMS_AND_CONDITIONS_ERROR, error));
      return error;
    }
  };
}
