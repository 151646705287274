/* eslint-disable */
import { useSelector } from "react-redux";
import { PREVENT_ACCESS } from "../../constants/restrictedAccessConstants";
import { OnboardingErrorPage } from "./OnboardingErrorPage";
import _ from "lodash";
import React, { Fragment, Dispatch, useEffect, useCallback } from 'react';
import moment from "moment-timezone";

export const AccountRestrictedComponent = (props: any) => {

    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);

    const logonData: any = useSelector((state: any) => state.logon.logonData);

    // const inactive = get(profileDetails, "data.data.customerInfo.isActive", "") === false; 

    const inactive = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.isActive && profileDetails.data.data.customerInfo.isActive === false;

    const isReadTandC = _.get(profileDetails, "data.data.customerInfo.isReadTandC");

    // const isReadTandC = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.isReadTandC && profileDetails.data.data.customerInfo.isReadTandC;

    const tradepointAccessToCustomer = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.isActive && profileDetails.data.data.customerInfo.tradepointAccessToCustomer === false;

    const locked = _.get(profileDetails, "data.data.basicInfo.lockIndicator", false) === true;

    // let featureAccessibility = _.get(profileDetails, "data.data.accessParameter.featureAccessibility", []);

    // const isAdmin = _.get(profileDetails, "data.data.basicInfo.userType", "User") === "Admin"; 
    const blockIntent = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.blockIntent === true;
    const userType = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType;

    const idExpired = _.get(logonData, "data.error", "") === PREVENT_ACCESS.ACCOUNT_EXPIRED;

    const sessionGenerationFailed = _.get(logonData, 'data.statusText.data.message.status', "") === "E";
    const sessionGenerationFailureErrorMessage = _.get(logonData, 'data.statusText.data.error', "");

    const [publishedDate, setPublishedDate] = React.useState(new Date("2024-05-06T12:06:40Z")); // Published date of T&C
    const [subUserBlocked, setSubUserBlocked] = React.useState(false); // Whether the sub user is blocked
    // const tandCDate = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.tandCDate && profileDetails.data.data.customerInfo.tandCDate;

    const tandCDate = _.get(profileDetails, "data.data.customerInfo.tandCDate");

    // useEffect(() => {
    //     const currentDate = new Date();
    //     let currentDubaiDateFormat: any = moment(currentDate).tz('Asia/Dubai').format('DD-MM-YYYY')
    //     let publishDateTandC: any = moment(tandCDate).tz('Asia/Dubai').format('DD-MM-YYYY')
    //     setPublishedDate(publishDateTandC);

    //     // const oneDayAfterPublishing = new Date(publishDateTandC);
    //     // oneDayAfterPublishing.setDate(oneDayAfterPublishing.getDate() + 6);
    //     console.log("----------tandCDate-----------", tandCDate);

    //     console.log("----------publishDateTandC-----------", publishDateTandC);

    //     const sevenDayAfterPublishing = new Date(publishDateTandC);
    //     sevenDayAfterPublishing.setDate(sevenDayAfterPublishing.getDate() + 8);

    //     console.log("----------sevenDayAfterPublishing-----------", sevenDayAfterPublishing);

    //     let finalFilteredDate: any = moment(sevenDayAfterPublishing).tz('Asia/Dubai').format('DD-MM-YYYY');

    //     console.log("----------currentDubaiDateFormat-----------", currentDubaiDateFormat);

    //     console.log("----------finalFilteredDate-----------", finalFilteredDate);

    //     if (isReadTandC === false && userType !== "Admin") {
    //         if (currentDubaiDateFormat > finalFilteredDate) {
    //             setSubUserBlocked(true);
    //         }
    //     }
    // }, [publishedDate]);

    useEffect(() => {
        const currentDate = new Date();
        const currentDubaiDateFormat = moment(currentDate).tz('Asia/Dubai').format('DD-MM-YYYY');
        // Ensure tandCDate is parsed and converted properly
        const publishDateTandC: any = moment(tandCDate).tz('Asia/Dubai');
        setPublishedDate(publishDateTandC.format('DD-MM-YYYY'));
     
        // Calculate the date 7 days after tandCDate
        const sevenDayAfterPublishing = publishDateTandC.clone().add(7, 'days');
        const finalFilteredDate = sevenDayAfterPublishing.format('DD-MM-YYYY');
     
        // Block sub-user if conditions are met
        if (isReadTandC === false && userType !== "Admin") {
            if (moment(currentDubaiDateFormat, 'DD-MM-YYYY').isAfter(moment(finalFilteredDate, 'DD-MM-YYYY'))) {
                setSubUserBlocked(true);
            }
        }
    }, [tandCDate, isReadTandC, userType]);
    
    // let inaccessibleFeatures = [];

    // let noAccess = false;

    // if(!isAdmin){
    //     if(featureAccessibility){
    //         inaccessibleFeatures = featureAccessibility.filter((feature:any) => {
    //             return feature.value === false;
    //         });
    //     }

    // if(featureAccessibility){
    //     noAccess = featureAccessibility.length === inaccessibleFeatures.length;
    //

    // }
    // else {
    //     featureAccessibility =[];
    // }

    //handle login expiry

    if (idExpired) {
        return <OnboardingErrorPage status="idExpired" />
    }

    //handle login failure
    if (logonData && logonData.data && logonData.data.message && logonData.data.message.status === "E") {
        return <OnboardingErrorPage status="loginError" />
    }

    if (sessionGenerationFailed) {
        return <OnboardingErrorPage status="sessionGenerationFailed" message={sessionGenerationFailureErrorMessage} />
    }

    if (tradepointAccessToCustomer) {
        return <OnboardingErrorPage status="tradepointAccessToCustomerDenied" />
    }

    if (subUserBlocked === true) {
        return <OnboardingErrorPage status="isReadTandCAccessDenied" />
    }

    if (inactive) {
        return <OnboardingErrorPage status="inactive" />
    }

    if(userType !== "Admin" && blockIntent){
        return <OnboardingErrorPage status="Kindly upload the revised document to regain access to the portal." />
    }

    else if(locked){
        return <OnboardingErrorPage status="locked" />
    }
    // else if(noAccess){
    //     return <OnboardingErrorPage status="noAccess" />
    // }
    else {
        return (
            props.children
        )
    }
}