import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const superAdminServices = {
    getCustomerDetails,
    editSuperDealer,
    getPlanFeatures,
    updatePlanFeatures,
    getDealerListSetTarget,
    getDefaultTargetData,
    updateDefaultTarget,
    updateTargetDataCustomer,
    getTier,
    getDownloadedSampleExcel,
    importTargetDataCustomer,
    setTargetCustomer,
    getRefreshButton
};

async function getDefaultTargetData(loginId?: string, year?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/targets/defalutTarget?&loginId=${loginId}&year=${year}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getTier() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/tiersList`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function updateDefaultTarget(data: any, loginId?: string, year?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/targets/defalutTarget?&loginId=${loginId}&year=${year}`;
    const options = {
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getCustomerDetails(id: any) {
    // const apiPath=`automotive/b2b/v1/users/targets/customer/${id}`
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/targets/customer/${id}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function editSuperDealer(data: any, id: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/targets/customer/${id}`
    const options = {
        path: apiPath,
        bodyObj: data,
        method: "PUT",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}
async function getRefreshButton( id:any, customerType: any ) { 
     const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/syncCustomerAccountTier?sapCustomerId=${id}&customerType=${customerType}`;
     const options = {
       path: apiPath,
       method: "GET",
       customHeaders: {},
       formdata:true
   };
   return await makeRequest(options)
}

async function getDealerListSetTarget(sapCustomerId: any, year?: any, loginId?: string) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/targets/customer/${sapCustomerId}/setTarget?year=${year}&loginId=${loginId}`;

    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function updateTargetDataCustomer(data: any, sapCustomerId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/targets/customer/${sapCustomerId}/setTarget`;

    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getPlanFeatures() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/targets/featurePlan?loginId=165578`;
    const options = {
        path: apiPath,
        method: "GET"
    };
    return await makeRequest(options)
}

async function updatePlanFeatures(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/targets/featurePlan/`
    const options = {
        path: apiPath,
        bodyObj: data,
        method: "PUT",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getDownloadedSampleExcel(loginId?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/targets/defalutTargetSample?&loginId=${loginId}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function importTargetDataCustomer(data: any, customHeaders?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/documents/xls/setDefaultTarget`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders,
        formdata: true
    };
    return await makeRequest(options)
}

async function setTargetCustomer(data: any, customHeaders?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/documents/setTargetForCustomers`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders,
        formdata: true
    };
    return await makeRequest(options)
}

