/* eslint-disable */
import React, { useEffect, useCallback } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone'
import CancelIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { uploadLpo } from '../../store/actions/quotation.action';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import "../Quotation/Quotation.css"
import { uploadLpoEmail } from "../../store/actions/quotation.action";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import GetAppIcon from "@material-ui/icons/GetApp";
import XLSX from "xlsx";
import { getDownloadedSampleExcel, importTargetDataCustomer } from "../../store/actions/superAdmin.actions";
import { readFile } from './../../helpers/commonmethod'
import { getAllDefaultTargetData } from "../../store/actions/superAdmin.actions";

const useStyles = makeStyles((theme) => ({
    collapseBg: {
        background: 'rgba(66, 94, 108, 0.07)'
    },
    rowHighlight: {
        background: 'rgba(67, 95, 107, 0.15)',
        color: '#666666',
        fontWeight: 700
    },
    orangeRowHighlight: {
        background: 'rgba(240, 223, 0, 0.12)',
        color: '#666666',
        fontWeight: 700
    },
    bodyContent: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        width: 140,

    },
    bodyContentSmall: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        width: 40,
    },
    collapseIcon: {
        color: '#000000',
        fontWeight: 500,
    },
    tableHeadSmall: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        lineHeight: 0,
        width: 40,
    },
    tableHeadSmallBrand: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        lineHeight: 0,
        width: 200,
    },
    tableHead: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        lineHeight: 0,
        width: 140,
    },
    subTableHead: {
        color: '#444444',
        fontWeight: 100,
        width: 135,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead1: {
        color: '#444444',
        fontWeight: 450,
        width: 90,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead2: {
        color: '#444444',
        fontWeight: 800,
        width: 97,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead3: {
        color: '#444444',
        fontWeight: 800,
        width: 150,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead4: {
        color: '#444444',
        fontWeight: 800,
        width: 140,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead5: {
        color: '#444444',
        fontWeight: 800,
        width: 140,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead6: {
        color: '#444444',
        fontWeight: 800,
        width: 140,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHeadSmall: {
        color: '#444444',
        fontWeight: 800,
        width: 40,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    actionButton: {
        color: '#1A9FE0',

    },
    actionRequest: {
        color: "white !important",
        background: '#1A9FE0',
        borderRadius: "14px",
        paddingLeft: 8,
        height: 20,
        width: 20,
        "&:hover": {
            background: "#1A9FE0",
            color: "white !important",
            borderRadius: "14px",
            paddingLeft: 8,
            height: 20,
            width: 20,
        },
    },
    requestQuestion: {
        color: '#1A9FE0',
        "&:hover": {
            background: "yellow",

        },

    },
    subTableBodyContent: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        width: 140,
    },
    subTableBodyContentSmall: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        width: 40,
    }
}));

const ImportExcelSetTarget = (props: any) => {
    const { quotationNumber, enquiryNumber, brandId, orderNumber } = props;
    const { openUploadImportExcel } = props;
    const [open, setOpen] = React.useState(false);
    const [uploadedFileName, setuploadedFileName] = React.useState('');
    const [uploadedFileNameError, setuploadedFileNameError] = React.useState(false);
    const [error, setError] = React.useState<any>('');
    const [size, setSize] = React.useState<any>(0);
    const [importExcelNumber, setImportExcelNumber] = React.useState("")
    const [file, setFile] = React.useState<any>("");
    const [termsAndConditions, setTermsAndConditions] = React.useState(false)
    const [openSnackbar, setOpenSnackBar] = React.useState<any>(false)
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false)
    const [successMessage, setSuccessMessage] = React.useState("")
    const [validateError, setValidateError] = React.useState(false);
    const { lpoQuotation, quotationEmail }: any = useSelector((state: any) => state.quotation)
    const classes = useStyles()
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const { quotationDetails }: any = useSelector((state: any) => state.quotation)
    const dispatch: any = useDispatch()
    const customerID = sessionStorage.getItem('selectedCustomerID');
    const [uploadedImportExcelFromOrderDetails, setUploadedImportExcelFromOrderDetails] = React.useState(false)
    const [sampleFile, setSampleFile] = React.useState<any>("");
    const [sampleFileError, setSampleFileError] = React.useState<any>("");
    const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId') || '';
    const { getImportExcelDetails }: any = useSelector((state: any) => state.superAdmin);

    useEffect(() => {
        if (uploadedFileName && !uploadedFileNameError && error === '') {
            setOpen(false);
        }
    }, [uploadedFileName, uploadedFileNameError, error])

    useEffect(() => {
        if (!open) {
            setuploadedFileName('');
            setuploadedFileNameError(false);
            setError('');
            setSize("")
            setFile("")
        }
    }, [open, openUploadImportExcel])

    const fileUploadChange = useCallback((data: any) => {
        if (data && data.length && data.length > 0 && data[0]) {
          if (data[0] && data[0].name.toLowerCase() && (data[0].name.toLowerCase().includes('.xlsx') || data[0].name.toLowerCase().includes('.xls'))) {
            readFile(data[0]);
            setError(null);
            setuploadedFileNameError(false);
          } else {
            setError('Please upload excel file');
            setuploadedFileNameError(true);
            setuploadedFileName(data[0] && data[0].name)
          }
        }
        let uploader: any = document.getElementById('storesfileupload');
        if (uploader) uploader.value = "";
      }, []) // eslint-disable-line
    
      const onDrop = useCallback((acceptedFiles: any) => {
        setSize(acceptedFiles[0].size)
        fileUploadChange(acceptedFiles)
        setFile(acceptedFiles && acceptedFiles[0])
        setuploadedFileName(acceptedFiles[0] && acceptedFiles[0].name)
      }, [fileUploadChange])
    

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const convertToSize = (value: any) => {
        let Value = Number(value) / 1024
        return Math.round(Value) + "KB"
    }

    const handleSubmit = async () => {
        const formData: any = new FormData();
        const fileName = file && file.name.substring(file.name.lastIndexOf('/') + 1);
        const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
        let keyAttribute = `fieldname=${"importExcel"}&docExtension=${fileExtension}`
        const customHeaders = {
            "Accept": "application/json",
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": "",
            "Accept-Language": "EN",
        };
        formData.append("entityName", "CUSTOMER");
        formData.append("keyAttribute", keyAttribute);
        formData.append("loginId", sessionStorage.getItem('webApplicationLoginId'));
        formData.append("fileUpload", file);
        formData.append("label", 'xlsfile');
        formData.append("selectedFileName", file.name);

        let data = await dispatch(importTargetDataCustomer(formData, customHeaders))
        if(data && data.message === ""){
            setOpenErrorSnackbar(true);
            setSuccessMessage("Please re-upload the excel file again to fetch updated values.");
            setOpenSnackBar(true);
        }else if (data && data.error) {
            setOpenErrorSnackbar(true);
            setSuccessMessage(data.error);
            setOpenSnackBar(true);
        } else {
            dispatch(getAllDefaultTargetData(webApplicationLoginId, props.selectYear));
            setSuccessMessage(data && data.message && data.message.message);
            setUploadedImportExcelFromOrderDetails(true);
            setOpenSnackBar(true);
            setOpenErrorSnackbar(false);
            props.handleUploadImportExcel();
            setImportExcelNumber("")
            setTermsAndConditions(false);
            setError(null);
            setuploadedFileNameError(false);
        }
    }

    const handleopenCloseSnackbar = (open: any) => {
        setOpenSnackBar(open ? true : false);
    }

    const handleDownload = () => {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a")
        link.href = url
        link.download = uploadedFileName
        document.body.appendChild(link)
        link.click()
    }

    return (
        <div>
            <React.Fragment>
                {(lpoQuotation && lpoQuotation.loading || quotationEmail && quotationEmail.loading) && <FullscreenLoader />}
                <Snackbar
                    open={openSnackbar}
                    type={openErrorSnackbar ? 'error' : ''}
                    handleopenClose={() => {
                        handleopenCloseSnackbar(false)
                    }} message={successMessage} />

                <span className="cursor-pointer mr-2" onClick={(e) => {
                    setuploadedFileName('');
                    setuploadedFileNameError(false);
                    e.preventDefault();
                    setOpen(true);
                }}>
                </span>

                <Dialog fullWidth={true} maxWidth="sm" open={openUploadImportExcel ? true : false} onClose={() => {
                    props.handleUploadImportExcel(); setuploadedFileName('');
                    setuploadedFileNameError(false);
                    setError('');
                    setSize("")
                    setFile("")
                    setImportExcelNumber("")
                    setTermsAndConditions(false);
                }} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <div className="col-12 row">
                            <div className="col-7">
                                <h4 className="page-title">Import Excel</h4>
                            </div>
                            <div className="row col-5 mt-2">
                                <a className="row" href="./importexcelsampletemplate.xlsx" download="importexcelsampletemplate.xlsx">
                                    <Button className="searchbarOptions" >
                                        <GetAppIcon fontSize="inherit" className="row mr-4 mt-1" />
                                        <h4 className="row font-size-13 load-from-fav">Downlaod Sample Excel</h4>
                                    </Button>
                                </a>
                            </div>
                        </div>
                        <div className="d-flex mt-3 py-4 dropzoneImport" {...getRootProps()}>
                            <div className="d-flex col-lg-6 col-md-6 col-sm-12 justify-content-center align-items-center dashed-right-border">
                                <div className="dragHereIcon justify-content-center">
                                    <CloudUploadIcon fontSize="large" />
                                </div>
                                <div className="dragHereText ml-2">Drag your excel sheet here</div>
                                <input className="d-none" type="file" hidden id="productsfileupload"{...getInputProps()} />
                            </div>
                            <div className="d-flex col-lg-6 col-md-6 col-sm-12 justify-content-center align-items-center">
                                <Button
                                    className="font-size-11 addToEnquiryButton"
                                    size="small"
                                    // onClick={(event) => {
                                    //     handleFileUpload(event)
                                    // }}
                                    variant="contained" color="primary">Browse Excel</Button>
                            </div>
                        </div>
                        {sampleFileError && <div style={{ color: 'red' }}>{sampleFileError}</div>}

                        {/* {uploadedFileName && !uploadedFileNameError && error === '' && <div className="d-flex font-weight-bold justify-content-center mt-2 text-success">{uploadedFileName} imported succesfully!</div>} */}
                        {uploadedFileNameError && <div className="d-flex font-weight-bold justify-content-center mt-2 text-danger">{uploadedFileName} - {error}</div>}


                        {uploadedFileName &&
                            <div className="d-flex flex row pl-4">
                                <div className="mt-2 row">
                                    <div className="file uploadFile" onClick={handleDownload}>{uploadedFileName} ({convertToSize(size)}/10MB)</div>
                                    <div className="pl-2" onClick={() => {
                                        setuploadedFileName('');
                                        setuploadedFileNameError(false);
                                        setError('');
                                        setSize("")
                                        setFile("")
                                    }}>
                                        <IconButton className={`${classes.actionButton}`} size="small">
                                            <CancelIcon fontSize="inherit" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>}
                    </DialogContent>
                    <DialogActions className="mt-2 mb-4 justify-content-end">
                        <Button variant="contained" className={`submit-button font-size-11 mr-3
                            ${file && uploadedFileNameError === false && !error ? "" : "lpo-button-disabled"}`} 
                            onClick={() => {
                                handleSubmit();
                                setOpen(false);
                            }}>
                            Import
                        </Button>
                        <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={() => { props.handleUploadImportExcel(); setImportExcelNumber(""); setTermsAndConditions(false); }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default ImportExcelSetTarget