import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const cmsAPIService = {
  getSpecialoffers,
  getAnnouncementsList,
  getAnnouncementDetails,
  getTermsAndConditions,
  getHomepageDetails,
  getWorkshopHomepageDetails,
  sendAnnouncmentToCustomer,
  acceptTermsAndConditions
};

async function getTermsAndConditions() {
  const apiPath = API_ENDPOINTS.CMS_ENDPOINT + "/wcm/terms?srv=cmpnt&source=library&WCM_Page.ResetAll=TRUE&subtype=json&cmpntname=onestop_Shared/render/ArticleDetail&spath=/onestop_en/Home/terms"
  const options = {
    path: apiPath,
    customHeaders: {},
    method: "GET",
  };
  return await makeRequest(options);
}

async function getSpecialoffers() {
  const apiPath = API_ENDPOINTS.CMS_ENDPOINT + "/wcm/offer?srv=cmpnt&source=library&WCM_Page.ResetAll=TRUE&subtype=json&cmpntName=onestop_Shared/render/ArticleList&spath=Onestop_en/Home/offers"
  const options = {
    path: apiPath,
    customHeaders: {},
    method: "GET",
  };
  return await makeRequest(options);
}

async function getAnnouncementsList() {
  const apiPath = API_ENDPOINTS.CMS_ENDPOINT + "/wcm/announcement?srv=cmpnt&source=library&WCM_Page.ResetAll=TRUE&subtype=json&cmpntName=onestop_Shared/render/ArticleList&spath=Onestop_en/Home/announcements"
  const options = {
    path: apiPath,
    customHeaders: {
    },
    method: "GET",
  };
  return await makeRequest(options);
}

async function sendAnnouncmentToCustomer(bodyData: any) {
  const apiPath = API_ENDPOINTS.CMS_ENDPOINT + `/wcm/emailDispatch`;
  const options = {
    path: apiPath,
    customHeaders: {},
    method: "POST",
    bodyObj: bodyData,
    formdata: true
  };
  return await makeRequest(options);
}

async function getAnnouncementDetails(name: any) {
  const apiPath = API_ENDPOINTS.CMS_ENDPOINT + `/wcm/announcement/${name}?&WCM_Page.ResetAll=TRUE&SRV=Page&subtype=json&presentationtemplate=onestop_Shared/PT_ArticleDetail`;
  const options = {
    path: apiPath,
    customHeaders: {},
    method: "GET",
  };
  return await makeRequest(options);
}

async function getHomepageDetails() {
  const apiPath = `${API_ENDPOINTS.CMS_ENDPOINT + "/wcm/homepage"}?source=library&labelsPath=/onestop_en/home/labels&WCM_Page.ResetAll=TRUE&srv=cmpnt&bannerPath=/onestop_en/home/landing/banner&subtype=json&cmpntname=onestop_Shared/render/home&tradepointPath=/onestop_en/home/landing/why-trade-point&brandsPath=/onestop_en/home/landing/our-brands&registerPath=/onestop_en/home/landing/register-now`;
  const options = {
    path: apiPath,
    customHeaders: {},
    method: "GET",
  };
  return await makeRequest(options);
}

async function getWorkshopHomepageDetails() {

  const apiPath = `${API_ENDPOINTS.CMS_ENDPOINT + "/wcm/workshop/homepage"}?locationPath=/onestop_en/home/workshop_landing/locations&source=library&WCM_Page.ResetAll=TRUE&labelsPath=/onestop_en/home/labels&srv=cmpnt&footerPath=/onestop_en/home/workshop_landing/footer&bannerPath=/onestop_en/home/workshop_landing/banner&subtype=json&cmpntname=onestop_Shared/render/workshop_landing&tradepointPath=/onestop_en/home/workshop_landing/why-trade-point&brandsPath=/onestop_en/home/workshop_landing/our-brands&registerPath=/onestop_en/home/workshop_landing/register-now&logosPath=/onestop_en/home/workshop_landing/website_logo`;
  const options = {
    path: apiPath,
    customHeaders: {},
    method: "GET",
  };
  return await makeRequest(options);
}

async function acceptTermsAndConditions(bodyData: any) {
  const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/updateTandCFlag`;
  const options = {
    path: apiPath,
    customHeaders: {},
    method: "POST",
    bodyObj: bodyData,
    formdata: true
  };
  return await makeRequest(options);
}

