/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { Tab, Tabs, Box } from "@material-ui/core";
import { withStyles, Theme, createStyles, createTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { getAllDefaultTargetData, updateAllDefaulTargetData } from "../../store/actions/superAdmin.actions";
import { superAdminServices } from "../../services/superAdmin.service";
import { Prompt } from "react-router-dom"
import { getYears } from "../../helpers/commonmethod";
import DeleteDialog from "../../common/components/deleteDialog";
import IconButton from '@material-ui/core/IconButton';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ImportExcelSetTarget from "./ImportExcelDialog";
import { getNewBrand } from '../../common/components/util';
import { getNewlyAddedBrand } from "../../common/components/util";

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SetTarget: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const leftMenuVisibility: any = useSelector((state: any) => state.global && state.global.sideMenuOpened);
  const [value, setValue] = React.useState(0);
  const { getDefaultTargetDetails, updateDefaultTarget, updateDefaultTargetData }: any = useSelector((state: any) => state.superAdmin);
  const currencies = ["AED"];
  const [dataUpdated, setDataUpdated] = React.useState<any>(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [range, setRange] = React.useState<any>();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId') || '';
  const [selectYear, setSelectYear] = React.useState<any>(new Date().getFullYear());
  const [defaultPlanData, setDefaultPlanData] = React.useState<any>(null);

  const [selectedTab, setselectedTab] = React.useState(0);
  const [selectedTabBrand, setSelectedTabBrand] = React.useState(0);

  const [selectedTabData, setSelectedTabData] = React.useState<any>(null);
  const [selectedTabBrandData, setSelectedTabBrandData] = React.useState<any>(null);

  const [selectedBrandData, setSelectedBrandData] = React.useState<any>(null);
  const [finalData, setFinalData] = React.useState<any>(null);

  const [currentSelectedTierName, setCurrentSelectedTierName] = React.useState<any>(sessionStorage.getItem('currentSelectedTierName'));
  const [currentSelectedBrandName, setCurrentSelectedBrandName] = React.useState<any>(sessionStorage.getItem('currentSelectedBrandName'));

  const [selectedPlan, setSelectedPlan] = React.useState<any>(null);
  const [selectedBrand, setSelectedBrand] = React.useState<any>(null);

  const [tabData, setTabData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [disableMonths, setDisableMonth] = React.useState<any>({});
  const [dataUpdatedPrompt, setDataUpdatedPrompt] = React.useState<boolean>(false);
  const years = getYears();
  const [newValue, setNewValue] = React.useState<any>();
  const [newValueBrand, setNewValueBrand] = React.useState<any>(0);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [openUploadImportExcel, setOpenUploadImportExcel] = React.useState(false);
  const [isImportExcelUpdate, setIsImportExcelUpdate] = React.useState(false);

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // SUCCESS - SNACKBAR FUNCTIONALITY
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  // ERROR - SNACKBAR FUNCTIONALITY
  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  let brandMapping: any = {
    "0": "Toyota",
    "1": "Honda",
    "2": "TE",
    "3": "OES",
  }

  let tierMapping: any = {
    "0": "Silver",
    "1": "Gold",
    "2": "Platinum",
    "3": "Elite",
  }

  // TO FETCH & DISPLAY THE SELECTED TAB DATA
  useEffect(() => {
    if (getDefaultTargetDetails && getDefaultTargetDetails.data && getDefaultTargetDetails.data.data && getDefaultTargetDetails.data.data.plan.length > 0) {
      setDefaultPlanData(JSON.parse(JSON.stringify({ ...getDefaultTargetDetails.data.data.plan })))
    }
    if (getDefaultTargetDetails && getDefaultTargetDetails.data && getDefaultTargetDetails.data.data && getDefaultTargetDetails.data.data.plan.length > 0) {
      setSelectedTabData(JSON.parse(JSON.stringify({ ...getDefaultTargetDetails.data.data.plan[0] })))
    }
    setselectedTab(0);
    setSelectedTabBrand(0);
    // updatedStateValues(0, 0)
  }, [getDefaultTargetDetails])


  useEffect(() => {
    if (selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.length > 0) {
      setSelectedBrandData(JSON.parse(JSON.stringify({ ...selectedTabData && selectedTabData.targetsByOrgId })));
    }
  }, [selectedTabData])

  useEffect(() => {
    dispatch(getAllDefaultTargetData(webApplicationLoginId, selectYear));
  }, [selectYear]);

  useEffect(() => {
    // updatedStateValues(selectedTab, selectedTabBrand)
    let planValue: any = tierMapping[selectedTab]
    let brandValue: any = brandMapping[selectedTabBrand]
    setSelectedPlan(planValue);
    setSelectedBrand(brandValue);

    sessionStorage.setItem('currentSelectedTierName', planValue);
    sessionStorage.setItem('currentSelectedBrandName', brandValue);

    setCurrentSelectedBrandName(sessionStorage.getItem('currentSelectedBrandName'))
    setCurrentSelectedTierName(sessionStorage.getItem('currentSelectedTierName'))
    if (selectedBrandData) {
      let data: any = [];
      for (let item in selectedBrandData) {
        data.push(selectedBrandData[item])
      }
      let filteredData: any = data.filter((ele: any) => {
        if (ele.brand === brandValue) {
          return ele
        }
      })
      setFinalData(filteredData[0])
    }
  }, [selectedTabBrand, selectedTab, selectedBrandData, getDefaultTargetDetails, tierMapping, brandMapping, finalData]);

  useEffect(() => {
    if (getDefaultTargetDetails?.data?.data?.categoryRange && getDefaultTargetDetails.data.data.categoryRange.length > 0 && selectedTabData) {
      let rangeValue = getDefaultTargetDetails.data.data.categoryRange.filter((range: any) => range.code === selectedTabData.code)
      setRange(rangeValue && rangeValue.length > 0 && rangeValue[0])
    }
  }, [selectedTabData]);


  // const updatedStateValues = (plan: any, brand: any) => {
  //   let planValue: any = tierMapping[plan]
  //   let brandValue: any = brandMapping[brand]

  //   setSelectedPlan(planValue);
  //   setSelectedBrand(brandValue);

  //   sessionStorage.setItem('currentSelectedTierName', planValue);
  //   sessionStorage.setItem('currentSelectedBrandName', brandValue);

  //   setCurrentSelectedBrandName(sessionStorage.getItem('currentSelectedBrandName'))
  //   setCurrentSelectedTierName(sessionStorage.getItem('currentSelectedTierName'))
  //   if (selectedBrandData) {
  //     let data: any = [];
  //     for (let item in selectedBrandData) {
  //       data.push(selectedBrandData[item])
  //     }
  //     let filteredData: any = data.filter((ele: any) => {
  //       if (ele.brand === brandValue) {
  //         return ele
  //       }
  //     })
  //     setFinalData(filteredData[0])
  //   }
  // }

  function convertToInternationalCurrencySystem(labelValue: any) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"// Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6

        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"// Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3

          ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

          : Math.abs(Number(labelValue));

  }

  // DISABLED PREVIOUS MONTHS FUNCTIONATILTY
  useEffect(() => {
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const currentMonth = new Date();
    const name = currentMonth.getMonth()
    const currentYear = new Date().getUTCFullYear();
    const filterMonth = month.filter((ele: any, i: any) => {
      return i < name
    })
    const disableMonth = { jan: false, feb: false, mar: false, apr: false, may: false, jun: false, jul: false, aug: false, oct: false, sep: false, nov: false, dec: false }
    if (filterMonth.includes(month[0])) {
      disableMonth.jan = true
    }
    if (filterMonth.includes(month[1])) {
      disableMonth.feb = true
    } if (filterMonth.includes(month[2])) {
      disableMonth.mar = true
    } if (filterMonth.includes(month[3])) {
      disableMonth.apr = true
    } if (filterMonth.includes(month[4])) {
      disableMonth.may = true
    } if (filterMonth.includes(month[5])) {
      disableMonth.jun = true
    } if (filterMonth.includes(month[6])) {
      disableMonth.jul = true
    } if (filterMonth.includes(month[7])) {
      disableMonth.aug = true
    } if (filterMonth.includes(month[8])) {
      disableMonth.sep = true
    } if (filterMonth.includes(month[9])) {
      disableMonth.oct = true
    } if (filterMonth.includes(month[10])) {
      disableMonth.nov = true
    } if (filterMonth.includes(month[11])) {
      disableMonth.dec = true
    }
    if (selectYear === currentYear) {
      setDisableMonth(disableMonth)
      // } else if (selectYear <= currentYear) {
      //   setDisableMonth({ jan: true, feb: true, mar: true, apr: true, may: true, jun: true, jul: true, aug: true, oct: true, sep: true, nov: true, dec: true })
    } else if (selectYear >= currentYear) {
      setDisableMonth({ jan: false, feb: false, mar: false, apr: false, may: false, jun: false, jul: false, aug: false, oct: false, sep: false, nov: false, dec: false })
    }
  }, [selectYear])


  // FOR PUT API CALL - TO UPADTE SET TARGET DATA
  const setTargetApiCall = async () => {
    let value = getDefaultTargetDetails && getDefaultTargetDetails.data &&
      getDefaultTargetDetails.data.data && getDefaultTargetDetails.data.data.plan.length > 0 &&
      getDefaultTargetDetails.data.data.plan;

    let selectedData: any = [];
    for (let item in defaultPlanData) {
      selectedData.push(defaultPlanData[item])
    }

    let filteredData: any = selectedData.filter((ele: any) => {
      if (ele.planName === selectedPlan) {
        return ele
      }
    })

    let brandData: any = [];

    const { targetsByOrgId } = filteredData[0]
    brandData = targetsByOrgId.filter((ele: any) => {
      if (ele.brand !== selectedBrand) {
        return ele
      }
    })

    brandData.push(finalData)

    if (value) {
      let apiData: any = value.filter((ele: any) => {
        if (ele.planName !== selectedPlan) {
          return ele
        }
      })

      let obj = {
        code: selectedPlan && selectedPlan === "Silver" ? "SLV" : selectedPlan && selectedPlan === "Gold" ? "GOLD" : selectedPlan && selectedPlan === "Platinum" ? "PLTNM" : selectedPlan && selectedPlan === "Elite" ? "ELITE" : selectedPlan.toUpperCase(),
        planName: selectedPlan,
        targetsByOrgId: brandData
      }

      apiData.push(obj)

      let data = {
        "updatedBy": webApplicationLoginId,
        "plan": apiData,
      }
      setLoading(true);
      const responseData: any = await dispatch(updateAllDefaulTargetData(data, webApplicationLoginId, selectYear));
      // superAdminServices.updateDefaultTarget(data, webApplicationLoginId, selectYear);
      if (responseData && responseData.message && responseData.message.status == "S") {
        setLoading(false);
        setDataUpdated(false);
        setOpenErrorSnackbar(false);
        setOpenSnackbar(true);
        setSuccessMessage(responseData.message.message);
        // dispatch(getAllDefaultTargetData(webApplicationLoginId, selectYear));
      } else {
        setLoading(false);
        // setOpenErrorSnackbar(true);
        handleopenCloseErrorSnackbar(true);
        showErrorMessage(responseData.error ? responseData.error : "Something went Wrong");
      }
    }
  }

  // FOR QUARTER 1 - TOTAL 
  const handleInputChange = () => {
    let jan = parseFloat(parseFloat(finalData?.monthlyTaget?.Jan?.amount || 0).toFixed(2));
    let feb = parseFloat(parseFloat(finalData?.monthlyTaget?.Feb?.amount || 0).toFixed(2));
    let mar = parseFloat(parseFloat(finalData?.monthlyTaget?.Mar?.amount || 0).toFixed(2));
    let Q1: any = jan + feb + mar;
    Q1 = parseFloat(Q1).toFixed(2)
    let initialValue = finalData
    initialValue.quarterlyTaget.quarter1.amount = Q1
    setFinalData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR QUARTER 2 TARGET- TOTAL 
  const handleInputChangeQ2 = () => {
    let apr = parseFloat(parseFloat(finalData?.monthlyTaget?.Apr?.amount || 0).toFixed(2));
    let may = parseFloat(parseFloat(finalData?.monthlyTaget?.May?.amount || 0).toFixed(2));
    let june = parseFloat(parseFloat(finalData?.monthlyTaget?.Jun?.amount || 0).toFixed(2));
    let Q2: any = apr + may + june;
    Q2 = parseFloat(Q2).toFixed(2)
    let initialValue = finalData
    initialValue.quarterlyTaget.quarter2.amount = Q2
    setFinalData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR QUARTER 3 TARGET- TOTAL 
  const handleInputChangeQ3 = () => {
    let jul = parseFloat(parseFloat(finalData?.monthlyTaget?.Jul?.amount || 0).toFixed(2));
    let aug = parseFloat(parseFloat(finalData?.monthlyTaget?.Aug?.amount || 0).toFixed(2));
    let sept = parseFloat(parseFloat(finalData?.monthlyTaget?.Sept?.amount || 0).toFixed(2));
    let Q3: any = jul + aug + sept
    Q3 = parseFloat(Q3).toFixed(2)
    let initialValue = finalData
    initialValue.quarterlyTaget.quarter3.amount = Q3
    setFinalData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR QUARTER 4 TARGET - TOTAL 
  const handleInputChangeQ4 = () => {
    let oct = parseFloat(parseFloat(finalData?.monthlyTaget?.Oct?.amount || 0).toFixed(2));
    let nov = parseFloat(parseFloat(finalData?.monthlyTaget?.Nov?.amount || 0).toFixed(2));
    let dec = parseFloat(parseFloat(finalData?.monthlyTaget?.Dec?.amount || 0).toFixed(2));
    let Q4: any = oct + nov + dec
    Q4 = parseFloat(Q4).toFixed(2)
    let initialValue = finalData
    initialValue.quarterlyTaget.quarter4.amount = Q4
    setFinalData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR YEARLY TARGET - TOTAL 
  const handleInputChangeYaerlyTarget = () => {
    let Q1 = parseFloat(parseFloat(finalData?.quarterlyTaget?.quarter1?.amount || 0).toFixed(2));
    let Q2 = parseFloat(parseFloat(finalData?.quarterlyTaget?.quarter2?.amount || 0).toFixed(2));
    let Q3 = parseFloat(parseFloat(finalData?.quarterlyTaget?.quarter3?.amount || 0).toFixed(2));
    let Q4 = parseFloat(parseFloat(finalData?.quarterlyTaget?.quarter4?.amount || 0).toFixed(2));
    let YearlyTargetTotal: any = Q1 + Q2 + Q3 + Q4;
    YearlyTargetTotal = parseFloat(YearlyTargetTotal).toFixed(2)
    let initialValue = finalData
    initialValue.yearlyTaget.amount = YearlyTargetTotal
    setFinalData({ ...initialValue });
  }

  const handleUploadImportExcel = async () => {
    setOpenUploadImportExcel(false);
    setIsImportExcelUpdate(false);
  }

  const updateImportExcel = () => {
    setOpenUploadImportExcel(true);
    setIsImportExcelUpdate(true);
  }


  return (
    <Fragment>
      {loading && <FullscreenLoader />}
      {getDefaultTargetDetails && getDefaultTargetDetails.loading && <FullscreenLoader />}
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      <div className="d-flex align-items-center">
        <h2 className="page-title"> Set Target </h2>
        <div className="ml-auto"> </div>
      </div>
      <div className="row col-12">
        <div className="row ealer-table-content col-8 mt-2"> Set default target for <h6 className="row px-4 text-info pr-3">{currentSelectedTierName} {" - "} <h6 className="px-3 row setTarget-monthly-target">{getNewlyAddedBrand(currentSelectedBrandName)}</h6></h6></div>
        <div className="row col-2">
          <IconButton className="searchbarOptions" size="small" onClick={() => {
            setOpenUploadImportExcel(true);
          }}>
            <ListAltIcon fontSize="inherit" />
            <span className="font-size-13 load-from-fav">Import Excel</span>
          </IconButton>
        </div>
        <div className="row col-2">
          <TextField select
            id="selectYear"
            label="Select Year"
            value={selectYear}
            variant="outlined"
            className="rounded-input-dealer mw-200p mb-2"
            size="small"
            onChange={(e: any) => {
              setselectedTab(0);
              setSelectedTabBrand(0);
              setSelectYear(e.target.value);
              // dispatch(getAllDefaultTargetData(webApplicationLoginId, e.target.value));
            }}
          >
            {years.map((data: any, index: any) => (
              <MenuItem key={index} value={data}> {data} </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div className="row">
        <div className="mt-3 mb-3 pb-5 w-100">
          <ThemeProvider theme={theme}>
            <Paper square>
              <Tabs
                className="tabSelect border-bottom border-top-0"
                value={selectedTab}
                textColor="primary"
                indicatorColor="primary"
                onChange={(event, newValue) => {
                  if (!dataUpdated) {
                    setselectedTab(newValue);
                    if (getDefaultTargetDetails && getDefaultTargetDetails.data &&
                      getDefaultTargetDetails.data.data &&
                      getDefaultTargetDetails.data.data.plan.length > 0)
                      setSelectedTabData(JSON.parse(JSON.stringify({ ...getDefaultTargetDetails.data.data.plan[newValue] })))
                  } else {
                    setNewValue(newValue);
                    setSelectedTabBrand(selectedTabBrand);
                    handleopenClose(true);
                  }
                }}
                aria-label="basic tabs example">
                {getDefaultTargetDetails && getDefaultTargetDetails.data &&
                  getDefaultTargetDetails.data.data && getDefaultTargetDetails.data.data.plan.length > 0 &&
                  getDefaultTargetDetails.data.data.plan.map((planName: any, item: any, index: any) => {
                    return <Tab label={planName.planName} {...a11yProps(index)} />
                  })}
              </Tabs>
              {selectedTabData && selectedTabData.planName === "Silver" ?
                <div className="mt-3">
                  <ThemeProvider theme={theme}>
                    <Paper square>
                      <Tabs
                        className="tabSelect border-bottom border-top-0"
                        value={selectedTabBrand}
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={(event, newValueBrand) => {
                          if (!dataUpdated) {
                            setSelectedTabBrand(newValueBrand);
                            if (selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.length > 0)
                              selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.map((ele: any) => {
                                return setSelectedTabBrandData(JSON.parse(JSON.stringify({ ...ele && ele[newValueBrand] })))
                              })
                          } else {
                            setNewValueBrand(newValueBrand);
                            setNewValue(selectedTab);
                            handleopenClose(true);
                          }
                        }}
                        aria-label="basic tabs example">
                        {selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.length > 0 &&
                          selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.map((brand: any, item: any, index: any) => {
                            return <Tab label={getNewlyAddedBrand(brand.brand)} {...a11yProps(index)} />
                          })}
                      </Tabs>
                    </Paper>
                  </ThemeProvider>
                </div>
                : selectedTabData && selectedTabData.planName === "Gold" ?
                  <div className="mt-3">
                    <ThemeProvider theme={theme}>
                      <Paper square>
                        <Tabs
                          className="tabSelect border-bottom border-top-0"
                          value={selectedTabBrand}
                          textColor="primary"
                          indicatorColor="primary"
                          onChange={(event, newValueBrand) => {
                            if (!dataUpdated) {
                              setSelectedTabBrand(newValueBrand);
                              if (selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.length > 0)
                                selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.map((ele: any) => {
                                  return setSelectedTabBrandData(JSON.parse(JSON.stringify({ ...ele && ele[newValueBrand] })))
                                })
                            } else {
                              setNewValueBrand(newValueBrand);
                              setNewValue(selectedTab);
                              handleopenClose(true);
                            }
                          }}
                          aria-label="basic tabs example">
                          {selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.length > 0 &&
                            selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.map((brand: any, item: any, index: any) => {
                              return <Tab label={getNewlyAddedBrand(brand.brand)} {...a11yProps(index)} />
                            })}
                        </Tabs>
                      </Paper>
                    </ThemeProvider>
                  </div>
                  : selectedTabData && selectedTabData.planName === "Platinum" ?
                    <div className="mt-3">
                      <ThemeProvider theme={theme}>
                        <Paper square>
                          <Tabs
                            className="tabSelect border-bottom border-top-0"
                            value={selectedTabBrand}
                            textColor="primary"
                            indicatorColor="primary"
                            onChange={(event, newValueBrand) => {
                              if (!dataUpdated) {
                                setSelectedTabBrand(newValueBrand);
                                if (selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.length > 0)
                                  selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.map((ele: any) => {
                                    return setSelectedTabBrandData(JSON.parse(JSON.stringify({ ...ele && ele[newValueBrand] })))
                                  })
                              } else {
                                setNewValueBrand(newValueBrand);
                                setNewValue(selectedTab);
                                handleopenClose(true);
                              }
                            }}
                            aria-label="basic tabs example">
                            {selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.length > 0 &&
                              selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.map((brand: any, item: any, index: any) => {
                                return <Tab label={getNewlyAddedBrand(brand.brand)} {...a11yProps(index)} />
                              })}
                          </Tabs>
                        </Paper>
                      </ThemeProvider>
                    </div>
                    : selectedTabData && selectedTabData.planName === "Elite" ?
                      <div className="mt-3">
                        <ThemeProvider theme={theme}>
                          <Paper square>
                            <Tabs
                              className="tabSelect border-bottom border-top-0"
                              value={selectedTabBrand}
                              textColor="primary"
                              indicatorColor="primary"
                              onChange={(event, newValueBrand) => {
                                if (!dataUpdated) {
                                  setSelectedTabBrand(newValueBrand);
                                  if (selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.length > 0)
                                    selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.map((ele: any) => {
                                      return setSelectedTabBrandData(JSON.parse(JSON.stringify({ ...ele && ele[newValueBrand] })))
                                    })
                                } else {
                                  setNewValueBrand(newValueBrand);
                                  setNewValue(selectedTab);
                                  handleopenClose(true);
                                }
                              }}
                              aria-label="basic tabs example">
                              {selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.length > 0 &&
                                selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.map((brand: any, item: any, index: any) => {
                                  return <Tab label={getNewlyAddedBrand(brand.brand)} {...a11yProps(index)} />
                                })}
                            </Tabs>
                          </Paper>
                        </ThemeProvider>
                      </div> : ""
              }
              {finalData && (
                <TabPanel value={selectedTab} index={selectedTab}>
                  <div className="row p-2 m-0">
                    <span className="row px-4 pt-4 text-info">Monthly spend range - AED
                      <div className="row px-3">{range && range.value.split(" - ")[0] ? convertToInternationalCurrencySystem(parseInt(range && range.value.split(" - ")[0])) : ""}
                        {range && range.value.split(" - ")[0] && range && range.value.split(" - ")[1] ? "-" : "+"} {range && range.value.split(" - ")[1] ? convertToInternationalCurrencySystem(parseInt(range && range.value.split(" - ")[1])) : ""}</div>
                    </span>
                  </div>
                  <div className="row p-2 m-0">
                    <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.Jan?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown"
                          variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="january"
                          type={finalData?.monthlyTaget?.Jan?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.Jan?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.Jan.amount = updateValue
                            setFinalData({ ...initialValue });
                            handleInputChange();
                            setDataUpdated(true);
                            setDataUpdatedPrompt(true);
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          label="January" variant="outlined" margin="dense"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                        // disabled={disableMonths.jan} 
                        />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.Feb?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField
                          id="february"
                          type={finalData?.monthlyTaget?.Feb?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.Feb?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.Feb.amount = updateValue
                            setFinalData({ ...initialValue });
                            handleInputChange();
                            setDataUpdated(true);
                            setDataUpdatedPrompt(true);
                          }}
                          label="February" variant="outlined" margin="dense"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                        // disabled={disableMonths.feb}
                        />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.Mar?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="march"
                          type={finalData?.monthlyTaget?.Mar?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.Mar?.amount}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.Mar.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdated(true);
                            handleInputChange();
                            setDataUpdatedPrompt(true);
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="March" variant="outlined" margin="dense"
                        // disabled={disableMonths.mar}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 1 Target</h6></div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 pr-0">
                        <TextField
                          id="quarterCurrencies"
                          value={finalData?.quarterlyTaget?.quarter1?.currencyType}
                          defaultValue={currencies[0]}
                          disabled
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="quarterOne"
                          type={finalData?.quarterlyTaget?.quarter1?.amount > 0 ? "text" : "number"}
                          value={finalData?.quarterlyTaget?.quarter1?.amount || 0}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          // disabled={disableMonths.mar}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.quarterlyTaget.quarter1.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdated(true);
                            setDataUpdatedPrompt(true);
                            handleInputChangeYaerlyTarget();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="Quarter 1" variant="outlined" margin="dense" />
                      </div>
                    </div>
                    <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.Apr?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="april"
                          type={finalData?.monthlyTaget?.Apr?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.Apr?.amount}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.Apr.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ2();
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="April" variant="outlined" margin="dense"
                        //  disabled={disableMonths.apr}
                        />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.May?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="may"
                          type={finalData?.monthlyTaget?.May?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.May?.amount}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.May.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ2();
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="May" variant="outlined" margin="dense"
                        // disabled={disableMonths.may}
                        />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.Jun?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense" >
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="june"
                          type={finalData?.monthlyTaget?.Jun?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.Jun?.amount}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.Jun.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ2();
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="June" variant="outlined" margin="dense"
                        // disabled={disableMonths.jun}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 2 Target</h6></div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                        <TextField
                          id="quarterCurrencies"
                          value={finalData?.quarterlyTaget?.quarter2?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="quarterTwo"
                          type={finalData?.quarterlyTaget?.quarter2?.amount > 0 ? "text" : "number"}
                          value={finalData?.quarterlyTaget?.quarter2?.amount || 0}
                          // disabled={disableMonths.jun}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.quarterlyTaget.quarter2.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdatedPrompt(true)
                            setDataUpdated(true)
                            handleInputChangeYaerlyTarget();
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="Quarter 2" variant="outlined" margin="dense" />
                      </div>
                    </div>
                    <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.Jul?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="july"
                          type={finalData?.monthlyTaget?.Jul?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.Jul?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.Jul.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ3();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="July" variant="outlined" margin="dense"
                        // disabled={disableMonths.jul}
                        />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.Aug?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="august"
                          type={finalData?.monthlyTaget?.Aug?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.Aug?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.Aug.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ3();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="August" variant="outlined" margin="dense"
                        // disabled={disableMonths.aug}
                        />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.Sept?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="september"
                          type={finalData?.monthlyTaget?.Sept?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.Sept?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.Sept.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ3();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="September" variant="outlined" margin="dense"
                        // disabled={disableMonths.sep}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 3 Target</h6></div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                        <TextField
                          id="quarterCurrencies"
                          value={finalData?.quarterlyTaget?.quarter3?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="quarterThird"
                          type={finalData?.quarterlyTaget?.quarter3?.amount > 0 ? "text" : "number"}
                          // disabled={disableMonths.sep}
                          value={finalData?.quarterlyTaget?.quarter3?.amount || 0}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.quarterlyTaget.quarter3.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdatedPrompt(true)
                            setDataUpdated(true)
                            handleInputChangeYaerlyTarget();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="Quarter 3" variant="outlined" margin="dense" />
                      </div>
                    </div>
                    <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.Oct?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="october"
                          type={finalData?.monthlyTaget?.Oct?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.Oct?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.Oct.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdated(true);
                            setDataUpdatedPrompt(true);
                            handleInputChangeQ4();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="October" variant="outlined" margin="dense"
                        // disabled={disableMonths.oct}
                        />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.Nov?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="november"
                          type={finalData?.monthlyTaget?.Nov?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.Nov?.amount}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.Nov.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ4();
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="November" variant="outlined" margin="dense"
                        // disabled={disableMonths.nov}
                        />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                        <TextField id="currenciesValues"
                          value={finalData?.monthlyTaget?.Dec?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="december"
                          type={finalData?.monthlyTaget?.Dec?.amount > 0 ? "text" : "number"}
                          value={finalData?.monthlyTaget?.Dec?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.monthlyTaget.Dec.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ4();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="December" variant="outlined" margin="dense"
                        // disabled={disableMonths.dec}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 4 Target</h6></div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                        <TextField
                          id="quarterCurrencies"
                          value={finalData?.quarterlyTaget?.quarter4?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="quarterFour"
                          type={finalData?.quarterlyTaget?.quarter4?.amount > 0 ? "text" : "number"}
                          // disabled={disableMonths.dec}
                          value={finalData?.quarterlyTaget?.quarter4?.amount || 0}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.quarterlyTaget.quarter4.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdatedPrompt(true)
                            setDataUpdated(true)
                            handleInputChangeYaerlyTarget();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="Quarter 4" variant="outlined" margin="dense" />
                      </div>
                    </div>
                    <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3">
                      <div className="col-12 d-flex justify-content-between"></div>
                      <div className="col-sm-12 col-md-4 col-lg-4 mb-2 pr-0"></div>
                      <div className="col-sm-8 col-md-8 col-lg-8 mb-2 pr-3"> </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12"><h6 className="setTarget-yearly-target mb-2">Yearly Target</h6></div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                        <TextField
                          id="quarterCurrencies"
                          value={finalData?.yearlyTaget?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="yearlyTarget"
                          type={finalData?.yearlyTaget?.amount > 0 ? "text" : "number"}
                          value={finalData?.yearlyTaget?.amount || 0}
                          // disabled={disableMonths.dec}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = finalData
                            initialValue.yearlyTaget.amount = updateValue
                            setFinalData({ ...initialValue });
                            setDataUpdatedPrompt(true)
                            setDataUpdated(true)
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="Yearly" variant="outlined" margin="dense" />
                      </div>
                    </div>
                  </div>
                </TabPanel>
              )}
              <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                <div className="col-12 d-flex mt-2 mb-2 p-0">
                  <div className="col-6 px-4">
                  </div>
                  <div className="col-6 onboard-footer">
                    <div className="">
                      <Button
                        variant="contained" color="primary"
                        className={`blueActionButtonSave border-left rounded-pill mr-5 mt-2 mb-2`}
                        onClick={() => {
                          setTargetApiCall(); setDataUpdated(false); setDataUpdatedPrompt(false);
                        }}
                      >{dataUpdated ? 'Update' : 'Save'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </ThemeProvider>
        </div>
      </div>
      <Prompt when={dataUpdatedPrompt} message={"Do you wish to proceed without saving the data?"}></Prompt>
      <ImportExcelSetTarget openUploadImportExcel={openUploadImportExcel} handleUploadImportExcel={handleUploadImportExcel} selectYear={selectYear} />
      <DeleteDialog
        open={confirmDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any) => {
          if (data === 'delete' || data === 'back') {
            setselectedTab(newValue);
            setSelectedTabBrand(newValueBrand);
            if (selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.length > 0)
              selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.map((ele: any) => {
                return setSelectedTabData(JSON.parse(JSON.stringify({ ...getDefaultTargetDetails.data.data.plan[newValue] })))
              })
            // if (selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.length > 0)
            // selectedTabData && selectedTabData.targetsByOrgId && selectedTabData.targetsByOrgId.map((ele: any) => {
            //   return setFinalData(JSON.parse(JSON.stringify({ ...ele && ele[newValueBrand] })))
            // })
            // setFinalData(JSON.parse(JSON.stringify({ ...getDefaultTargetDetails.data.data.plan[newValue] })))
            handleopenClose(false);
            setDataUpdated(false);
          }
          if (!data) { handleopenClose(false); }
        }} />
    </Fragment >
  );
};

export default SetTarget;
