/* eslint-disable */
import React, { Fragment, Dispatch, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import { createTheme } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import XLSX from 'xlsx';
import moment from "moment-timezone";
import { deletePartnerDocument, resetValues, updateDocument } from '../../store/actions/onboard.actions';
import { get } from 'lodash';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import DeleteDialog from '../../common/components/deleteDialog';
import TextField from '@material-ui/core/TextField';
import HistoryIcon from '@material-ui/icons/History';
import { getTandCHistory, postTandCSaveDetails } from '../../store/actions/dealer.action';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
const regex: any = /<br\s*[\/]?>/gi;
import FullscreenLoader from "../../common/components/FullscreenLoader";
import _ from "lodash";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    title: {
        flex: '1 1 100%',
    },
    buttonDel: {
        color: "#1A9FE0",
        fontSize: '0.625rem',
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        float: 'right'
    },
    buttonIconDel: {
        color: "#1A9FE0",
    },
    bodyContent: {
        color: '#666666',
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 600
    },
    createButton: {
        backgroundColor: '#1A9FE0',
        width: 140,
        fontWeight: 800,
        textTransform: 'inherit',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#FFFFFF',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 8
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 140,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    addUpdateDailogTitle: {
        position: "absolute",
        top: 10,
        left: 0
    },
    rowCustomSummary: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}));

const theme = createTheme({
    palette: {
        secondary: {
            main: '#1A9FE0'
        },
    }
});

export default function PublishRevisedTandCDialog(props: any) {
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const dispatch: Dispatch<any> = useDispatch();
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const [errorMessage, showErrorMessage] = React.useState<any>(null);
    const { setOpenDialog } = props;
    const [checkbox, setCheckbox] = React.useState<boolean>(false);
    const [titleError, setTitleError] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>("");
    const [invalidTitleError, setInvalidTitleError] = React.useState<boolean>(false);
    const [titleList, setTitleList] = React.useState();
    const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
    const b2bUserId: any = selectedNewDealer && selectedNewDealer.createdByB2bUserId;
    const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
    const [verify, setVerify] = React.useState<any>(false);
    const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
    const { getTandCHistoryData } = useSelector((state: any) => state.dealer)
    const [loading, setLoading] = React.useState(false);
    const userType = _.get(profileDetails, "data.data.basicInfo.userType")
    const [sortedData, setSortedData] = React.useState([]);

    useEffect(() => {
        if (getTandCHistoryData && getTandCHistoryData.data && getTandCHistoryData.data.tAndcData && getTandCHistoryData.data.tAndcData.length > 0) {
            let sorted: any = getTandCHistoryData?.data?.tAndcData.sort((a: any, b: any) => new Date(b.createOn).getTime() - new Date(a.createOn).getTime());
            setSortedData(sorted);
        }
    }, [getTandCHistoryData]);

    const handleopenCloseErrorSnackbar = (open?: any) => {
        setOpenErrorSnackbar(open ? true : false);
    };

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    const resetData = (open?: any) => {
        setInvalidTitleError(false);
        setTitleError(false);
        setTitle("");
        setCheckbox(false);
    };

    const handleInputChange = (e: any, setterName: any, errorSetterName?: any, emiratesId?: any) => {
        setterName(e.target.value);
        if (errorSetterName && e.target.required) {
            if (e.target.value === "" || e.target.value.trim() === "")
                errorSetterName(true);
            else
                errorSetterName(false);
        }
    }

    function validateFormFields(action: string, value: any) {
        switch (action) {
            // case "title": {
            //     const re = /^[a-zA-Z0-9 ]{1,50}$/;
            //     return value.length > 250 || !re.test(value) ? false : true;
            // }
            default:
                return true

        }
    }


    function ValidatePublishTandC() {
        let isValid: boolean = true;
        if (title.trim() === "") {
            setTitleError(true);
            isValid = false;
            showErrorMessage("Please enter the T&C title.");
            handleopenCloseErrorSnackbar(true);
        }
        if (title.trim() !== "") {
            if (title.split('').length > 50) {
                setInvalidTitleError(true);
                isValid = false;
            }
            else if (!validateFormFields("title", title)) {
                setInvalidTitleError(true);
                isValid = false;
            }
            else {
                setInvalidTitleError(false);
            }
        }
        if (checkbox === false || !checkbox) {
            setTitleError(true);
            isValid = false;
            showErrorMessage("Please select the checkbox.");
            handleopenCloseErrorSnackbar(true);
        }
        if (isValid) {
            publishTandCAPI();
        }
    }

    const publishTandCAPI = async () => {
        setLoading(true);
        let payload = {
            "userType": userType,
            "webapplicationloginId": webApplicationLoginId,
            "title": title,
            "notifyToAll": checkbox
        }
        let responseData: any = await dispatch(postTandCSaveDetails(payload))
        if (responseData && responseData.status === 400) {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(responseData && responseData.error);
            setOpenDialog();
            resetData();
            setLoading(false);
        } else {
            setOpenErrorSnackbar(false);
            handleopenCloseSnackbar(true);
            setSuccessMessage(responseData && responseData.message && responseData.message.message);
            setTitle("");
            setLoading(false);
        }
    }

    // ONCLICK OF VIEW HISTORY 
    const onClickViewHistory = async () => {
        setLoading(true);
        setVerify(true);
        setTitle("");
        const titleData: any = await dispatch(getTandCHistory(webApplicationLoginId));
        if (titleData && titleData.message && titleData.message.status === 400) {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(titleData && titleData.message && titleData.message.error);
            setLoading(false);
        } else {
            setOpenErrorSnackbar(false);
            handleopenCloseSnackbar(true);
            setSuccessMessage(titleData && titleData.message && titleData.message.message);
            setLoading(false);
        }
    }

    const classes = useStyles();
    return (
        <Fragment>
            {loading && <FullscreenLoader />}
            {getTandCHistoryData && getTandCHistoryData.loading && <FullscreenLoader />}

            <div className={classes.root}>
                <Snackbar
                    open={openSnackbar}
                    handleopenClose={() => {
                        handleopenCloseSnackbar(false)
                    }} message={successMessage} />
                <Snackbar
                    open={openErrorSnackbar}
                    type="error"
                    handleopenClose={() => {
                        handleopenCloseErrorSnackbar(false)
                    }} message={errorMessage} />
                <>
                    {!verify && (
                        <Toolbar
                            className={clsx(classes.root)}>
                            <div className="col-12 mb-2 mt-2">
                                <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                    <h4 className="shift-to-party-page-title">
                                        <div className={` pr-4 ${classes.addUpdateDailogTitle}`}>
                                            Publish Revised T&C
                                        </div>
                                        <IconButton aria-label="close" className="float-right" onClick={() => { setOpenDialog(); resetData(); setVerify(false); }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </h4>
                                </Typography>
                            </div>
                        </Toolbar>
                    )}
                    {verify && (
                        <Toolbar
                            className={clsx(classes.root)}>
                            <div className="col-12 mb-2 mt-2">
                                <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                    <h4 className="shift-to-party-page-title">
                                        <div className={` pr-4 ${classes.addUpdateDailogTitle}`}>
                                            T&C History
                                        </div>
                                        <IconButton aria-label="close" className="float-right" onClick={() => { setOpenDialog(); resetData(); setVerify(false); }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </h4>
                                </Typography>
                            </div>
                        </Toolbar>
                    )}
                </>
                {!verify && (
                    <>
                        <div className={`px-3 p-1 TandC-font-style-no row col-12 ${classes.rowCustomSummary} justify-content-start`}>
                            <span className="tandcHeading-orderlog"><p className='px-2'> Do you wish to Publish the T&C to all TP users ?</p></span>
                            <div className="row col-12 px-4">
                                <Checkbox checked={checkbox} className="" onChange={() => { setCheckbox(!checkbox); }} />
                                <p className="myOrders">By submitting, Customer will get notified to Read, Accept and Updated T&C. </p>
                            </div>
                        </div>
                        <div className='col-12 mt-4 mb-3 px-4'>
                            <TextField
                                required
                                id="title"
                                value={title}
                                error={titleError || invalidTitleError}
                                inputProps={{ maxLength: 50 }}
                                // helperText={invalidTitleError &&
                                //     <span style={{ color: 'red' }}> {"Please enter 50 characters only."}</span>
                                // }
                                onChange={(e: any) => handleInputChange(e, setTitle, setTitleError)}
                                size="small"
                                className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                                label="T&C Title"
                                variant="outlined"
                            />
                        </div>
                    </>
                )}
            </div>
            <div>
                {verify && (
                    <div className="col-12">
                        <div className="d-flex flex-row border-bottom">
                            <IconButton className="p-0 m-1 toast-header-icon-tandc px-1" edge="end" aria-label="add"
                                onClick={() => { setVerify(false); }}>
                                <ChevronLeftIcon fontSize="medium" />
                                <span className="info-sub-header-tandc m-2 mt-2 px-0">Back</span>
                            </IconButton>
                        </div>
                        <div>
                            <div className="subTitle text-preline mt-2"></div>
                        </div>
                        <div>
                            {sortedData.map((data: any) => {
                                return <div className='border-bottom px-3'>
                                    <div className="subTitle text-preline mt-2">{data.title ? data.title.replace(regex, "\n") : '-'}</div>
                                    <div className='row col-12 px-4'><h6 className="row document-sub-content-tandc pt-1 pb-2 mb-2 pr-3">Published by KAM : <h6 className='row document-sub-content px-3'> {getTandCHistoryData && getTandCHistoryData.data && getTandCHistoryData.data.firstName ? getTandCHistoryData.data.firstName : ""} {" "} {getTandCHistoryData && getTandCHistoryData.data && getTandCHistoryData.data.lastName ? getTandCHistoryData.data.lastName : ""}</h6></h6> <h6 className='px-3'>|</h6>  <h6 className="row document-sub-content-tandc pt-1 pb-2 px-4 mb-2">{data.createOn ? moment(data.createOn).tz('Asia/Dubai').format('DD-MM-YYYY') : '-'}</h6></div>
                                </div>
                            })}
                        </div>
                    </div>
                )}
            </div>
            {!verify && (
                <DialogActions className="col-12 row mt-3 mb-3">
                    <div className='col-4 row'>
                        <Button
                            className="mr-2 text-info"
                            startIcon={<HistoryIcon className="justify-content-start TandC-history-icon font-size-22 mb-0 mt-2" />}
                            onClick={() => { onClickViewHistory(); }}
                        ><span className="TandC-onAccess-font mt-3 mb-2 pr-0" onClick={() => { }}
                        >T&C History
                            </span>
                        </Button>
                    </div>
                    <div className='col-8 d-flex justify-content-end row'>
                        <Button variant="contained"
                            color="secondary" className={`createFavourite ${classes.createButton}`}
                            onClick={() => {
                                ValidatePublishTandC();
                            }}
                        > Submit
                        </Button>
                        <Button variant="contained" className={classes.closeButton}
                            onClick={() => { setOpenDialog(); resetData(); setVerify(false); }}
                        >Cancel</Button>
                    </div>
                </DialogActions>
            )}
        </Fragment >
    );
}