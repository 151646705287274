/* eslint-disable */
import React, { Fragment, Dispatch, useState, useEffect } from "react";
import { get, isEmpty, find } from "lodash";
import "./OrgSetting.css";
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import GetAppIcon from '@material-ui/icons/GetApp';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import AddUserDialog from './AddUserDialog'
// import Snackbar from './../../common/components/Snackbar';
import Snackbar from "./../../common/components/CreatedEnquirySnackbar";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { getAllUsers, getDealerDetails, setUserDetails, getUploadedDocuments, getUserDetails, uploadDocument, deleteDocument, postSetDefaultAddress } from "../../store/actions/dealer.action";
import { AddShipToPartyAddress, deleteShipToPartyAddress, getSelectedShipToPartyAddress, UpdatedShipToPartyAddress, getTradeRegion, saveZone, setCustomerType, postDeliveryType, getRegion } from '../../store/actions/editDealer.actions';
import AddDealerDialog from '../Dealers/AddDealerDialog';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import { getCreditLimit } from "../../store/actions/creditLimit.actions";
import { Grid } from "@material-ui/core";
import SearchBar from "../favorites/searchBar";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import NotificationIcon from "../../common/components/NotificationIcon";
import { userTypes } from "../../constants/userTypes";
import { getBrandName } from "../../common/components/util";
import { getProfile } from "../../store/actions/profile.action";
import XLSX from 'xlsx';
import { getShipToPartyAddress, resetValues, getTransportationZone, getDealingIn, getTypeOfTrade, getNationality, downloadCategoryCertificate } from '../../store/actions/onboard.actions';
import FullscreenLoader from './../../common/components/FullscreenLoader';
import DealerDetails from "./DealerDetails";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import moment from "moment-timezone";
import Visibility from "@material-ui/icons/Visibility";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { getAddNewAuthorisedSignatories, saveAuthorisedSignatories, updateAuthorisedSignatories, deleteAuthorisedSignatories, updateDocument, getAllSignatories, changeCustomerType, saveDocument } from '../../store/actions/editDealer.actions';
import Dialog from '@material-ui/core/Dialog';
import AddNewAuthorisedSignatoriesDailog from "../ViewEditDealerDetailsKam/addNewAuthorisedSignatories";
import SellingPrice from "./SellingPrice";
import { Prompt } from "react-router-dom";
import MoveOutDialog from "../ViewEditDealerDetailsKam/MoveOutDialog";
import DeleteDialog from "../../common/components/deleteDialog";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import _ from "lodash";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { MenuItem } from '@material-ui/core';
import InputLabel from "@material-ui/core/InputLabel";
import { debug } from "console";
import { KeyboardDatePicker } from '@material-ui/pickers';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from "@material-ui/icons/Delete";
import OrgDocuments from "./Documents";
import { AddNewAddressDailog } from "../ViewEditDealerDetailsKam/AddNewAddressDailog";
import DeleteDialogOnboarding from "../../common/components/deleteDialogOnboarding";
import DeleteDocumentDialog from "../../common/components/deleteDocumentDialog";
import { updateDealerData } from '../../store/actions/dealer.action';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
  header: {
    color: '#133F8A',
    fontFamily: 'Nunito',
    fontSize: 12,
    fontWeight: 900,
    minWidth: 87,
    padding: 14
  },
  body: {
    color: '#666666',
    fontFamily: 'Nunito',
    fontSize: 13,
    fontWeight: 600,
    padding: 15
  },
  actions: {
    color: '#1A9FE0',
    fontSize: 13,
    fontFamily: 'Nunito',
    fontWeight: 700
  },
  error: {
    color: '#1A9FE0',
    fontSize: 13
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
  oppacityLess: {
    opacity: 0.5,
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const OrgSetting: React.FC = (props: any) => {
  const [selectedUserId, setSelectedUserId] = React.useState("");
  const [sapCustomerId, setSapCustomerId] = React.useState<any>();
  // const [b2bUserId, setb2bId] = React.useState<any>();
  const [addressID, setAddressID] = React.useState<any>();
  const [featureCodeLabel, setFeatureCodeLabel] = React.useState<any>();
  const [featureCode, setFeatureCode] = React.useState<any>();
  const [featureCodeValue, setFeatureCodeValue] = React.useState<any>();
  const classes = useStyles();
  const [selectedTab, setselectedTab] = React.useState('Dealership');
  const [tabValue, setTabValue] = React.useState<any>(null);
  const dispatch: Dispatch<any> = useDispatch();
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [openEditInfo, setOpenEditInfo] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openAddressSnackbar, setOpenAddressSnackbar] = React.useState(false);
  const [customerDetails, setCustomerDetails]: any = React.useState();
  const [isDialogForEdit, setIsDialogForEdit] = React.useState(false);
  const [isUserDialogForEdit, setIsUserDialogForEdit] = React.useState(false);
  const [isUserDialogForView, setIsUserDialogForView] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [openDealerSnackbar, setOpenDealerSnackbar] = React.useState(false);
  const [isValid, setIsvalid] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [customerDocuments, setCustomerDocuments] = React.useState([]);
  const [selected, setSelected] = React.useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const { shipToPartyAddressData, uploadedDocumentData, getSelectedAddress, regionData, typeofTradeData, dealingInData, getNationalityData, zoneData, getAllSignatoriesData }: any = useSelector((state: any) => state.onboard);
  const [editedAddress, setEditAddress] = React.useState({});
  const [addressList, setAddressList] = React.useState<any>([]);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [shippingAddress, setShippingAddress] = React.useState<any>(null);
  const { selectedNewDealer, isEditDealer }: any = useSelector((state: any) => state.dealer);
  const [sapId, setSapId] = React.useState<string>();
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [apiResponseText, setApiResponseText] = React.useState(false);
  const [selectedZone, setSelectedZone] = React.useState<any>([]);
  const [isValidAddress, setIsValidAddress] = React.useState<boolean>(true);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { creditLimitDetails }: any = useSelector((state: any) => state.creditLimitDetails);
  const creditLimitData = get(creditLimitDetails, 'data.data.data', []);
  const { dealerDetails, updateDealer, usersList, uploadedDocuments, uploadDocumentData, removeDocumentData }: any = useSelector((state: any) => state.dealer);
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
  const customerId = sessionStorage.getItem('customerId')
  const companyBrands: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data) ? dealerDetails.data.data.organizationIds : null;
  const brandIds = companyBrands && companyBrands.map((id: any) => id.organizationId);
  const [isView, setIsView] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [addNewAuthorisedSignatories, setAddNewAuthorisedSignatories]: any = React.useState([])
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [moveOutDialog, setmoveOutDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const b2bUserId = get(profileDetails, 'data.data.basicInfo.b2bUserId', '');
  const featureAccessibility = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.accessParameter && profileDetails.data.data.accessParameter.featureAccessibility);
  const sapCustomerID = get(profileDetails, 'data.data.basicInfo.sapCustomerId', "");
  const [openSnackbarType, setOpenSnackbarType] = React.useState("");
  const defaultAddressId: any = get(dealerDetails, 'data.data.defaultAddressId', '');
  const isAdmin = _.get(profileDetails, "data.data.basicInfo.userType", "");
  const [isEditDocuments, setIsEditDocuments] = React.useState(false);
  const [tradeReference, setTradeReference] = React.useState<any>([
    {
      tradeLicenseNo: "",
      selectRegion: "",
      tradeLicenseExpiryDate: null,
      tradeLicense: null,
    }
  ]);
  const [inCompleteFileds, setInCompleteFileds] = React.useState<any>(false);
  const [invalidSelectRegion, setInvalidSelectRegion] = React.useState<string>("");
  const [selectRegionError, setSelectRegionError] = React.useState<boolean>(false);
  const [documentType, setDocumentType] = React.useState<any>(null);
  const [selectedType, setSelectedType] = React.useState<any>();
  const [currentEmiratesIndex, setCurrentEmiratesIndex] = React.useState<any>();
  const [openDeleteDialogDocument, setOpenDeleteDialogDocument] = React.useState(false);
  const [selectedTradeReference, setSelectedTradeReference] = React.useState<any>([]);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openDeleteDialogTrade, setOpenDeleteDialogTrade] = React.useState(false);
  const [loading, setLoading] = React.useState<any>(false);
  const [memoRandumExpiryDate, setMemoRandumExpiryDate] = React.useState<any>(null);
  const [memorandamCopy, setMemorandamCopy] = React.useState<any>({ documentId: '', expiryDate: '' });
  const [vatCertificateCopy, setVatCertificateCopy] = React.useState({ documentId: '', expiryDate: '' });
  const [companySealCopy, setCompanySealCopy] = React.useState({ documentId: '' });
  const [memoRandumRegFile, setMemoRandumRegFile] = React.useState<any>(null);
  const [vatRegExpiryDate, setVatRegExpiryDate] = React.useState<any>(null);
  const [isValidVatRegExpiryDate, setIsValidVatRegExpiryDate] = React.useState<any>(true);
  const [vatRegFile, setVatRegFile] = React.useState<any>(null);
  const [companySealFile, setCompanySealFile] = React.useState<any>(null);
  const [tradeLicense, setTradeLicense] = React.useState<any>(null);
  const [firstAddressCheck, setFirstAddressCheck] = React.useState<any>(false);
  const [isDefaultAdd, setIsDefaultAdd] = React.useState<any>(false);
  const [dataUpdatedQuotation, setDataUpdatedQuotation] = React.useState(false);
  const [selectedDeliveryType, setSelectedDeliveryType] = React.useState<string>('DLV');
  const [isDefaultUAE, setIsDefaultUAE] = React.useState("");
  const [salesRegion, setSalesRegion] = React.useState("");
  const [masterCompanyBrands, setMasterCompanyBrands] = React.useState<any>([]);
  const [isCustomerAcceptingDelivery, setIsCustomerAcceptingDelivery] = React.useState(true);
  const [isEditOwnerPartnerOrg, setIsEditOwnerPartnerOrg] = React.useState(false);
  // const blockIntent = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.blockIntent;
  const sapCustomerIdAdmin = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId ? profileDetails.data.data.basicInfo.sapCustomerId : '';
  const [isEditOrgDocument, setIsEditOrgDocument] = React.useState(false);
  const [dataFetched, setDataFetched] = React.useState<any>(false);
  const [dealerstatus, setStatus] = React.useState<any>("");
  const [customer, setCustomer] = React.useState<any>("")
  const [dealerDetail, setDealerDetail] = React.useState<any>();
  const [isEditOrgOthersDocument, setIsEditOrgOthersDocument] = React.useState(false);
  const [isValidMemoRandumExpiryDate, setIsValidMemoRandumExpiryDate] = React.useState<any>(true);
  const { getDocumentExpiryStatusData }: any = useSelector((state: any) => state.profileDetails);
  const [blockIntent, setBlockedIntent] = React.useState<any>(false);
  const [reUploadedMOADocument, setReUploadedMOADocument] = React.useState<any>(false);
  const [getTradeLicneceDocStatus, setGetTradeLicneceDocStatus] = React.useState<any>(false);
  const blockIntentProfileAPI = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.blockIntent;

  const onClickEditAddress = async (addressId: any) => {
    setIsEdited(true);
    setSapId(userType === "Admin" && blockIntent === true ? sapCustomerIdAdmin : sapCustomerId)
    await dispatch(getSelectedShipToPartyAddress(userType === "Admin" && blockIntent === true ? sapCustomerIdAdmin : sapCustomerId, addressId.id));
    setSelected(addressId.id);
    addressId.showSapId = false;
    setOpen(true)
  }


  function isButtonEnabled(selectedDate: any) {
    const currentDate: any = new Date();
    moment(currentDate).utc().format();
    if (new Date(selectedDate).getTime() <= currentDate.getTime()) {
      return false;
    } else {
      const oneDay = 24 * 60 * 60 * 1000;
      const expiryDate: any = new Date(selectedDate);
      const diffDays = Math.round(Math.abs((expiryDate - currentDate) / oneDay));
      if (diffDays <= 15) {
        return false
      } else {
        return true
      }
    }
  }

  const downloadCertificate = async () => {
    setLoading(true);
    let payload = {
      "customerName": dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.companyName ? dealerDetails.data.data.companyName : "",
      "currentYear": new Date().getUTCFullYear()
    }
    let responseData: any = await dispatch(downloadCategoryCertificate(payload))

    if (responseData && responseData.status === 400) {

    } else {
      let ele: any = JSON.parse(responseData && responseData.data)
      // const tempDiv = document.createElement('div');
      // tempDiv.innerHTML = ele;
      // tempDiv.style.position = 'absolute';
      // tempDiv.style.top = '-9999px';
      // document.body.appendChild(tempDiv);
      // html2canvas(tempDiv, { useCORS: true, scale: 2 }).then((canvas) => {
      //   const imgData = canvas.toDataURL('image/png');
      //   const pdf = new jsPDF();
      //   // const imgWidth = 210;
      //   // let imgHeight = (canvas.height * imgWidth) / canvas.width;
      //   // pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      //   pdf.addImage(imgData, 'PNG', 0, 0,210,290)
      //   pdf.save('Certificate.pdf');
      //   document.body.removeChild(tempDiv);
      //   setLoading(false);
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = ele;
      tempDiv.style.width = '800px';
      tempDiv.style.position = 'absolute';
      tempDiv.style.top = '-9999px';
      document.body.appendChild(tempDiv);
      html2canvas(tempDiv, { scale: 2, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdfDOC = new jsPDF();
        const width = pdfDOC.internal.pageSize.getWidth();
        let height = pdfDOC.internal.pageSize.getHeight();
        pdfDOC.addImage(imgData, 'PNG', 0, 0, width, height);
        pdfDOC.save('certificate.pdf');
        document.body.removeChild(tempDiv);
        setLoading(false);
      });
    }
  }


  const populateDocumentDetails = (data: any) => {
    setSuccessMessage(null);
    handleopenCloseSnackbarAddress(false);
    if (data && data.data && data.data.treadLicense && data.data.treadLicense.length > 0 && !dataFetched) {
      let tread: any = [];
      data.data.treadLicense.map((tl: any) => {
        tread.push({
          selectRegion: tl.regionCode,
          tradeLicenseNo: tl.tradeLicenseNo,
          tradeLicenseExpiryDate: tl.expiryDate,
          tradeLicense: tl.documentId && tl.documentId.url ? tl.documentId : '',
          documentStatus: tl.documentStatus,
          id: tl.id
        })
      })
      if (data.data.treadLicense.length === 0) {
        tread.push({
          tradeLicenseNo: "",
          selectRegion: "",
          tradeLicenseExpiryDate: null,
          tradeLicense: null,
        })
      }
      setTradeReference(tread);
      setSelectedTradeReference(tread);
      handleopenClose(false);
    }
    if (data && data.data && data.data.status) {
      setStatus(data.data.status)
    }
    if (data && data.data && data.data.memorandumOfAssociation && data.data.memorandumOfAssociation.documentId) {
      const { expiryDate, id } = data.data.memorandumOfAssociation.documentId;
      const documentStatus = data.data.memorandumOfAssociation.documentStatus;
      setMemoRandumExpiryDate(expiryDate);
      setMemorandamCopy({ documentId: id, expiryDate: expiryDate, documentStatus: documentStatus ? documentStatus : "" })
      setMemoRandumRegFile(data.data.memorandumOfAssociation.documentId)
    }
    if (data && data.data && data.data.vatCertificateCopy && data.data.vatCertificateCopy.documentId && !dataFetched) {
      const { expiryDate, id } = data.data.vatCertificateCopy.documentId;
      setVatCertificateCopy({ documentId: id, expiryDate: expiryDate })
      setVatRegExpiryDate(expiryDate)
      setVatRegFile(data.data.vatCertificateCopy.documentId);
    }
    if (data && data.data && data.data.companySeal && data && data.data.companySeal.documentId && !dataFetched) {
      const { id } = data && data.data.companySeal.documentId;
      setCompanySealCopy({ documentId: id });
      setCompanySealFile(data && data.data.companySeal.documentId)
    }
    if (!dataFetched) setDataFetched(true);
    handleopenCloseSnackbar(false);
    handleopenCloseDocument(false);
  }

  const loadSignatories = async () => {
    setSuccessMessage(null);
    handleopenCloseSnackbarAddress(false);
    // dispatch(getRegion());
    setLoading(true);
    let data: any = await dispatch(getDealerDetails(sapCustomerIdAdmin, 'documents'));
    if (data && data.data) {
      setDealerDetail(data);
      populateDocumentDetails(data);
    }
    // dispatch(getAllSignatories(sapCustomerId));
    setLoading(false);
    if (data && data.data && data.data.customerTierType) setCustomer(data && data.data && data.data.customerTierType)
    // setCustomerTierType(data && data.data && data.data.isNewCustomer)
  }

  const deleteDealerDocument = async () => {
    let data: any;
    setSelectedType(documentType);
    if (documentType == 'companySeal') {
      data = companySealFile;
    } else if (documentType == 'memorandumOfAssociation') {
      data = memoRandumRegFile;
    } else if (documentType == 'vatCertificateCopy') {
      data = vatRegFile;
    } else if (documentType == 'treadLicense') {
      let trade = [...tradeReference]
      data = trade[currentEmiratesIndex].tradeLicense;
    }
    const obj = {
      "type": documentType
    }
    // let response: any = await dispatch(deleteDocument(data.id, sapCustomerId, obj));
    let response: any
    // if (response && response.message && response.message.status === 'S') {
    if (documentType === 'companySeal') {
      setCompanySealCopy({ documentId: '' });
      setCompanySealFile(null);
    }
    if (documentType === 'memorandumOfAssociation') {
      setMemoRandumRegFile(null); setMemoRandumExpiryDate(null);
      setMemorandamCopy({ documentId: '', expiryDate: '' });
    }
    if (documentType === 'vatCertificateCopy') {
      setVatRegFile(null); setVatRegExpiryDate(null);
      setVatCertificateCopy({ documentId: '', expiryDate: '' });
    }
    if (documentType === 'treadLicense') {
      let trade = [...tradeReference]
      trade[currentEmiratesIndex].tradeLicense = null;
      trade[currentEmiratesIndex].tradeLicenseExpiryDate = null;
    }
    handleopenCloseDocument(false);
    // } else {
    //   showErrorMessage(response.error);
    //   setOpenErrorSnackbar(true);
    // }
  }

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenCloseTrade = (open?: any) => {
    setOpenDeleteDialogTrade(open ? true : false);
    setSuccessMessage(null);
  };

  const handleopenCloseDocument = (open?: any) => {
    setOpenDeleteDialogDocument(open ? true : false);
    setSuccessMessage(null);
  };

  const getNewAddressData = async (data: any) => {
    if (isEdited) {
      let result = await dispatch(UpdatedShipToPartyAddress(sapCustomerId, data, selected));
      const apiResponse = get(result, 'message', {});
      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        if (selectedDeliveryType === "DLV" && sessionStorage.getItem('customerOnboardingType') === 'Workshop') {
          await dispatch(postDeliveryType({
            "id": sapCustomerId,
            "deliveryType": 'DLV',
            "updatedBy": webApplicationLoginId,
            "updatedByRole": "kam"
          }))
        }
        handleopenCloseSnackbarAddress(true);
        setSuccessMessage(apiResponse.message);
        setTimeout(() => {
          handleopenCloseSnackbarAddress(false)
        }, 2000);
      }
    }
    else {
      let result = await dispatch(AddShipToPartyAddress(sapCustomerId, data));
      const apiResponse = get(result, 'message', {});

      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
      setSelected("");
    }
  }

  const loadData = async () => {
    const data: any = await dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
    if (data && data.data && data.data.addresses && data.data.addresses.length > 0) {
      setMasterCompanyBrands(data.data.masterCompanyBrands);
    }
  }

  //BROWSE FILE CODE 
  const onClickBrowseFile = async (type: any, item: any, setCallback: any, index?: any) => {
    setLoading(true);
    showErrorMessage(null);
    setSuccessMessage(null);
    handleopenCloseSnackbar(false);
    handleopenCloseErrorSnackbar(false);
    setCurrentEmiratesIndex(index);
    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
    if (!canUploadFile) {
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      setSuccessMessage('Invalid File type format');
      handleopenCloseSnackbarAddress(true);
      setLoading(false);
      return;
    }
    if (type === 'tradeLicenes' && item && item.size < 5000000) {
      setLoading(true);
      const canNotUploadWithoutDate = type === 'tradeLicenes';
      const formData: any = new FormData();
      let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
      let expiryDate: any = '';

      if (setCallback) {
        if (type == "tradeLicenes") {
          let tradeReferenceRow = [...tradeReference];
          tradeReferenceRow[index].tradeLicense = item;
          setCallback(tradeReferenceRow);
          // return;
        }
      }
      if (type !== "tradeLicenes") setCallback(item);
      if (canNotUploadWithoutDate) {
        if (type === 'tradeLicenes') {
          let trade = [...tradeReference];
          expiryDate = moment(trade[index].tradeLicenseExpiryDate).utc().format()
          setCallback(trade);
        };
      }
      keyAttribute = `${keyAttribute}&expiryDate=${expiryDate}`;
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };
      let docType = '';
      if (type === 'tradeLicenes') { docType = 'Trade License File' }

      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", sapCustomerId ? sapCustomerId : '');
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'dealerUser');
      formData.append("uploadedBywebApplicationLoginId", '');
      formData.append("selectedFileName", item.name);
      let uploadedData: any = await dispatch(updateDocument(formData, customHeaders));
      if (uploadedData && uploadedData.data && uploadedData.message && uploadedData.message.status === 'S') {
        // setSuccessMessage("Document uploaded successfully.");
        setLoading(false);
        // dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'documents'));
        // handleopenCloseSnackbar(true);
        const { documentStatus, label, expiryDate, url, id, sapCustomerId } = uploadedData.data;
        if (label === 'tradeLicenes') {
          let tradeReferenceRow: any = [...tradeReference];
          tradeReferenceRow[index].tradeLicense = uploadedData.data;
          tradeReferenceRow[index].documentStatus = documentStatus ? documentStatus : "";
          tradeReferenceRow[index].tradeLicenseExpiryDate = expiryDate;
          setTradeReference(tradeReferenceRow);
          setSelectedTradeReference(tradeReferenceRow);
        }
      } else {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setSuccessMessage('Document upload failed');
        handleopenCloseSnackbarAddress(true);
        setLoading(false);
        if (type === 'tradeLicenes') {
          let tradeReferenceRow: any = [...tradeReference];
          tradeReferenceRow[index].tradeLicense = null;
          tradeReferenceRow[index].tradeLicenseExpiryDate = null;
          setTradeReference(tradeReferenceRow);
          setSelectedTradeReference(tradeReferenceRow);
        }
      }
    } else if (type !== 'tradeLicenes' && item && item.size < 2000000) {
      setLoading(true);
      const canNotUploadWithoutDate = type === 'memorandumOfAssociation' || type === 'vatCertificateFile';
      const formData: any = new FormData();
      let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
      let expiryDate: any = '';

      if (canNotUploadWithoutDate) {
        if (type === 'memorandumOfAssociation') expiryDate = moment(memoRandumExpiryDate).utc().format();
        else if (type === 'vatCertificateFile') expiryDate = moment(vatRegExpiryDate).utc().format();
      }
      keyAttribute = `${keyAttribute}&expiryDate=${expiryDate}`;
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };
      let docType = '';
      if (type === 'memorandumOfAssociation') { docType = 'Memorandum File' }
      else if (type === 'vatCertificateFile') { docType = 'Vat Certificate File' }
      else if (type === 'companySealFile') { docType = 'Company Seal File' }

      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", sapCustomerId ? sapCustomerId : '');
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'dealerUser');
      formData.append("uploadedBywebApplicationLoginId", '');
      formData.append("selectedFileName", item.name);
      let uploadedData: any = await dispatch(updateDocument(formData, customHeaders));
      if (uploadedData && uploadedData.data && uploadedData.message && uploadedData.message.status === 'S') {
        // setSuccessMessage(uploadedData.message.message);
        setLoading(false);
        // handleopenCloseSnackbar(true);
        // dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'documents'));
        const item = uploadedData && uploadedData.data ? uploadedData.data : {};
        const { documentStatus, label, expiryDate, url, id, sapCustomerId } = item;
        if (label === 'memorandumOfAssociation') {
          setMemoRandumExpiryDate(expiryDate);
          setMemorandamCopy({ documentId: id, expiryDate: memoRandumExpiryDate, documentStatus: documentStatus ? documentStatus : "" });
          setMemoRandumRegFile(uploadedData.data);
        }
        else if (label === 'vatCertificateFile') {
          setVatCertificateCopy({ documentId: id, expiryDate: expiryDate });
          setVatRegExpiryDate(expiryDate);
          setVatRegFile(uploadedData.data);
        }
        else if (label === 'companySealFile') {
          setCompanySealCopy({ documentId: id });
          setCompanySealFile(uploadedData.data)
        }
      } else {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setSuccessMessage('Document upload failed');
        handleopenCloseSnackbarAddress(true);
        setLoading(false);
        if (type === 'memorandumOfAssociation') {
          setMemoRandumExpiryDate(null);
          setMemorandamCopy({ documentId: '', expiryDate: '' });
          setMemoRandumRegFile(null);
        }
        else if (type === 'vatCertificateFile') {
          setVatCertificateCopy({ documentId: '', expiryDate: '' });
          setVatRegExpiryDate(null);
          setVatRegFile(null);
        }
        else if (type === 'companySealFile') {
          setCompanySealCopy({ documentId: '' });
          setCompanySealFile(null)
        }
      }
    } else if (type === 'tradeLicenes') {
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      setSuccessMessage('Please upload less than 5MB file for Trade License Copy.');
      handleopenCloseSnackbarAddress(true);
      setLoading(false);
    } else if (type !== 'tradeLicenes') {
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      setSuccessMessage('Please upload less than 2MB file for Trade License Copy.');
      handleopenCloseSnackbarAddress(true);
      setLoading(false);
    }
  }

  function randomString() {
    let chars = '0123456789abcdefghijklmnopqrstuvwxyz-';
    let length = 35;
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  // VALIDATION ERROR MESSAGE CODE FOR ADD MORE TEXTFILEDS
  const handleInputChange = (e: any, type: any, index: number, errorSetterName?: any, errorErrorMessage?: any) => {
    let trade = [...tradeReference]
    switch (type) {
      case "selectRegion":
        trade[index].selectRegion = e.target.value
        break;
      case "tradeLicenseNo":
        trade[index].tradeLicenseNo = e.target.value;
        // validateTradeLicense(e.target.value, index);
        break;
      case "tradeLicenseExpiryDate":
        let date = new Date(e)
        if (date > new Date() || e == null) {
          trade[index].tradeLicenseExpiryDate = e;
          trade[index].dateError = '';
          trade[index].invalidDate = false;
        } else {
          trade[index].dateError = "Invalid Date Format"
          trade[index].invalidDate = true
        }
        break;
      case "tradeLicense":
        break;
      default: ;
    }
    setTradeReference(trade);
    setSelectedTradeReference(trade);

    // HANDLE ONCHANGE CODE
    if (errorSetterName && e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
      }

      else {
        errorSetterName(false);
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenAddress = () => {
    setOpen(true);
  };

  const handleopenCloseMoveOut = (open?: any) => {
    setmoveOutDialog(open ? true : false);
  };

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };

  // ADD NEW USER AUTHORISED SIGANTORIES CODE
  const getNewAuthorisedSignatories = (data: any) => {
    addNewAuthorisedSignatories.push(data);
    dispatch(saveAuthorisedSignatories(data));
    setAddNewAuthorisedSignatories(addNewAuthorisedSignatories);
  }

  // UPDATE USER AUTHORISED SIGANTORIES CODE
  const updateNewAuthorisedSignatories = (data: any) => {
    dispatch(updateAuthorisedSignatories(data));
    setSuccessMessage("Documets Updated Successfully.");
    handleopenCloseSnackbarAddress(true);
  }

  // const filteredBrands = creditLimitData && creditLimitData.filter((x: any, index: any) => {
  //   if (brandIds && brandIds.length > 0) {
  //     return brandIds.includes(x.brandId);
  //   }
  //   return undefined;
  // });

  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      return regionName && regionName.length > 0 ? regionName[0].Region : '-';
    }
    return "";
  }

  useEffect(() => {
    if (getDocumentExpiryStatusData && getDocumentExpiryStatusData.data && getDocumentExpiryStatusData.data.data && (getDocumentExpiryStatusData.data.data.messages.length === 0 || getDocumentExpiryStatusData.data.data.messages.length > 0)) {
      setBlockedIntent(true);
    } else {
      setBlockedIntent(false);
    }
    // !isEmpty(getDocumentExpiryStatusData) && getDocumentExpiryStatusData && getDocumentExpiryStatusData.data && getDocumentExpiryStatusData.data.data && getDocumentExpiryStatusData.data.data.messages.length > 0 && getDocumentExpiryStatusData.data.data.messages.map((ele: any) => {
    //   setBlockedIntent(true);
    // })
  }, [getDocumentExpiryStatusData]);

  useEffect(() => {
    // dispatch(getShipToPartyAddress(b2bUserId));
    if (zoneData && zoneData.data && zoneData.data.length > 0) {
    } else {
      dispatch(getTransportationZone(b2bUserId));
    }
    setSuccessMessage(null)
    handleopenCloseSnackbar(false);
    dispatch(resetValues());
    if (regionData && regionData.data && regionData.data.length > 0) { } else {
      dispatch(getRegion());
    }
    if (dealingInData && dealingInData.data && dealingInData.data.length > 0) {
    } else {
      dispatch(getDealingIn());
    }
    if (typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0) {
    } else {
      dispatch(getTypeOfTrade());
    }
    if (getNationalityData && getNationalityData.data && getNationalityData.data.length > 0) {
    } else {
      dispatch(getNationality());
    }
  }, []);

  useEffect(() => {
    if (shipToPartyAddressData && shipToPartyAddressData.data) {
      setAddressList(shipToPartyAddressData.data.addresses);
      setSapId(userType === "Admin" && blockIntent === true ? sapCustomerIdAdmin : shipToPartyAddressData.data.sapCustomerId);
    }
    else if (shipToPartyAddressData && shipToPartyAddressData.err) {
    }
    if (getSelectedAddress && getSelectedAddress.data) {
      setEditAddress(getSelectedAddress ? getSelectedAddress.data : {})
    }
    else {
      setSelected("");
    }
  }, [shipToPartyAddressData, getSelectedAddress])

  const getAuthorisedFor = (data: any) => {
    if (data.authorizedForCheque && data.authorizedForPurchaseOffice) {
      return 'Cheques , Purchase Orders';
    } else if (data.authorizedForCheque) {
      return 'Cheques'
    } else if (data.authorizedForPurchaseOffice) {
      return 'Purchase Orders'
    } else {
      return '-'
    }
  }

  useEffect(() => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
      dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'dealerDetails'));
      setSapCustomerId(profileDetails.data.data.basicInfo.sapCustomerId);
      // setb2bId(profileDetails.data.data.basicInfo.b2bUserId);
      let featureAccessibilityAddress = featureAccessibility.filter((ele: any) => {
        return ele && ele.label == "Select Default address"
      });
      featureAccessibilityAddress.map((ele: any) => {
        return <>
          {setFeatureCodeLabel(ele && ele.label)}
          {setFeatureCode(ele && ele.featureCode)}
          {setFeatureCodeValue(ele && ele.value)}
        </>
      })
      // if (customerTierType === 'Dealer' && creditLimitData && creditLimitData.length === 0) {
      if (creditLimitData && creditLimitData.length === 0) {
        dispatch(getCreditLimit(customerId, webApplicationLoginId));
      }
    }
  }, [])

  useEffect(() => {
    if (selectedTab !== "DocumentsTab") {
      if (updateDealer && updateDealer.data && updateDealer.data.data && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo) {
        setIsvalid(false);
        dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'dealerDetails'));
      }
    }
  }, [updateDealer]) // eslint-disable-line

  const getUsers = () => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
      setPage(0);
      dispatch(getAllUsers(profileDetails.data.data.basicInfo.sapCustomerId));
    }
  }

  useEffect(() => {
    if (uploadDocumentData && uploadDocumentData.data && uploadDocumentData.data.data && uploadDocumentData.data.data.data) {
      if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
        setIsvalid(false);
        dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'dealerDetails'));
      }
    }
  }, [uploadDocumentData]); // eslint-disable-line

  // useEffect(() => {
  //   const item = uploadedDocumentData && uploadedDocumentData.data ? uploadedDocumentData.data : {};
  //   const { documentStatus, expiryDate, id, label } = item;
  //   if (label === 'memorandumOfAssociation') {
  //     setMemoRandumExpiryDate(expiryDate);
  //     setMemorandamCopy({ documentId: id, expiryDate: memoRandumExpiryDate, documentStatus: documentStatus ? documentStatus : "" });
  //     setMemoRandumRegFile(uploadedDocumentData.data);
  //   }
  //   else if (label === 'vatCertificateFile') {
  //     setVatCertificateCopy({ documentId: id, expiryDate: expiryDate });
  //     setVatRegExpiryDate(expiryDate);
  //     setVatRegFile(uploadedDocumentData.data);
  //   }
  //   else if (label === 'companySealFile') {
  //     setCompanySealCopy({ documentId: id });
  //     setCompanySealFile(uploadedDocumentData.data)
  //   }
  // }, [uploadedDocumentData]); // eslint-disable-line

  useEffect(() => {
    if (removeDocumentData && removeDocumentData.data && removeDocumentData.data.data && removeDocumentData.data.data && removeDocumentData.data.data.message && removeDocumentData.data.data.message.status === "S") {
      if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
        setIsvalid(false);
        dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'dealerDetails'));
      }
    }
  }, [removeDocumentData]); // eslint-disable-line

  const columns = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'userid',
      label: 'Internal ID (for AFG use)',
      align: 'left',
    },
    {
      id: 'b2cUserId',
      label: 'Login ID',
      align: 'left',
    },
    {
      id: 'usertype',
      label: 'User Type',
      align: 'left'
    },
    {
      id: 'email',
      label: 'Email',
      align: 'left'
    },
    {
      id: 'mobile',
      label: 'Mobile',
      align: 'left'
    },
    {
      id: 'brands',
      label: 'Brands',
      align: 'left'
    },
    {
      id: 'status',
      label: 'Status',
      align: 'left'
    },
  ];

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setselectedTab(newValue);
  };

  const handleChangePage = (newPage: number) => {
    if (searchValue === "") {
      dispatch(getAllUsers(dealerDetails.data.data.sapCustomerId, newPage));
    }
    else {
      dispatch(getAllUsers(dealerDetails.data.data.sapCustomerId, newPage, searchValue));
    }
    setPage(newPage);
  };

  const handleopenCloseAddUser = (open?: any) => {
    setOpenAddUser(open ? true : false);
  };

  const handleopenCloseEditInfo = (open?: any) => {
    setOpenEditInfo(open ? true : false);
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseSnackbarAddress = (open?: any) => {
    setOpenAddressSnackbar(open ? true : false);
  };

  const handleopenCloseDealerSnackbar = (open?: any) => {
    setOpenDealerSnackbar(open ? true : false);
  }

  const loadProfileDetails = () => {
    const b2cUid = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');
    if (b2cUid !== "undefined" && b2cUid !== null && userType !== 'undefined' && userType !== null) {
      dispatch(getProfile(b2cUid, userType));
    }
  };

  const addTradeRow = () => {
    let trade = [...tradeReference];
    let allow = true;
    trade.map((tradeItemMap: any) => {
      tradeItemMap.error = null;
      if (!tradeItemMap.tradeLicenseNo || !tradeItemMap.selectRegion || !tradeItemMap.tradeLicenseExpiryDate || !tradeItemMap.tradeLicense) {
        allow = false;
      }
    });
    if (allow) {
      const data = {
        tradeLicenseNo: "",
        selectRegion: "",
        tradeLicenseExpiryDate: null,
        tradeLicense: ""
      }
      trade.push(data);
      setTradeReference(trade);
      setSelectedTradeReference(trade);
      setInCompleteFileds(false);
    } else {
      setInCompleteFileds(true);
    }
    return allow;
  }

  // DELETE ROW ONCLICK OF ADD MORE DELETE ICON
  const deleteTradeRow = () => {
    let trade = [...tradeReference];
    trade = trade.filter((element, index1) => {
      return index1 != currentEmiratesIndex;
    })
    setTradeReference(trade);
    setSelectedTradeReference(trade);
    handleopenCloseTrade(false);
  }

  //CODE FOR DATE PICKER
  function datePickerValid(e: any, state: any, index?: any) {
    if (state === 'trade') {
      let trade = [...tradeReference]
      if (e === "Invalid Date Format") {
        trade[index].dateError = "Invalid Date Format"
        trade[index].invalidDate = true
      } else if (e === "Date should be less than today date.") {
        trade[index].dateError = "Date should be greater than today date."
        trade[index].invalidDate = true
      } else if (e === "Date should not be before minimal date") {
        trade[index].dateError = "Date should not be before minimal date"
        trade[index].invalidDate = true
      } else {
        trade[index].dateError = '';
        trade[index].invalidDate = false
      }
    } else {
      if (e === "Invalid Date Format") {
        state(false);
        // setSubmitDisabled(true);
      } else if (e === "Date should not be before minimal date") {
        state(false);
      } else if (e === "Date should not be after maximal date") {
        state(false);
      } else {
        state(true);
      }
    }
  }

  const getPrevDocId = (type: any, index?: any) => {
    if (dealerDetail && dealerDetail.data) {
      if (type === 'companySeal') {
        if (dealerDetail?.data.companySeal && dealerDetail?.data.companySeal.documentId) {
          const { id } = dealerDetail?.data.companySeal.documentId;
          return id;
        }
      } else if (type === 'memorandumOfAssociation') {
        if (dealerDetail?.data.memorandumOfAssociation && dealerDetail?.data.memorandumOfAssociation.documentId) {
          const { id } = dealerDetail?.data.memorandumOfAssociation.documentId;
          return id;
        }
      } else if (type === 'vatCertificateCopy') {
        if (dealerDetail?.data.vatCertificateCopy && dealerDetail?.data.vatCertificateCopy.documentId) {
          const { id } = dealerDetail?.data.vatCertificateCopy.documentId;
          return id;
        }
      } else if (type === 'tradeItem') {
        if (dealerDetail.data && dealerDetail.data.treadLicense && dealerDetail.data.treadLicense.length > 0) {
          let data = dealerDetail.data.treadLicense[index];
          if (data && data.documentId) {
            const { id } = data.documentId;
            return id;
          }
        }
      }
    }
  }

  const isValidDocuments = () => {
    let isValid = true;
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && vatCertificateCopy.documentId === '' && customer !== "Government") {
        isValid = false;
      }
      if (vatRegExpiryDate !== null && vatCertificateCopy.documentId == "" && sessionStorage.getItem('customerOnboardingType') === 'Government') {
        isValid = false
      }
      if (sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && companySealCopy.documentId === '' && customer !== "Government") {
        isValid = false;
      }
    }
    let trade = [...selectedTradeReference];
    trade.map((tradeItemMap: any) => {
      tradeItemMap.error = null;
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (customer !== "Government" || sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
          if (!tradeItemMap.tradeLicenseNo || tradeItemMap.isError || !tradeItemMap.selectRegion || !tradeItemMap.tradeLicenseExpiryDate || !tradeItemMap.tradeLicense) {
            isValid = false;
          } else if (tradeItemMap.tradeLicenseNo || tradeItemMap.selectRegion || tradeItemMap.tradeLicenseExpiryDate || tradeItemMap.tradeLicense && tradeItemMap.tradeLicense.fileName) {
            if (!tradeItemMap.tradeLicenseNo || tradeItemMap.isError || !tradeItemMap.selectRegion || !tradeItemMap.tradeLicenseExpiryDate || !tradeItemMap.tradeLicense || tradeItemMap.invalidDate) {
              isValid = false;
            }
          }
        }
      }
    });
    return isValid;
  }

  // API INTEGARTION CODE
  function isValidDocumentTab() {
    showErrorMessage(null);
    handleopenCloseErrorSnackbar(false);
    if (isValidDocuments()) {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && getAllSignatoriesData && getAllSignatoriesData.data && getAllSignatoriesData.data.length === 0) {
          // setOpenErrorSnackbar(true);
          setInCompleteFileds(true);
          handleopenCloseErrorSnackbar(true);
          showErrorMessage('Please add atleast one Authorized Signatories');
        } else {
          setInCompleteFileds(false);
          return true;
        }
      } else {
        setInCompleteFileds(false);
        return true;
      }
    } else {
      if (sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
        setInCompleteFileds(true);
        showErrorMessage("Please fill remaining fields in Trade License");
        handleopenCloseErrorSnackbar(true);
        return false;
      } else {
        setInCompleteFileds(true);
        showErrorMessage("Please fill all mandatory fields and upload all mandatory documents");
        handleopenCloseErrorSnackbar(true);
        return false;
      }
    }
  }

  async function saveDocumentDetails(isSubmitted?: any) {
    // dispatch(resetValues());
    handleopenCloseSnackbar(false);
    let selectedTrade: any[] = [];
    let trade = [...selectedTradeReference];
    trade.map((t: any) => {
      selectedTrade.push({
        "regionCode": t.selectRegion,
        "tradeLicenseNo": t.tradeLicenseNo,
        "expiryDate": moment(t.tradeLicenseExpiryDate).utc().format() === "Invalid date" ? null : moment(t.tradeLicenseExpiryDate).utc().format(),
        "documentId": t.tradeLicense && t.tradeLicense.id ? t.tradeLicense.id : '',
        id: t.id ? t.id : randomString()
      })
    })
    const customerInfo = dealerDetails && dealerDetails.data;
    let data = {
      "b2bUserId": b2bUserId,
      "sapCustomerId": sapCustomerId,
      "treadLicense": selectedTrade,
      "companySeal": companySealCopy,
      "revisedDocument": userType === "Admin" && blockIntent === true ? true : false,
      "reUpload": userType === "Admin" && blockIntent === true && reUploadedMOADocument === true || userType === "Admin" && blockIntent === true && getTradeLicneceDocStatus === true ? true : false,
      "memorandumOfAssociation": memorandamCopy,
      "vatCertificateCopy": vatCertificateCopy,
      "isSubmited": false,
    }
    setLoading(true);
    const savedData: any = await dispatch(updateDealerData(sapCustomerId, data));
    if (savedData && savedData.message && savedData.message.status === 'S') {
      loadSignatories();
      setLoading(false);
      setSuccessMessage("Documets Updated Successfully.");
      handleopenCloseSnackbarAddress(true);
    } else {
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      setSuccessMessage(savedData.error);
      handleopenCloseSnackbarAddress(true);
      setLoading(false);
    }
  }

  const setMarkAsDefaultAddress = async (id: any) => {
    let data: any = {
      "addressId": id,
      "sapCustomerId": sapCustomerID,
      "b2bUserId": b2bUserId,
      "webApplicationLoginId": webApplicationLoginId,
      "addressSetByAdmin": isAdmin === "Admin" ? true : false,
      "adminB2bUserId": isAdmin === "Admin" ? b2bUserId : "",
    }
    let defaultAddressData: any = await dispatch(postSetDefaultAddress(data))
    if (defaultAddressData && defaultAddressData.status === 400) {
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      setSuccessMessage(defaultAddressData && defaultAddressData.message);
      handleopenCloseSnackbarAddress(true);
    } else {
      setOpenErrorSnackbar(false);
      setSuccessMessage(defaultAddressData && defaultAddressData.message && defaultAddressData.message.message);
      handleopenCloseSnackbarAddress(true);
      dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'shipToParty'));
    }
  }

  const uploadFile = async (file: any) => {
    const { name } = file,
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || name,
      doctExtensions = ['jpg', 'jpeg', 'png'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;

    if (!canUploadFile) {
      return;
    }

    const sapCustomerId: any = get(dealerDetails, 'data.data.sapCustomerId', false),
      organization = get(dealerDetails, 'data.data.organizationIds[0]', {});

    const formData: any = new FormData();

    let keyAttribute = `fieldname=${name}&docExtension=${doctExtension}`;

    if (!sapCustomerId) { return; }


    const customHeaders = {
      "Accept": "application/json",

      "Lob-Id": "AUTO",
      "Channel-Id": "B2B",
      "Org-Id": organization.organizationId || "",
      "Accept-Language": "EN",
    };

    formData.append("entityName", "CUSTOMER");
    formData.append("keyAttribute", keyAttribute);
    formData.append("fileUpload", file);
    formData.append("document", 'false');
    formData.append("Attributes", `key=Brand&value=${file}`);
    formData.append("sapCustomerId", sapCustomerId);
    formData.append("uploadedByRole", 'dealerUser');
    formData.append("uploadedBywebApplicationLoginId", sessionStorage.getItem('webApplicationLoginId'));

    await dispatch(uploadDocument(formData, customHeaders));
    loadProfileDetails();
  }
  const getDetailedExposure = (creditExposure: any) => {
    const credLimit = creditExposure.split('.')[0];
    if (credLimit.includes("000")) {
      const number = credLimit.substring(0, credLimit.lastIndexOf("000"));
      return number;
    } else if (credLimit > 1000) {
      return (credLimit / 1000 | 0);
    } else return credLimit
  }

  const getCreditValue = (creditLimit: any, creditExposure: any) => {
    let value = (parseInt(creditExposure.split('.')) / parseInt(creditLimit.split('.'))) * 100;
    if (value > 100 || value < 0) value = -1;
    return value;
  }

  const resetFiltersForCustomer = () => {
    setSearchValue("");
    setPage(0);
    dispatch(getAllUsers(profileDetails.data.data.basicInfo.sapCustomerId));
  }

  const showCreditStatus = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_CREDIT_STATUS);
  const showManageUser = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.MANAGER_USER);
  const showViewUser = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_USERS);
  const userType = get(profileDetails, 'data.data.basicInfo.userType', "");
  const shipToPartyFeatureAccess = get(profileDetails, 'data.data.customerInfo.shipToPartyFeatureAccess', '');

  const searchDealerList = (e: any, isSearch: any) => {
    if (selectedTab) {
      if (searchValue.trim() === "") {
        dispatch(getAllUsers(dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.sapCustomerId, 0, searchValue));
      } else {
        dispatch(getAllUsers(dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.sapCustomerId, 0, searchValue));
      }
    }
  }

  //TABLE HEADER CODE
  const headCells = [
    { id: 'firstName', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
    { id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile' },
    { id: 'designation', numeric: true, disablePadding: false, label: 'Designation' },
    { id: 'emiratesIdNo', numeric: true, disablePadding: false, label: 'Emirates ID No' },
    { id: 'authorisedFor', numeric: true, disablePadding: false, label: 'Authorized for' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
  ];

  function EnhancedTableHead(props: any) {
    const classes = useStyles();
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={classes.bodyContent}
              key={headCell.id}
              // align={headCell.numeric ? headCell.id === 'actions' || headCell.id === 'brands' ? 'center' : 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Fragment>
      <Snackbar
        open={openAddressSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        message={successMessage}
        handleopenClose={() => {
          handleopenCloseSnackbarAddress(false);
        }}
      />
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Organization Setting
        </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <div className="row mt-2">
        <Snackbar
          open={openDealerSnackbar}
          text="Details Updated Successfully"
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }}
        />
        <AddDealerDialog
          open={openEditInfo}
          isEdit={isDialogForEdit}
          hideAdmin
          handleopenClose={(data: any) => {
            if (data) {
              handleopenCloseDealerSnackbar(true)
            }
            dispatch(getDealerDetails(sapCustomerId, 'dealerDetails'));
            handleopenCloseEditInfo(false)
          }}
        />
        <Snackbar
          open={openSnackbar}
          text="New User Created Successfully"
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }}
        />
        {loading && (<FullscreenLoader />)}
        {openAddUser &&
          <AddUserDialog
            open={openAddUser}
            isEdit={isUserDialogForEdit}
            setIsEdit={setIsUserDialogForEdit}
            isView={isUserDialogForView}
            setIsView={setIsUserDialogForView}
            setInProgress={setInProgress}
            setSelectedUserId={setSelectedUserId}
            inProgress={inProgress}
            sapCustomerId={sapCustomerId}
            isAdmin={false}
            page={page}
            selectedUserId={selectedUserId}
            handleopenClose={(data: any) => {
              if (data) {
                handleopenCloseSnackbar(true)
              }
              handleopenCloseAddUser(false)
            }}
          />
        }
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <ThemeProvider theme={theme}>
            <Paper square className="bg-transparent">
              <Tabs
                className="tabSelect"
                value={selectedTab}
                textColor="primary"
                indicatorColor="primary"
                onChange={(e: any, newValue: any) => {
                  if (newValue === 'DocumentsTab') {
                    // dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'documents'));
                    loadSignatories();
                    setIsEditOrgDocument(false);
                    setIsEditOrgOthersDocument(false);
                  } else if (newValue === 'Address') {
                    dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'shipToParty'));
                  } else if (newValue === 'Dealership') {
                    dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'dealerDetails'));
                  } else if (newValue === 'Users') {
                    dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'shipToParty'));
                    getUsers();
                  }
                  if (!dataUpdated) {
                    handleTabChange(e, newValue);
                  } else {
                    handleopenClose(true);
                    setTabValue(newValue);
                    return
                  }
                }}
                aria-label="disabled tabs example">
                <Tab label="Your Details" value="Dealership" {...a11yProps('Dealership')} />
                <Tab label="Your Documents" value="DocumentsTab" {...a11yProps('DocumentsTab')} />
                <Tab label="Your Delivery Addresses" value="Address" {...a11yProps('Address')} />
                {showViewUser && <Tab label="Your Users" value="Users" {...a11yProps('Users')} />}
                {userType === 'Admin' && (
                  <Tab label="Set Selling Price" value="sellingPrice" {...a11yProps('sellingPrice')} />
                )}
              </Tabs>
            </Paper>
          </ThemeProvider>
        </div>
        <Divider light />
      </div>
      <TabPanel value={selectedTab} index="Dealership">
        <DealerDetails dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} userType={userType} />
      </TabPanel>
      <TabPanel value={selectedTab} index="DocumentsTab">
        {dealerDetails && dealerDetails.loading && (
          <div className="mx-auto my-5">
            <FullscreenLoader />
          </div>
        )}
        {/* {shipToPartyAddressData && shipToPartyAddressData.loading && <FullscreenLoader />} */}
        {!isEditOrgDocument ?
          <div className="row mt-3">
            <div className="col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex align-items-center">
                <h5 className="page-sub-title mt-3 mb-2">List of Documents</h5>
              </div>
            </div>
          </div>
          : ""}
        {!isEditOrgDocument ?
          <div className="card col-12 m-0 p-0">
            <div className="row mb-0 mt-0 px-2">
              <div className="col-12 mt-1 mb-1">
                <div className="d-flex align-items-center mt-3 mb-3">
                  <h5 className="info-sub-header-document px-4">Trade License</h5>
                  <div className="row-space-start ml-auto">
                    {userType === "Admin" && blockIntent === true ?
                      <Button className="text-info" startIcon={<EditOutlinedIcon />}
                        onClick={() => {
                          setIsEditOrgDocument(true);
                        }}>
                        <span className="onshiftoparty-font mt-1 mb-0 pr-0">Edit</span>
                      </Button>
                      : ""}
                  </div>
                </div>
                <div className="row m-0 col-12 mt-2 mb-3">
                  <div className="col-3 col-md-2 col-sm-2">
                    <div className="info-sub-title-value font-size-12">Region</div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-2">
                    <div className="info-sub-title-value font-size-12">Trade License Number</div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-2">
                    <div className="info-sub-title-value font-size-12">Expiry Date</div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-2">
                    <div className="info-sub-title-value font-size-12">Supported Document</div>
                  </div>
                  <div className="col-2 col-md-3 col-sm-2">
                  </div>
                </div>
                {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.treadLicense && dealerDetails.data.data.treadLicense.length > 0 && dealerDetails.data.data.treadLicense.map((treadLicense: any, index: any) => (
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 col-12 mt-2 mb-2 border-bottom">
                      <div className="col-3 col-md-2 col-sm-2 mt-3">
                        <div className="subTitle mt-2">{getRegionName(treadLicense.regionCode)}</div>
                      </div>
                      <div className="col-3 col-md-3 col-sm-2 mt-3">
                        <div className="subTitle mt-2 mb-3">{treadLicense.tradeLicenseNo}</div>
                      </div>
                      <div className="col-3 col-md-3 col-sm-2 mt-3">
                        <div className="subTitle mt-2 mb-3">{moment(treadLicense.expiryDate).format('DD MMM YYYY')}</div>
                      </div>
                      {dealerDetails && dealerDetails.data && dealerDetails.data.data.treadLicense && treadLicense.documentId && treadLicense.documentId.url && (
                        <div className="col-3 col-md-3 col-sm-2 mt-3">
                          <div className="subTitle mt-1 mb-3">
                            <span className="info-sub-header-document pr-2 mt-0" onClick={() => {
                              window.open(treadLicense.documentId.url)
                            }}>{treadLicense.documentId.selectedFileName ? treadLicense.documentId.selectedFileName : "-"}
                            </span>
                            <IconButton className="p-1" aria-label="download" onClick={() => { window.open(treadLicense.documentId.url) }}>
                              <GetAppIcon className="file-close-color" fontSize="small" />
                            </IconButton>
                          </div>
                        </div>
                      )}
                      <div className="col-2 col-md-3 col-sm-2">
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          : ""}
        {isEditOrgDocument ? (
          <div className="card mt-3">
            <div className="row m-0 p-0 border-bottom">
              <div className="col-md-2 col-lg-2 mb-2 mt-3">
                <h5 className="info-sub-header-document mt-3 mb-0 px-4">Trade License</h5>
                {isEditOrgDocument && (
                  <div className="px-3">
                    <IconButton disabled className="p-0 m-1 toast-header"
                      edge="end" aria-label="add"
                    //  disabled
                    >
                      <AddCircleIcon fontSize="medium" onClick={() => {
                        addTradeRow();
                      }} />
                    </IconButton>
                    <span className="info-sub-header-document mt-2">Add More</span>
                  </div>
                )}
              </div>
              <div className="col-md-10 col-lg-10 mb-2 pl-0 mt-3 trade onboarding-field">
                {tradeReference && tradeReference.length > 0 && tradeReference.map((tradeItem: any, index: any) => {
                  return <div className="col-md-12 col-lg-12 mb-2 pl-0 mt-0">
                    <TextField select id="selectRegion"
                      value={tradeItem.selectRegion}
                      // disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                      error={!tradeItem.selectRegion && inCompleteFileds}
                      disabled
                      helperText={invalidSelectRegion}
                      onChange={(e: any) => handleInputChange(e, "selectRegion", index, setSelectRegionError, setInvalidSelectRegion)}
                      size="small" className="col-sm-3 my-2 pr-2 commonRoundedInputs mt-2"
                      label="Select Region" required variant="outlined" margin="dense">
                      {regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((item: any) => (
                        <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
                    </TextField>
                    <TextField id="tradeLicenseNo"
                      type="text"
                      disabled
                      value={tradeItem.tradeLicenseNo}
                      // disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                      error={!tradeItem.tradeLicenseNo && inCompleteFileds}
                      // onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                      helperText={tradeItem.errormessage && <span style={{ color: 'red' }}>{tradeItem.errormessage}</span>}
                      inputProps={{ maxLenght: 30 }}
                      onChange={(e: any) => handleInputChange(e, "tradeLicenseNo", index)}
                      size="small" className="col-sm-3 my-2 pr-2 commonRoundedInputs mt-2"
                      label="Trade License No" required variant="outlined" margin="dense" />
                    <KeyboardDatePicker
                      views={["year", "month", "date"]}
                      openTo="year"
                      disablePast
                      // disabled={dealerstatus == "Active" ? false : true}
                      // disabled
                      onChange={(date: Date | null) => {
                        handleInputChange(date, "tradeLicenseExpiryDate", index);
                      }}
                      onError={(e, v) => datePickerValid(e, 'trade', index)}
                      disabled={tradeItem.documentStatus === "Re Upload" ? false : tradeItem.tradeLicense ? true : false}
                      autoOk size="small"
                      disableToolbar
                      error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : !tradeItem.tradeLicenseExpiryDate && inCompleteFileds || tradeItem.invalidDate}
                      variant="inline" inputVariant="outlined"
                      format="dd/MM/yyyy" margin="normal"
                      label={`Expiry Date  ${"*"}`}
                      value={tradeItem.tradeLicenseExpiryDate ? tradeItem.tradeLicenseExpiryDate : null}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      className={'col-sm-2 my-2 pr-3 rounded-input-dealer cal-icon'}
                      helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : tradeItem.invalidDate && <span style={{ color: 'red' }}>{tradeItem.dateError}</span>}
                    />
                    {tradeItem.tradeLicense ?
                      <div className="file-name ml-2">
                        <span className="cursor-pointer" onClick={() => {
                          window.open(tradeItem.tradeLicense.url)
                        }}>{tradeItem.tradeLicense.name ? tradeItem.tradeLicense.name : tradeItem.tradeLicense.selectedFileName}</span>
                        <IconButton className="p-1 mt-2 "
                          aria-label="delete"
                          disabled={tradeItem.documentStatus === "Re Upload" ? false : isButtonEnabled(tradeItem.tradeLicenseExpiryDate) === false ? false : true}
                          onClick={(e: any) => {
                            setDocumentType('treadLicense');
                            setCurrentEmiratesIndex(index);
                            handleopenCloseDocument(true);
                            if (tradeItem.documentStatus === "Re Upload") {
                              setGetTradeLicneceDocStatus(true);
                            } else {
                              setGetTradeLicneceDocStatus(false);
                            }
                          }}>
                          <CancelIcon className={
                            tradeItem.documentStatus === "Re Upload" ? "file-close-color" : isButtonEnabled(tradeItem.tradeLicenseExpiryDate) === false ? "file-close-color" : "file-close-color disabledCustomclass"
                          } fontSize="small" />
                        </IconButton>
                      </div>
                      :
                      <Button variant="contained"
                        color="secondary"
                        component="label"
                        disabled={tradeItem.documentStatus === "Re Upload" ? false : tradeItem.tradeLicenseExpiryDate ? false : true}
                        className={`col-md-3 col-lg-3 rounded-button-dealer mt-2 mr-4 px-4 ml-2 ${!tradeItem.tradeLicenseExpiryDate ? classes.oppacityLess : ""}`}>
                        Upload Trade license
                        <input type="file" hidden
                          onChange={(e) =>
                            (e.target && e.target.files) ?
                              onClickBrowseFile('tradeLicenes', e.target.files[0], setTradeReference, index) : setTradeLicense(null)
                          } />
                      </Button>
                    }
                    {tradeReference && tradeReference.length === 1 ? '' : (
                      <IconButton
                        disabled
                        // disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                        className="col-md-0 mt-2 float-right " size="small"
                        onClick={() => {
                          setCurrentEmiratesIndex(index);
                          handleopenCloseTrade(true)
                        }}>
                        <DeleteIcon
                          fontSize="inherit" />
                        <span className="font-size-13 load-from-fav ml-1 mt-0">
                        </span>
                      </IconButton>
                    )}
                    {tradeItem.error ? (<div className="text-red">{tradeItem.error}</div>) : ''}
                  </div>
                })}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="card col-12 m-0 p-0">
          <div className="row mb-1 mt-1 px-2">
            <div className="col-12 mt-1 mb-1">
              <div className="d-flex align-items-center mt-0 mb-0">
                <h5 className="info-sub-header-document mt-3 mb-0 px-4">Authorized Signatories</h5>
              </div>
            </div>
          </div>
          <div className="row m-0 p-0 mt-2 mb-3 px-2">
            <div className="col-12 px-4">
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                  />
                  <TableBody>
                    <>
                      {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.authorizedSignatories && dealerDetails.data.data.authorizedSignatories.length > 0 && dealerDetails.data.data.authorizedSignatories.map((authorizedSignatories: any, index: any) => (
                        <TableRow hover tabIndex={-1}>
                          <TableCell component="th"
                            scope="row" padding="none" className="document-tablecell-data">
                            {authorizedSignatories.firstName}{'  '}{authorizedSignatories.lastName}
                          </TableCell>
                          <TableCell align="left" className="document-tablecell-data">
                            {authorizedSignatories.email}
                          </TableCell>
                          <TableCell align="left" className="document-tablecell-data">
                            {authorizedSignatories.mobile}
                          </TableCell>
                          <TableCell align="left" className="document-tablecell-data">
                            {authorizedSignatories.designation}
                          </TableCell>
                          <TableCell align="left" className="document-tablecell-data">
                            {authorizedSignatories.emiratesIdNumber}
                          </TableCell>
                          <TableCell align="left" className="document-tablecell-data">
                            {getAuthorisedFor(authorizedSignatories)}
                          </TableCell>
                          <TableCell align="left">
                            <div className="row">
                              <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                <Button
                                  className="text-info"
                                  startIcon={<Visibility />}
                                  onClick={() => {
                                    dispatch(getAddNewAuthorisedSignatories(authorizedSignatories.id, sapCustomerId))
                                    handleClickOpen();
                                    setIsView(true);
                                    setIsEdit(true);
                                    setEditData(authorizedSignatories);
                                  }}>
                                  View
                                </Button>
                                {userType === "Admin" && blockIntent === true ?
                                  <Button
                                    className="text-info"
                                    startIcon={<EditOutlinedIcon />}
                                    onClick={() => {
                                      dispatch(getAddNewAuthorisedSignatories(authorizedSignatories.id, sapCustomerId))
                                      handleClickOpen();
                                      setIsView(false);
                                      setIsEdit(true);
                                      setEditData(authorizedSignatories);
                                    }}>
                                    Edit
                                  </Button> : ''}
                              </ButtonGroup>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <Dialog className="pb-0"
            open={open}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                handleClose()
              }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
            disableEscapeKeyDown
          >
            <AddNewAuthorisedSignatoriesDailog setOpenDialog={handleClose} getNewAuthorisedSignatories={getNewAuthorisedSignatories}
              isEdit={isEdit}
              editData={editData}
              updateNewAuthorisedSignatories={updateNewAuthorisedSignatories}
              isView={isView}
            />
          </Dialog>
          <DeleteDialogOnboarding
            open={openDeleteDialogTrade}
            text="Are you sure you want to delete this Trade License Details ?"
            handleopenCloseTrade={(data: any) => {
              if (data === 'delete')
                deleteTradeRow();
              if (!data) handleopenCloseTrade(false);
            }} />
          <DeleteDocumentDialog
            open={openDeleteDialogDocument}
            text="Are you sure you want to delete this document ?"
            handleopenCloseDocument={(data: any) => {
              if (data === 'delete')
                deleteDealerDocument();
              if (!data) handleopenCloseDocument(false);
            }} />
        </div>
        {!isEditOrgOthersDocument ?
          <div className="card col-12 m-0 p-0">
            <div className="row mb-0 mt-0 px-2">
              <div className="col-12 mt-1 mb-1">
                <div className="d-flex align-items-center mt-3 mb-3">
                  <h5 className="info-sub-header-document px-4">Other Docs</h5>
                  <div className="row-space-start ml-auto">
                    {userType === "Admin" && blockIntent === true ?
                      <Button className="text-info" startIcon={<EditOutlinedIcon />}
                        onClick={() => {
                          setIsEditOrgOthersDocument(true);
                        }}>
                        <span className="onshiftoparty-font mt-1 mb-0 pr-0">Edit</span>
                      </Button>
                      : ''}
                  </div>
                </div>
                <div className="col-12 mt-1 mb-1">
                  <div className="row col-md-12 mb-2 mt-2 px-4">
                    {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.companySeal && dealerDetails.data.data.companySeal.documentId && dealerDetails.data.data.companySeal.documentId.url && (
                      <div className="col-md-4 col-lg-4 pr-3 mb-2">
                        <h6 className="document-content px-2 mb-2">Company Seal</h6>
                        <small className="document-sub-content px-2"> ----- </small><br></br>
                        <div className="mb-2 mt-4">
                          <IconButton size="small" className="" onClick={() => { window.open(dealerDetails.data.data.companySeal.documentId.url) }}>
                            <AssignmentReturnedIcon className="download-icon mr-1" />
                          </IconButton>
                          <span className="download-docs mr-2 px-2" onClick={() => {
                            window.open(dealerDetails.data.data.companySeal.documentId.url)
                          }}> {dealerDetails.data.data.companySeal.documentId ? dealerDetails.data.data.companySeal.documentId.selectedFileName : "-"}
                          </span>
                        </div>
                        <div className="info-sub-title-value font-size-12 px-0"> </div>
                      </div>
                    )}
                    {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.memorandumOfAssociation && dealerDetails.data.data.memorandumOfAssociation.documentId && dealerDetails.data.data.memorandumOfAssociation.documentId.url && (
                      <div className="col-md-4 col-lg-4 pr-3 mb-2">
                        <h6 className="document-content px-0">Memorandum Of Association (MOA)</h6>
                        <small className="document-sub-content">Power Of Attorney (POA)</small><br></br>
                        <small className="mb-3">If you are not the Owner, it's mandatory to upload.</small>
                        <div className="mb-2 mt-2">
                          <IconButton size="small" className="" onClick={() => { window.open(dealerDetails.data.data.memorandumOfAssociation.documentId.url) }}>
                            <AssignmentReturnedIcon className="download-icon mr-1" />
                          </IconButton>
                          <span className="download-docs mr-2 px-2" onClick={() => {
                            window.open(dealerDetails.data.data.memorandumOfAssociation.documentId.url)
                          }}>{dealerDetails.data.data.memorandumOfAssociation.documentId ? dealerDetails.data.data.memorandumOfAssociation.documentId.selectedFileName : "-"}
                          </span>
                        </div>
                        <div className="info-sub-title-value font-size-12 px-0">Expiry Date : {moment(dealerDetails.data.data.memorandumOfAssociation.expiryDate).format('DD MMM YYYY')} </div>
                      </div>
                    )}
                    {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.vatCertificateCopy && dealerDetails.data.data.vatCertificateCopy.documentId && dealerDetails.data.data.vatCertificateCopy.documentId.url && (
                      <div className="col-md-4 col-lg-4 pr-3 mb-2">
                        <h6 className="document-content px-0">VAT Certificate Copy</h6>
                        <small className="document-sub-content mb-2">One Time With Issue Date</small>
                        <div className="mb-2 mt-4">
                          <IconButton size="small" className="" onClick={() => { window.open(dealerDetails.data.data.vatCertificateCopy.documentId.url) }}>
                            <AssignmentReturnedIcon className="download-icon mr-1" />
                          </IconButton>
                          <span className="download-docs mr-2 px-2" onClick={() => {
                            window.open(dealerDetails.data.data.vatCertificateCopy.documentId.url)
                          }}>{dealerDetails.data.data.vatCertificateCopy.documentId ? dealerDetails.data.data.vatCertificateCopy.documentId.selectedFileName : "-"}
                          </span>
                        </div>
                        <div className="info-sub-title-value font-size-12 px-0">Expiry Date : {moment(dealerDetails.data.data.vatCertificateCopy.expiryDate).format('DD MMM YYYY')}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          : ""}
        {isEditOrgOthersDocument ?
          <>
            <div className="card col-12 m-0 p-0 mt-2">
              <div className="col-12 mt-1 mb-1">
                <div className="d-flex align-items-center mt-3 mb-3">
                  <h5 className="info-sub-header-document px-4">Other Docs</h5>
                </div>
              </div>
              <div className="px-4">
                {customer !== "Government" && <div className="row m-0 p-0 border-bottom">
                  <div className="col-md-4 col-lg-4 pr-2 mb-2">
                    <h6 className="document-content pt-3 pb-2">Company Seal{' '} {sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerOnboardingType') === 'Workshop' ? "" : "*"}</h6>
                    <small className="document-content">--</small>
                  </div>
                  <div className="col-md-2 col-lg-2 pr-5 mb-2 pl-0 mt-2">
                    {companySealFile !== null ?
                      <span className="file-name">
                        <span className="cursor-pointer" onClick={() => {
                          window.open(companySealFile.url)
                        }}>{companySealFile.name ? companySealFile.name : companySealFile.selectedFileName}</span>
                        {isEditOrgOthersDocument && (
                          <IconButton className="p-1 " aria-label="delete"
                            disabled
                            onClick={(e) => {
                              setDocumentType('companySeal');
                              handleopenCloseDocument(true);
                            }}>
                            <CancelIcon className="file-close-color disabledCustomclass" fontSize="small" />
                          </IconButton>
                        )}
                      </span>
                      :
                      <>
                        {isEditOrgOthersDocument && (
                          <Button variant="contained"
                            color="secondary"
                            component="label"
                            disabled
                            className="col-md-12 rounded-button-dealer mt-2 mr-4 px-4">
                            Browse File
                            <input type="file" hidden
                              onChange={(e) =>
                                (e.target && e.target.files) ?
                                  onClickBrowseFile('companySealFile', e.target.files[0], setCompanySealFile, null) : setCompanySealFile(null)
                              } />
                          </Button>
                        )}
                      </>
                    }
                  </div>
                </div>}
                {customer !== "Government" && <div className="row  m-0 p-0 border-bottom">
                  <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-3">
                    <h6 className="document-content">Memorandum Of Association (MOA)</h6>
                    <small className="document-sub-content pb-3">Power Of Attorney (POA)</small><br></br>
                    <small className="pb-3">If you are not the Owner, it's mandatory to upload.</small>
                  </div>
                  <div className={isEditOrgOthersDocument ? 'col-md-3 col-lg-2 pr-5 mb-2 pl-0 mt-2' : 'col-md-6 col-lg-6 pr-5 mb-2 pl-0 mt-2'}>
                    {memoRandumRegFile !== null ?
                      <>
                        <div className="file-name">
                          <span className="cursor-pointer" onClick={() => {
                            window.open(memoRandumRegFile.url)
                          }}>{memoRandumRegFile.name ? memoRandumRegFile.name : memoRandumRegFile.selectedFileName}</span>
                          {isEditOrgOthersDocument && (
                            <IconButton className="p-1  " aria-label="delete"
                              disabled={memorandamCopy && memorandamCopy.documentStatus === "Re Upload" ? false : isButtonEnabled(memoRandumExpiryDate) === false ? false : true}
                              onClick={(e) => {
                                setDocumentType('memorandumOfAssociation');
                                handleopenCloseDocument(true);
                                if (memorandamCopy && memorandamCopy.documentStatus === "Re Upload") {
                                  setReUploadedMOADocument(true);
                                } else {
                                  setReUploadedMOADocument(false);
                                }
                              }}>
                              <CancelIcon className={
                                memorandamCopy && memorandamCopy.documentStatus === "Re Upload" ? "file-close-color" : isButtonEnabled(memoRandumExpiryDate) === false ? "file-close-color" : "file-close-color disabledCustomclass"
                              } fontSize="small" />
                            </IconButton>
                          )}
                        </div>
                        {!isEditOrgOthersDocument && (
                          <div className="info-sub-title-value font-size-12 px-0">Expiry Date : {moment(memoRandumRegFile.expiryDate).format('DD MMM YYYY')} </div>
                        )}
                      </>
                      :
                      <>
                        {isEditOrgOthersDocument && (
                          <Button
                            variant="contained"
                            component={memoRandumExpiryDate && isValidMemoRandumExpiryDate ? "label" : "button"}
                            color="secondary"
                            className="col-md-12 rounded-button-dealer mt-2 mr-4 px-4"
                            disabled={memorandamCopy && memorandamCopy.documentStatus === "Re Upload" ? false : memoRandumExpiryDate === null ? true : false}
                          >Browse File
                            <input type="file" hidden
                              onChange={(e) =>
                                (e.target && e.target.files) ?
                                  onClickBrowseFile('memorandumOfAssociation', e.target.files[0], setMemoRandumRegFile, null) : setMemoRandumRegFile(null)
                              } />
                          </Button>
                        )}
                      </>
                    }
                  </div>
                  {isEditOrgOthersDocument && (
                    <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-2">
                      <KeyboardDatePicker
                        views={["year", "month", "date"]}
                        openTo="year"
                        disablePast
                        onError={(e, v) => datePickerValid(e, setIsValidMemoRandumExpiryDate)}
                        disabled={memorandamCopy && memorandamCopy.documentStatus === "Re Upload" ? false : memoRandumRegFile !== null ? true : false}
                        // disabled
                        autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                        format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={memoRandumExpiryDate}
                        onChange={(date: Date | null) => {
                          setMemoRandumExpiryDate(date); !date && setMemoRandumRegFile(null)
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        className={'col-sm-4 my-2 pr-3 rounded-input-dealer cal-icon'}
                      />
                    </div>
                  )}
                </div>}
                <div className="row m-0 p-0 border-bottom">
                  <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-3">
                    <h6 className="document-content">VAT Certificate Copy{' '}{sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : (customer !== "Government" && customer.toLowerCase() !== "workshop") ? "*" : ""}</h6>
                    <small className="document-sub-content pb-3">One Time With Issue Date</small>
                  </div>
                  <div className={isEditOrgOthersDocument ? 'col-md-3 col-lg-2 pr-5 mb-2 pl-0 mt-2' : 'col-md-6 col-lg-6 pr-5 mb-2 pl-0 mt-2'}>
                    {vatRegFile !== null ?
                      <>
                        <div className="file-name">
                          <span className="cursor-pointer" onClick={() => {
                            window.open(vatRegFile.url)
                          }}>{vatRegFile.name ? vatRegFile.name : vatRegFile.selectedFileName}</span>
                          {isEditOrgOthersDocument && (
                            <IconButton className="p-1  " aria-label="delete"
                              disabled
                              onClick={(e) => {
                                setDocumentType('vatCertificateCopy');
                                handleopenCloseDocument(true);
                              }}>
                              <CancelIcon className="file-close-color disabledCustomclass" fontSize="small" />
                            </IconButton>
                          )}
                        </div>
                        {!isEditOrgOthersDocument && (
                          <div className="info-sub-title-value font-size-12 px-0">Expiry Date : {moment(vatRegFile.expiryDate).format('DD MMM YYYY')} </div>
                        )}
                      </>
                      :
                      <>
                        {isEditOrgOthersDocument && (
                          <Button
                            variant="contained"
                            component={vatRegExpiryDate && isValidVatRegExpiryDate ? "label" : "button"}
                            color="secondary" className="col-md-12 rounded-button-dealer mt-2 mr-4 px-4"
                            disabled={vatRegExpiryDate === null ? true : false}
                          >Browse File
                            <input type="file" hidden
                              onChange={(e) =>
                                (e.target && e.target.files) ?
                                  onClickBrowseFile('vatCertificateFile', e.target.files[0], setVatRegFile, null) : setVatRegFile(null)
                              } />
                          </Button>
                        )}
                      </>
                    }
                  </div>
                  {isEditOrgOthersDocument && (
                    <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-2">
                      <KeyboardDatePicker views={["year", "month", "date"]} openTo="year"
                        disableFuture
                        onError={(e, v) => datePickerValid(e, setIsValidVatRegExpiryDate)}
                        disabled={vatRegFile !== null ? true : false}
                        // disabled
                        autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                        format="dd/MM/yyyy" margin="normal" label="Issue Date" value={vatRegExpiryDate}
                        onChange={(date: Date | null) => { setVatRegExpiryDate(date); !date && setVatRegFile(null) }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        className={'col-sm-4 my-2 pr-3 rounded-input-dealer cal-icon'}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
          : ""}
        {(userType === "Admin" && blockIntentProfileAPI === false) && (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.categoryCertificateSent === true || dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerAgreement && dealerDetails.data.data.customerAgreement.length && dealerDetails.data.data.customerAgreement.length > 0 || dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.annexureToAgreement && dealerDetails.data.data.annexureToAgreement.length && dealerDetails.data.data.annexureToAgreement.length > 0) ?
          <div className="card col-12 m-0 p-0">
            <div className="row mb-0 mt-0 px-2">
              <div className="col-12 mt-1 mb-1">
                <div className="d-flex align-items-center mt-3 mb-3">
                  <h5 className="page-sub-title px-4">Customer Agreement Docs</h5>
                </div>
                <div className="col-12 mt-1 mb-1">
                  <div className="row col-md-12 mb-2 mt-2 px-4">
                    {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerAgreement && dealerDetails.data.data.customerAgreement.length && dealerDetails.data.data.customerAgreement.length > 0 && (
                      <div className="col-md-4 col-lg-4 pr-3 mb-2">
                        <h6 className="document-content px-0">Customer Agreement</h6>
                        <div className="mb-2 mt-2">
                          {dealerDetails.data.data.customerAgreement && dealerDetails.data.data.customerAgreement.length > 0 && dealerDetails.data.data.customerAgreement.map((file: any, index: any) => (
                            file.documentId && (file.documentId.fileName || file.documentId.selectedFileName) && (
                              <div className="file-name-kam">
                                <IconButton size="small" className="" onClick={() => { window.open(file.documentId.url) }}>
                                  <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs cursor-pointer"
                                  onClick={() => {
                                    window.open(file.documentId.url)
                                  }}
                                >{file.documentId && file.documentId.selectedFileName ? file.documentId.selectedFileName : file.documentId && file.documentId.fileName}</span>
                              </div>
                            )
                          ))}
                        </div>
                      </div>
                    )}
                    {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.annexureToAgreement && dealerDetails.data.data.annexureToAgreement.length && dealerDetails.data.data.annexureToAgreement.length > 0 && (
                      <div className="col-md-4 col-lg-4 pr-3 mb-2">
                        <h6 className="document-content px-0">Annexure To Agreement</h6>
                        <div className="mb-2 mt-2">
                          {dealerDetails.data.data.annexureToAgreement && dealerDetails.data.data.annexureToAgreement.length > 0 && dealerDetails.data.data.annexureToAgreement.map((file: any, index: any) => (
                            file.documentId && (file.documentId.fileName || file.documentId.selectedFileName) && (
                              <div className="file-name-kam">
                                <IconButton size="small" className="" onClick={() => { window.open(file.documentId.url) }}>
                                  <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs cursor-pointer"
                                  onClick={() => {
                                    window.open(file.documentId.url)
                                  }}
                                >{file.documentId && file.documentId.selectedFileName ? file.documentId.selectedFileName : file.documentId && file.documentId.selectedFileName}</span>
                              </div>
                            )
                          ))}
                        </div>
                      </div>
                    )}
                    {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.categoryCertificateSent === true && (
                      <div className="col-md-4 col-lg-4 pr-3 mb-2">
                        <h6 className="document-content px-0">Category Certificate
                        </h6>
                        <small className="document-sub-content mb-2">(Silver/Gold/Platinum/Elite)</small>
                        <div className="mb-2 mt-2">
                          <div className="file-name-kam mt-2">
                            <IconButton size="small" className="" onClick={() => { downloadCertificate(); }}>
                              <AssignmentReturnedIcon className="download-icon mr-1" />
                            </IconButton>
                            <span className="cursor-pointer download-docs"
                              onClick={() => { downloadCertificate(); }}
                            >{"Certificate.Pdf"}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          : ""}
        <div className="card col-12 m-0 p-0">
          <div className="row mb-0 mt-0 px-2">
            <div className="col-12 mt-1 mb-1">
              <div className="d-flex align-items-center mt-3 mb-3">
                {/* <h5 className="info-sub-header-document px-4">Please click on the update the documents button to save the updated documents data</h5> */}
                <div className="row-space-start ml-auto">
                  <Button
                    variant="contained"
                    color="primary"
                    className={`blueActionButton border-left rounded-pill ml-4 mb-2`}
                    onClick={() => {
                      setIsEditOrgDocument(false);
                      setIsEditOrgOthersDocument(false);
                      saveDocumentDetails();
                    }}
                  >Update</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      {/* <TabPanel value={selectedTab} index="DocumentsTab">
        <OrgDocuments dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} userType={userType} />
      </TabPanel> */}
      <TabPanel value={selectedTab} index="Address">
        <Fragment>
          {dealerDetails && dealerDetails.loading && (
            <div className="mx-auto my-5">
              <FullscreenLoader />
            </div>
          )}
          <div className="row mt-3">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <div className="row-space-start ml-auto text-info">
                  (Contact KAM to add new address)
                  {/* <Button
                    className="text-info"
                    startIcon={<AddIcon className="font-size-18" />}
                    disabled
                    onClick={() => {
                      // handleClickOpen();
                    }}
                  ><span className="onshiftoparty-font">Add New Address
                    </span>
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card row m-0 mt-3">
            {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.addresses && dealerDetails.data.data.addresses.length && dealerDetails.data.data.addresses.map((addresses: any, index: any) => (
              <>
                <div className="col-12 m-0 pl-3 p-2 border-bottom">
                  <div className="row">
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="card-body pt-1" key={index}>
                        <div className="w-50 d-inline-block mt-2">
                          <div className="shipAddTitle">{addresses.dafault ? addresses.companyName : addresses.location} &nbsp; &nbsp;
                            {addresses.dafault ?
                              <Button
                                className="defaultAddressButton cursor-default"
                                size="small"
                                variant="contained"
                                color="secondary"
                              >Head Office</Button>
                              :
                              // featureCodeValue === true && featureCodeLabel === "Select Default address" && defaultAddressId === addresses.id ?
                              defaultAddressId === addresses.id && shipToPartyFeatureAccess ?
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="rounded-pill cursor-default"
                                >Default Address</Button>
                                : ""}
                            {/* {featureCodeValue === true && featureCodeLabel === "Select Default address" && addresses.dafault && defaultAddressId === addresses.id ?
                              <Button
                                variant="contained"
                                color="primary"
                                className="rounded-pill"
                              >Default Address</Button>
                              : ""} */}
                            {/* {!addresses.dafault && (
                              <Button
                                className="underKAMReviewAddressButton cursor-default"
                                size="small"
                                variant="contained"
                                color="secondary"
                              >Under KAM Review</Button>
                            )} */}
                          </div>
                          <div className="shipAdd mt-2">{addresses.streetName ? addresses.streetName + " - " : ""}  {addresses.city ? addresses.city + " - " : ""} {addresses.country}</div>
                          {customerTierType === "Workshop" ? <div className="shipAdd">Contact Telephone Number: {addresses.telephoneNumber}</div> : <div className="shipAdd">Telephone Office: {addresses.telephoneNumber}</div>}
                          <div className="shipAdd">PO Box: {addresses.poBox}</div>
                          <div className="shipAdd">Emirates/Region: {addresses.region} {getRegionName(addresses.region)}</div>
                          <div className="shipAdd">Ship To Party Customer ID:{" "}{addresses.shiftToPartyCustomerNumber ? addresses.shiftToPartyCustomerNumber : '-'}</div>
                          <div className="shipAdd">Transportation Zone:{" "}{addresses.transportationZone ? addresses.transportationZone : '-'}</div>
                          <div className="shipAdd" style={{ display: "flex" }}>
                            <div className="shipAdd" style={{ marginRight: '30px' }}>Shipping Label 1:{" "}{addresses && addresses.shippingLabels && addresses.shippingLabels[0] && addresses.shippingLabels[0].value !== "" ? addresses.shippingLabels[0].value : '-'}</div>
                            <div className="shipAdd">Shipping Label 2:{" "}{addresses && addresses.shippingLabels && addresses.shippingLabels[1] && addresses.shippingLabels[1].value !== "" ? addresses.shippingLabels[1].value : '-'}</div>
                          </div>
                          <div className="px-0 col-md-3 col-lg-3">
                          </div>
                        </div>
                        {shipToPartyFeatureAccess ? (
                          <>
                            {addresses.dafault && defaultAddressId === addresses.id ?
                              // {featureCodeValue === true && featureCodeLabel === "Select Default address" && addresses.dafault && defaultAddressId === addresses.id ?
                              <div className="markDefaultIcon w-50 d-inline-block text-right">
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="rounded-pill cursor-default"
                                  >Default Address</Button>
                                  {userType === "Admin" && blockIntent === true ?
                                    <Button
                                      className="text-info"
                                      startIcon={<EditOutlinedIcon />}
                                      onClick={() => {
                                        setFirstAddressCheck(addresses && addresses.firstAddress)
                                        onClickEditAddress(addresses);
                                        setIsEdited(true);
                                      }}><span className="onshiftoparty-font">View/Edit</span>
                                    </Button>
                                    : ''}
                                </>
                              </div>
                              : ""}
                            {featureCodeValue === true && featureCodeLabel === "Select Default address" ?
                              <>
                                {(
                                  <div className="row d-flex justify-content-end">
                                    <Button
                                      className="text-info"
                                      startIcon={defaultAddressId !== addresses.id ? <CheckCircleIcon className="valid-user-id" /> : ""}
                                      onClick={() => {
                                        setAddressID(addresses.id);
                                        setMarkAsDefaultAddress(addresses.id);
                                        dispatch(getSelectedShipToPartyAddress(sapCustomerId, addresses.id));
                                        // handleClickOpenAddress();
                                        setIsEdited(true);
                                        setSelected(addresses.id);
                                        addresses.showSapId = false;
                                      }}><span className="onshiftoparty-font">
                                        {defaultAddressId !== addresses.id ? 'Mark as Default' : ''}
                                      </span>
                                    </Button>
                                    {!addresses.dafault ?
                                      <>
                                        {userType === "Admin" && blockIntent === true ?
                                          <Button
                                            className="text-info"
                                            startIcon={<EditOutlinedIcon />}
                                            onClick={() => {
                                              setIsEditOwnerPartnerOrg(true);
                                              // setFirstAddressCheck(addresses && addresses.firstAddress)
                                              setIsEdited(true);
                                              onClickEditAddress(addresses)
                                            }}><span className="onshiftoparty-font">View/Edit</span>
                                          </Button> : ""}
                                      </> : ""}
                                  </div>
                                )}
                              </>
                              : ""}
                          </>
                        ) : (
                          <div className="markDefaultIcon w-50 d-inline-block text-right">
                            {userType === "Admin" && blockIntent === true ?
                              <Button
                                className="text-info"
                                startIcon={<EditOutlinedIcon />}
                                onClick={() => {
                                  setFirstAddressCheck(addresses && addresses.firstAddress)
                                  onClickEditAddress(addresses);
                                  setIsEdited(true);
                                }}><span className="onshiftoparty-font">View/Edit</span>
                              </Button>
                              : ''}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
            {customerTierType.toLowerCase() === "workshop" && <div className="p-3 pl-4 collectionPoint">
              <div className="shipAddTitle">
                Collection Points
              </div>
              {
                profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.collectionPointsOfOrder && profileDetails.data.data.customerInfo.collectionPointsOfOrder.collectionPoints && profileDetails.data.data.customerInfo.collectionPointsOfOrder.collectionPoints && profileDetails.data.data.customerInfo.collectionPointsOfOrder.collectionPoints.map((ele: any) => {
                  return (
                    <div>
                      <div className="shipAdd mt-2">{ele.defineTPCounter}</div>
                      <div className="shipAdd">Landline Number: {ele.landlinePhone}</div>
                      <div className="shipAdd mb-2">Region Name: {ele.regionName}</div>
                      <iframe id="iframeId" className="w-50" frameBorder="0" src={`https://maps.google.com/maps?q=${ele.gpsCoordinates.split(",")[0]},${ele.gpsCoordinates.split(",")[1]}&z=12&amp;&hl=es;&output=embed`}></iframe>
                      {ele && ele.workingTiming && ele.workingTiming.map((data: any, i: any) => {
                        return <div className="shipAdd mb-3 mt-2">Open At:
                          <p className="px-2 mt-2">{data && data.days ? data.days : "-"}</p>
                          <p className="px-2">{data && data.morningTime ? data.morningTime : "-"}</p>
                          <p className="px-2">{data && data.eveningTime ? data.eveningTime : "-"}</p>
                        </div>
                      })}
                      <div className="shipAdd mb-2 row">Close At: {" "}{ele && ele.closeAt ? ele.closeAt : "-"}</div>
                    </div>
                  )
                })
              }
            </div>}
            <Dialog
              open={open}
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  handleClose();
                }
              }}
              disableEscapeKeyDown
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth="md">

              <AddNewAddressDailog
                firstAddressCheck={firstAddressCheck} isDefaultAdd={isDefaultAdd}
                setOpenDialog={handleClose} setDataUpdatedQuotation={setDataUpdatedQuotation}
                selectedDeliveryType={selectedDeliveryType}
                getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={isEdited}
                regionData={regionData} sapId={sapId} masterCompanyBrands={masterCompanyBrands}
                // isEditDealer={editedAddress.dafault ? false : isEditDealer}
                isEditDealer={isEditDealer}
                isOutsideFreezone={isCustomerAcceptingDelivery}
                // isIntBuisness={customerOnboardingType === "Int. Business" ? true : false}
                isDefaultUAE={isDefaultUAE} salesRegion={salesRegion} />
            </Dialog>
          </div>
        </Fragment>
      </TabPanel>
      <TabPanel value={selectedTab} index="Users">
        <Fragment>
          {usersList && usersList.loading && (
            <div className="mx-auto my-5">
              <FullscreenLoader />
            </div>
          )}
          <div className="row">
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 mt-2">
              <div className="d-flex bd-highlight">
                <div className="flex-grow-1 bd-highlight">
                  <h5 className="page-sub-title mt-3">Users List</h5>
                </div>
                <div className="mt-2 mb-1 bd-highlight">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="dealer-search" className="rounded-button-dealer-search pl-2" margin="dense">Login ID / Internal ID</InputLabel>
                    <OutlinedInput
                      id="dealer-search"
                      value={searchValue}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          searchDealerList(searchValue, true);
                          setPage(0);
                        }
                      }}
                      onChange={(e: any) => {
                        setSearchValue(e.target.value);
                        // if (e.target.value === '') {
                        //   getUsers();
                        // }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Internal ID(for AFG use)"
                            edge="end"
                            onClick={(e: any) => {
                              // if (searchValue !== '') {
                              searchDealerList(e, true);
                              setPage(0);
                              // }
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={100} label="Internal ID(for AFG use)" margin="dense" className="rounded-button-dealer-search mr-2" />
                  </FormControl>
                </div>
                <Button variant="contained" className="cancel-button font-size-11 px-2 mt-2"
                  onClick={() => {
                    resetFiltersForCustomer();
                  }
                  }>Reset Filters</Button>
                <div className="mt-2 mb-1 bd-highlight">
                  {showManageUser && userType !== userTypes.user &&
                    <Button
                      variant="contained"
                      color="primary"
                      className="rounded-pill ml-3"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setSelectedUserId("");
                        setIsUserDialogForView(false);
                        setIsUserDialogForEdit(false);
                        handleopenCloseAddUser(true);
                      }}
                    >
                      Add User
                    </Button>
                  }
                </div>
              </div>
              {/* <Grid container>
                <Grid item xs={5}>
                  <h5 className="page-sub-title mt-2">Users List</h5>
                </Grid>
                <Grid item xs={7} >
                  <div className="row float-right">
                    <Grid item xs={7}>
                      <SearchBar callAPI={() => {
                        dispatch(getAllUsers(dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.sapCustomerId, 0, searchValue));
                        setPage(0);
                      }} value={searchValue} onChange={(e: any) => {
                        setSearchValue(e.target.value);
                        if (e.target.value === '') {
                          getUsers();
                        }
                      }} label="Internal ID (for AFG use)" className="mr-2" />
                    </Grid>
                    {showManageUser && userType !== userTypes.user &&
                      <Grid item xs={5}>
                        <Button
                          variant="contained"
                          color="primary"
                          className="rounded-pill ml-5"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            setSelectedUserId("");
                            setIsUserDialogForView(false);
                            setIsUserDialogForEdit(false);
                            handleopenCloseAddUser(true);
                          }}
                        >
                          Add User
                        </Button>
                      </Grid>
                    }
                  </div>
                </Grid>
              </Grid>
               */}
              <div className="cardCommon mh-300p mt-2">
                <TableContainer className={`classes.container`}>
                  <Table aria-label="table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column: any) => (
                          <TableCell
                            key={column.id}
                            className={classes.header}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell className={classes.header}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersList && usersList.data && usersList.data.data && usersList.data.data.userList && usersList.data.data.userList.length > 0 && usersList.data.data.userList.map((user: any, index: any) => (
                        <TableRow key={index}>
                          <TableCell className={classes.body}>{user.firstName}{' '}{user.lastName}</TableCell>
                          <TableCell className={classes.body}>{user.webApplicationLoginId}</TableCell>
                          <TableCell className={classes.body}>{user.b2cUserId}</TableCell>
                          <TableCell className={classes.body}>{user.userType}</TableCell>
                          <TableCell className={classes.body}>{user.email}</TableCell>
                          <TableCell className={classes.body}>{user.firstTelephoneNumber}</TableCell>
                          <TableCell className={classes.body}>
                            {user && user.salesOrganization && user.salesOrganization.length > 0 && user.salesOrganization.map((org: any, index: any) => (
                              <>
                                {org.isActive !== false ? <React.Fragment key={index}>{org.isActive === false ? '' : getBrandName(org.make, customerTierType)}{user.salesOrganization.length === index + 1 ? '' : ', '}</React.Fragment>
                                  : ''}
                              </>
                            ))}
                          </TableCell>
                          <TableCell className={classes.body}>{user.status}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setSelectedUserId(user.b2bUserId);
                                setIsUserDialogForEdit(true);
                                setIsUserDialogForView(true);
                                if (user.status.toLowerCase() === "in progress") {
                                  setInProgress(true);
                                }
                                else {
                                  setInProgress(false);
                                }
                                dispatch(getUserDetails(user.b2bUserId));
                                dispatch(setUserDetails(user));
                                handleopenCloseAddUser(true);
                              }}
                              className={`mr-2 ${classes.actions}`} size="small">
                              <Visibility fontSize="inherit" />
                              <span className="ml-1">View</span>
                            </IconButton>
                            {showManageUser && userType !== userTypes.user &&
                              <IconButton
                                onClick={() => {
                                  setSelectedUserId(user.b2bUserId);
                                  setIsUserDialogForEdit(true);
                                  setIsUserDialogForView(false)
                                  if (user.status.toLowerCase() === "in progress") {
                                    setInProgress(true);
                                  }
                                  else {
                                    setInProgress(false);
                                  }
                                  dispatch(getUserDetails(user.b2bUserId));
                                  dispatch(setUserDetails(user));
                                  handleopenCloseAddUser(true);
                                }}
                                className={classes.actions} size="small">
                                <EditIcon fontSize="inherit" />
                                <span className="ml-1">Edit</span>
                              </IconButton>}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="col-12 d-flex justify-content-end">
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={usersList && usersList.data && usersList.data.data && usersList.data.data.totaCount ? usersList.data.data.totaCount : 0}
                    rowsPerPage={10}
                    page={page}
                    onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </TabPanel>
      <TabPanel value={selectedTab} index="sellingPrice">
        {dealerDetails && dealerDetails.loading && (
          <div className="mx-auto my-5">
            <FullscreenLoader />
          </div>
        )}
        <SellingPrice dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} />
      </TabPanel>
      <DeleteDialog
        open={confirmDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any, newValue: any) => {
          if (data === 'delete' || data === "back") {
            setDataUpdated(false); setselectedTab(tabValue); handleopenClose(false);
          }
          if (!data) { handleopenClose(false); }
        }} />
      <Prompt when={dataUpdated} message={"Do you wish to proceed without saving the data?"}>
      </Prompt>
    </Fragment >
  );
};

export default OrgSetting;

