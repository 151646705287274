/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import { Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { Tab, Tabs, Box } from "@material-ui/core";
import { withStyles, Theme, createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SetTargetDialog from "./SetTargetDialog";
import { getDealerListSetTargetData, updateSetTargetCustomerData } from "../../store/actions/superAdmin.actions";
import { superAdminServices } from "../../services/superAdmin.service";
import { get } from 'lodash';
import { getYears } from "../../helpers/commonmethod";
import Alert from "@material-ui/lab/Alert";
import DeleteDialog from "../../common/components/deleteDialog";
import { getNewBrand } from '../../common/components/util';
import { getNewlyAddedBrand } from "../../common/components/util";

const useStyles = makeStyles(() => ({
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  addUpdateDailogTitle: {
    position: "absolute",
    top: 10,
    left: 10
  },
}));

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SetTargetDealerList: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened);
  const [value, setValue] = React.useState(0);
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
  const { getDealerListSetTargetDetails, updateCustomerTargetData }: any = useSelector((state: any) => state.superAdmin);
  const currencies = ["AED"];
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId') || '';
  const [selectYear, setSelectYear] = React.useState<any>(new Date().getFullYear());
  const [selectedTab, setselectedTab] = React.useState(0);
  const [selectedTabData, setSelectedTabData] = React.useState<any>(null);
  const [brandId, setBrandId] = React.useState<any>(null);
  const [defaultTargetData, setDefaultTargetData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [dataUpdated, setDataUpdated] = React.useState<any>(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [showApproveDialog, setshowApproveDialog] = React.useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const years = getYears();
  const [disableMonths, setDisableMonth] = React.useState<any>({});
  const [labelDefaultValues, setLabelDefaultValues] = React.useState<any>({});
  const [dataUpdatedPrompt, setDataUpdatedPrompt] = React.useState<boolean>(false);
  const [resetToDefault, setResetToDefault] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);
  const [disabledValue, setDisabledValue] = React.useState<any>({
    monthlyTaget: {
      Jan: { amount: 0 },
      Feb: { amount: 0 },
      Mar: { amount: 0 },
      Apr: { amount: 0 },
      May: { amount: 0 },
      Jun: { amount: 0 },
      Jul: { amount: 0 },
      Aug: { amount: 0 },
      Sep: { amount: 0 },
      Oct: { amount: 0 },
      Nov: { amount: 0 },
      Dec: { amount: 0 }
    }
  });
  const [newValue, setNewValue] = React.useState<any>();
  const [confirmDialog, setConfirmDialog] = React.useState(false);

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const orgIdBrandsMapping: any = {
    "2001": "Toyota",
    "2002": "Honda",
    "2003": "Famco",
    "2380": "TE",
    "2195": "ALAC"
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  useEffect(() => {
    if (getDealerListSetTargetDetails && getDealerListSetTargetDetails.data && getDealerListSetTargetDetails.data.data && getDealerListSetTargetDetails.data.data?.targetData?.brandTargets?.length > 0) {
      setSelectedTabData(JSON.parse(JSON.stringify({ ...getDealerListSetTargetDetails.data.data.targetData.brandTargets[0] })));
      setBrandId(getDealerListSetTargetDetails.data.data.targetData.brandTargets[0].brandId);
    }
  }, [getDealerListSetTargetDetails])

  useEffect(() => {
    if (getDealerListSetTargetDetails && getDealerListSetTargetDetails.data && getDealerListSetTargetDetails.data.data && getDealerListSetTargetDetails.data.data?.defaultTargets?.targetsByOrgId?.length > 0 && selectedTabData && selectedTabData?.brandId) {
      let data: any;
      data = getDealerListSetTargetDetails?.data?.data?.defaultTargets?.targetsByOrgId.filter((dt: any) => parseInt(dt?.orgID) === parseInt(selectedTabData?.brandId))
      if (data && data.length > 0) {
        setDefaultTargetData(data[0])
      }
    }
  }, [selectedTabData, getDealerListSetTargetDetails])


  // DISABLED PREVIOUS MONTHS FUNCTIONATILTY
  useEffect(() => {
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const currentMonth = new Date();
    const name = currentMonth.getMonth()
    const currentYear = new Date().getUTCFullYear();
    const filterMonth = month.filter((ele: any, i: any) => {
      return i < name
    })
    const disableMonth = { jan: false, feb: false, mar: false, apr: false, may: false, jun: false, jul: false, aug: false, oct: false, sep: false, nov: false, dec: false }
    if (filterMonth.includes(month[0])) {
      disableMonth.jan = true
    }
    if (filterMonth.includes(month[1])) {
      disableMonth.feb = true
    } if (filterMonth.includes(month[2])) {
      disableMonth.mar = true
    } if (filterMonth.includes(month[3])) {
      disableMonth.apr = true
    } if (filterMonth.includes(month[4])) {
      disableMonth.may = true
    } if (filterMonth.includes(month[5])) {
      disableMonth.jun = true
    } if (filterMonth.includes(month[6])) {
      disableMonth.jul = true
    } if (filterMonth.includes(month[7])) {
      disableMonth.aug = true
    } if (filterMonth.includes(month[8])) {
      disableMonth.sep = true
    } if (filterMonth.includes(month[9])) {
      disableMonth.oct = true
    } if (filterMonth.includes(month[10])) {
      disableMonth.nov = true
    } if (filterMonth.includes(month[11])) {
      disableMonth.dec = true
    }
    if (selectYear === currentYear) {
      setDisableMonth(disableMonth)
      // } else if (selectYear <= currentYear) {
      //   setDisableMonth({ jan: true, feb: true, mar: true, apr: true, may: true, jun: true, jul: true, aug: true, oct: true, sep: true, nov: true, dec: true })
    } else if (selectYear >= currentYear) {
      setDisableMonth({ jan: false, feb: false, mar: false, apr: false, may: false, jun: false, jul: false, aug: false, oct: false, sep: false, nov: false, dec: false })
    }
  }, [selectYear])

  // FOR POST API CALL - TO SAVE & UPADTE SET TARGET DATA - ON CLICK OF SAVE & UPDATE
  const setTargetApiCall = async () => {
    let value = getDealerListSetTargetDetails.data.data.targetData.brandTargets;
    value[selectedTab] = selectedTabData;
    let data = {
      "year": String(selectYear),
      "updatedBy": webApplicationLoginId,
      "targetData": {
        "year": String(selectYear),
        "brandTargets": value
        //  [{
        //   brandId: brandId,
        //   monthlyTaget: value && value.length > 0 && value[0].monthlyTaget,
        //   quarterlyTaget: value && value.length > 0 && value[0].quarterlyTaget,
        //   yearlyTaget: value && value.length > 0 && value[0].yearlyTaget
        // }
        // ]
      }
    }
    setLoading(true);
    const responseData = await superAdminServices.updateTargetDataCustomer(data, getDealerListSetTargetDetails.data.data.sapCustomerId);
    if (responseData && responseData.message && responseData.message.status == "S") {
      setLoading(false);
      setDataUpdated(false);
      setOpenErrorSnackbar(false);
      setOpenSnackbar(true);
      setSuccessMessage(responseData.message.message);
      setTimeout(() => {
        history.push('/dealerstarget');
      }, 2000);
      setshowApproveDialog(false);
    } else {
      setLoading(false);
      handleopenCloseErrorSnackbar(true);
      showErrorMessage(responseData.error ? responseData.error : "Something went Wrong");
      setshowApproveDialog(false);
    }
  }

  // FOR QUARTER 1 - TOTAL 
  const handleInputChange = () => {
    debugger
    let jan = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.Jan?.amount || 0).toFixed(2));
    let feb = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.Feb?.amount || 0).toFixed(2));
    let mar = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.Mar?.amount || 0).toFixed(2));
    let Q1: any = jan + feb + mar;
    Q1 = parseFloat(Q1).toFixed(2)
    let initialValue = selectedTabData
    initialValue.quarterlyTaget.quarter1.amount = Q1
    setSelectedTabData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR QUARTER 2 TARGET- TOTAL 
  const handleInputChangeQ2 = () => {
    let apr = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.Apr?.amount || 0).toFixed(2));
    let may = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.May?.amount || 0).toFixed(2));
    let june = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.Jun?.amount || 0).toFixed(2));
    let Q2: any = apr + may + june;
    Q2 = parseFloat(Q2).toFixed(2)
    let initialValue = selectedTabData
    initialValue.quarterlyTaget.quarter2.amount = Q2
    setSelectedTabData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR QUARTER 3 TARGET- TOTAL 
  const handleInputChangeQ3 = () => {
    let jul = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.Jul?.amount || 0).toFixed(2));
    let aug = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.Aug?.amount || 0).toFixed(2));
    let sept = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.Sept?.amount || 0).toFixed(2));
    let Q3: any = jul + aug + sept
    Q3 = parseFloat(Q3).toFixed(2)
    let initialValue = selectedTabData
    initialValue.quarterlyTaget.quarter3.amount = Q3
    setSelectedTabData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR QUARTER 4 TARGET - TOTAL 
  const handleInputChangeQ4 = () => {
    let oct = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.Oct?.amount || 0).toFixed(2));
    let nov = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.Nov?.amount || 0).toFixed(2));
    let dec = parseFloat(parseFloat(selectedTabData?.monthlyTaget?.Dec?.amount || 0).toFixed(2));
    let Q4: any = oct + nov + dec
    Q4 = parseFloat(Q4).toFixed(2)
    let initialValue = selectedTabData
    initialValue.quarterlyTaget.quarter4.amount = Q4
    setSelectedTabData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR YEARLY TARGET - TOTAL 
  const handleInputChangeYaerlyTarget = () => {
    let Q1 = parseFloat(parseFloat(selectedTabData?.quarterlyTaget?.quarter1?.amount || 0).toFixed(2));
    let Q2 = parseFloat(parseFloat(selectedTabData?.quarterlyTaget?.quarter2?.amount || 0).toFixed(2));
    let Q3 = parseFloat(parseFloat(selectedTabData?.quarterlyTaget?.quarter3?.amount || 0).toFixed(2));
    let Q4 = parseFloat(parseFloat(selectedTabData?.quarterlyTaget?.quarter4?.amount || 0).toFixed(2));
    let YearlyTargetTotal: any = Q1 + Q2 + Q3 + Q4;
    YearlyTargetTotal = parseFloat(YearlyTargetTotal).toFixed(2)
    let initialValue = selectedTabData
    initialValue.yearlyTaget.amount = YearlyTargetTotal
    setSelectedTabData({ ...initialValue });
  }

  const classes = useStyles();

  return (
    <Fragment>
      {loading && <FullscreenLoader />}
      {getDealerListSetTargetDetails && getDealerListSetTargetDetails.loading && <FullscreenLoader />}
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      <div className="d-flex bd-highlight">
        <div className="p-2 bd-highlight page-title">Set Target</div>
        {getDealerListSetTargetDetails?.data?.data?.companyName && (
          <div className="p-2 bd-highlight page-sub-title-set-target mt-2">({getDealerListSetTargetDetails?.data?.data?.companyName})</div>
        )}
        <div className="ml-auto p-2 bd-highlight"></div>
      </div>
      <div className="d-flex bd-highlight">
        <div className="p-2 flex-grow-1 bd-highlight mt-3">Set target for your all brands.</div>
        {getDealerListSetTargetDetails?.data?.data?.isDefaultTargetSet && (
          <>
            <p className="mt-3">Category :</p><h6 className="p-2 bd-highlight setTarget-quarter-target mt-2">{getDealerListSetTargetDetails?.data?.data?.customerTierInfo?.customerAccountTier}</h6>
          </>
        )}
        <div className="p-2 bd-highlight">
          <TextField select
            id="selectYear"
            label="Select Year"
            value={selectYear}
            variant="outlined"
            className="rounded-input-dealer mw-200p mr-2 mb-2"
            size="small"
            onChange={(e: any) => {
              setselectedTab(0);
              setSelectYear(e.target.value);
              dispatch(getDealerListSetTargetData(sapCustomerId, e.target.value, webApplicationLoginId));
            }}
          >
            {years.map((data: any, index: any) => (
              <MenuItem key={index} value={data}> {data} </MenuItem>
            ))}
          </TextField>
          <Button variant="contained" className={`resetToDefaultActionButton border-left rounded-pill mr-3`}
            onClick={() => { history.push('/superadmindealers'); }}
          >Back To Customer List</Button>
        </div>
      </div>
      <div className="row">
        {getDealerListSetTargetDetails?.data?.error ? (
          <div className="col-12">
            <Alert severity="error">{getDealerListSetTargetDetails?.data?.error}</Alert>
          </div>
        ) : <>
          {(getDealerListSetTargetDetails?.data?.data?.isDefaultTargetSet) ? (
            <div className="mt-3 mb-3 pb-5 w-100">
              <ThemeProvider theme={theme}>
                <Paper square>
                  <Tabs
                    className="tabSelect border-bottom border-top-0"
                    value={selectedTab}
                    textColor="primary"
                    indicatorColor="primary"
                    onChange={(event, newValue) => {
                      if (!dataUpdated) {
                        setselectedTab(newValue);
                        if (getDealerListSetTargetDetails && getDealerListSetTargetDetails.data &&
                          getDealerListSetTargetDetails.data.data &&
                          getDealerListSetTargetDetails.data.data.targetData.brandTargets.length > 0) {
                          setSelectedTabData(JSON.parse(JSON.stringify({ ...getDealerListSetTargetDetails.data.data.targetData.brandTargets[newValue] })));
                          setBrandId(getDealerListSetTargetDetails.data.data.targetData.brandTargets[newValue].brandId);
                          // setDefaultTargetData(getDealerListSetTargetDetails.data.data.defaultTargets[newValue]);
                        }
                      } else {
                        setNewValue(newValue);
                        handleopenClose(true);
                      }
                    }}
                    //   setselectedTab(newValue);
                    //   if (getDealerListSetTargetDetails && getDealerListSetTargetDetails.data &&
                    //     getDealerListSetTargetDetails.data.data &&
                    //     getDealerListSetTargetDetails.data.data.targetData.brandTargets.length > 0) {
                    //     setSelectedTabData(getDealerListSetTargetDetails.data.data.targetData.brandTargets[newValue]);
                    //     setBrandId(getDealerListSetTargetDetails.data.data.targetData.brandTargets[newValue].brandId);
                    //     setDefaultTargetData(getDealerListSetTargetDetails.data.data.defaultTargets[newValue]);
                    //   }
                    // }}
                    aria-label="basic tabs example">
                    {getDealerListSetTargetDetails && getDealerListSetTargetDetails.data &&
                      getDealerListSetTargetDetails.data.data && getDealerListSetTargetDetails.data.data.targetData.brandTargets.length > 0 &&
                      getDealerListSetTargetDetails.data.data.targetData.brandTargets.map((brand: any, item: any, index: any) => {
                        return <Tab label={getNewBrand(orgIdBrandsMapping[brand.brandId])} {...a11yProps(index)} />
                      })}
                  </Tabs>
                  {selectedTabData && (
                    <TabPanel value={selectedTab} index={selectedTab}>
                      <div className="row p-2 m-0">
                        <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                          <div className="col-12 d-flex justify-content-between">
                            <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.Jan?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="january"
                              type={selectedTabData?.monthlyTaget?.Jan?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.jan ? disabledValue?.monthlyTaget.Jan?.amount : selectedTabData?.monthlyTaget?.Jan?.amount}
                              value={selectedTabData?.monthlyTaget?.Jan?.amount}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.Jan.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                handleInputChange();
                                setDataUpdated(true);
                                setDataUpdatedPrompt(true);
                              }}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="January" variant="outlined" margin="dense"
                            // disabled={disableMonths.jan}
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData && defaultTargetData?.monthlyTaget ? defaultTargetData && defaultTargetData?.monthlyTaget?.Jan?.amount === null ? 0 : defaultTargetData && defaultTargetData?.monthlyTaget?.Jan?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.Feb?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField
                              id="february"
                              type={selectedTabData?.monthlyTaget?.Feb?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.feb ? disabledValue?.monthlyTaget?.Feb?.amount : selectedTabData?.monthlyTaget?.Feb?.amount}
                              value={selectedTabData?.monthlyTaget?.Feb?.amount}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.Feb.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                handleInputChange();
                                setDataUpdated(true);
                                setDataUpdatedPrompt(true);
                              }}
                              label="February" variant="outlined" margin="dense"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                            // disabled={disableMonths.feb}
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget)
                              && (
                                <div className="col-12 d-flex justify-content-between">
                                  <div className="col-12 px-0">
                                    <h6 className="info-sub-title-value-default mt-2">
                                      Default - AED {(defaultTargetData && defaultTargetData?.monthlyTaget) ? defaultTargetData?.monthlyTaget?.Feb?.amount === null ? 0 : defaultTargetData?.monthlyTaget?.Feb?.amount : '-'
                                      }</h6></div>
                                </div>
                              )}
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.Mar?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="march"
                              type={selectedTabData?.monthlyTaget?.Mar?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.mar ? disabledValue?.monthlyTaget.Mar?.amount : selectedTabData?.monthlyTaget?.Mar?.amount}
                              value={selectedTabData?.monthlyTaget?.Mar?.amount}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.Mar.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                handleInputChange();
                                setDataUpdated(true);
                                setDataUpdatedPrompt(true);
                              }}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="March" variant="outlined" margin="dense"
                            //  disabled={disableMonths.mar} 
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget)
                              && (
                                <div className="col-12 d-flex justify-content-between">
                                  <div className="col-12 px-0">
                                    <h6 className="info-sub-title-value-default mt-2">
                                      Default - AED {defaultTargetData && defaultTargetData?.monthlyTaget ? defaultTargetData && defaultTargetData?.monthlyTaget?.Mar?.amount === null ? 0 : defaultTargetData?.monthlyTaget?.Mar?.amount : '-'
                                      }</h6></div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                          <div className="col-12 d-flex justify-content-between">
                            <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 1 Target</h6></div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 pr-0">
                            <TextField
                              id="quarterCurrencies"
                              value={selectedTabData?.quarterlyTaget?.quarter1?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="quarterOne"
                              type={selectedTabData?.quarterlyTaget?.quarter1?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.mar ? disabledValue?.quarterlyTaget?.quarter1?.amount : selectedTabData?.quarterlyTaget?.quarter1?.amount}
                              value={selectedTabData?.quarterlyTaget?.quarter1?.amount}
                              // disabled={disableMonths.mar}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.quarterlyTaget.quarter1.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                handleInputChangeYaerlyTarget();
                                setDataUpdatedPrompt(true);
                                setDataUpdated(true);
                              }}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="Quarter 1" variant="outlined" margin="dense" />
                            {(defaultTargetData && defaultTargetData?.quarterlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData?.quarterlyTaget ? defaultTargetData?.quarterlyTaget?.quarter1?.amount === null ? 0 : defaultTargetData?.quarterlyTaget?.quarter1?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                          <div className="col-12 d-flex justify-content-between">
                            <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.Apr?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="april"
                              type={selectedTabData?.monthlyTaget?.Apr?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.apr ? disabledValue?.monthlyTaget.Apr?.amount : selectedTabData?.monthlyTaget?.Apr?.amount}
                              value={selectedTabData?.monthlyTaget?.Apr?.amount}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.Apr.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdated(true)
                                setDataUpdatedPrompt(true)
                                handleInputChangeQ2();
                              }}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="April" variant="outlined" margin="dense"
                            // disabled={disableMonths.apr} 
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData?.monthlyTaget ? defaultTargetData?.monthlyTaget?.Apr?.amount === null ? 0 : defaultTargetData?.monthlyTaget?.Apr?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.May?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="may"
                              type={selectedTabData?.monthlyTaget?.May?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.may ? disabledValue?.monthlyTaget.May?.amount : selectedTabData?.monthlyTaget?.May?.amount}
                              value={selectedTabData?.monthlyTaget?.May?.amount}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.May.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdated(true)
                                setDataUpdatedPrompt(true)
                                handleInputChangeQ2();
                              }}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="May" variant="outlined" margin="dense"
                            // disabled={disableMonths.may} 
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {(defaultTargetData && defaultTargetData?.monthlyTaget) ? defaultTargetData?.monthlyTaget?.May?.amount === null ? 0 : defaultTargetData?.monthlyTaget?.May?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.Jun?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="june"
                              type={selectedTabData?.monthlyTaget?.Jun?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.jun ? disabledValue?.monthlyTaget.Jun?.amount : selectedTabData?.monthlyTaget?.Jun?.amount}
                              value={selectedTabData?.monthlyTaget?.Jun?.amount}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.Jun.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdated(true)
                                setDataUpdatedPrompt(true)
                                handleInputChangeQ2();
                              }}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="June" variant="outlined" margin="dense"
                            // disabled={disableMonths.jun} 
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData && defaultTargetData?.monthlyTaget ? defaultTargetData && defaultTargetData?.monthlyTaget?.Jun?.amount === null ? 0 : defaultTargetData?.monthlyTaget?.Jun?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                          <div className="col-12 d-flex justify-content-between">
                            <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 2 Target</h6></div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                            <TextField
                              id="quarterCurrencies"
                              value={selectedTabData?.quarterlyTaget?.quarter2?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="quarterTwo"
                              type={selectedTabData?.quarterlyTaget?.quarter2?.amount > 0 ? "text" : "number"}
                              value={selectedTabData?.quarterlyTaget?.quarter2?.amount}
                              // value={ resetToDefault && disableMonths.jun ? 0 : selectedTabData?.quarterlyTaget?.quarter2?.amount}
                              // disabled={disableMonths.jun}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.quarterlyTaget.quarter2.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdatedPrompt(true)
                                setDataUpdated(true)
                                handleInputChangeYaerlyTarget();
                              }}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="Quarter 2" variant="outlined" margin="dense" />
                            {(defaultTargetData && defaultTargetData?.quarterlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData && defaultTargetData?.quarterlyTaget ? defaultTargetData?.quarterlyTaget?.quarter2?.amount === null ? 0 : defaultTargetData?.quarterlyTaget?.quarter2?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                          <div className="col-12 d-flex justify-content-between">
                            <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.Jul?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="july"
                              type={selectedTabData?.monthlyTaget?.Jul?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.jul ? disabledValue?.monthlyTaget.Jul?.amount : selectedTabData?.monthlyTaget?.Jul?.amount}
                              value={selectedTabData?.monthlyTaget?.Jul?.amount}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.Jul.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdated(true)
                                setDataUpdatedPrompt(true)
                                handleInputChangeQ3();
                              }}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="July" variant="outlined" margin="dense"
                            // disabled={disableMonths.jul} 
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData?.monthlyTaget ? defaultTargetData?.monthlyTaget?.Jul?.amount === null ? 0 : defaultTargetData?.monthlyTaget?.Jul?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.Aug?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="august"
                              type={selectedTabData?.monthlyTaget?.Aug?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.aug ? disabledValue?.monthlyTaget.Aug?.amount : selectedTabData?.monthlyTaget?.Aug?.amount}
                              value={selectedTabData?.monthlyTaget?.Aug?.amount}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.Aug.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdated(true)
                                setDataUpdatedPrompt(true)
                                handleInputChangeQ3();
                              }}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="August" variant="outlined" margin="dense"
                            // disabled={disableMonths.aug}
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData?.monthlyTaget ? defaultTargetData?.monthlyTaget?.Aug?.amount === null ? 0 : defaultTargetData?.monthlyTaget?.Aug?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.Sept?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="september"
                              type={selectedTabData?.monthlyTaget?.Sept?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.sep ? disabledValue?.monthlyTaget.Sept?.amount : selectedTabData?.monthlyTaget?.Sept?.amount}
                              value={selectedTabData?.monthlyTaget?.Sept?.amount}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.Sept.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdated(true)
                                setDataUpdatedPrompt(true)
                                handleInputChangeQ3();
                              }}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="September" variant="outlined" margin="dense"
                            // disabled={disableMonths.sep} 
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData?.monthlyTaget ? defaultTargetData?.monthlyTaget?.Sept?.amount === null ? 0 : defaultTargetData?.monthlyTaget?.Sept?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                          <div className="col-12 d-flex justify-content-between">
                            <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 3 Target</h6></div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                            <TextField
                              id="quarterCurrencies"
                              value={selectedTabData?.quarterlyTaget?.quarter3?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="quarterThird"
                              type={selectedTabData?.quarterlyTaget?.quarter3?.amount > 0 ? "text" : "number"}
                              // disabled={disableMonths.sep}
                              // value={resetToDefault && disableMonths.sep ? disabledValue?.quarterlyTaget?.quarter3?.amount : selectedTabData?.quarterlyTaget?.quarter3?.amount}
                              value={selectedTabData?.quarterlyTaget?.quarter3?.amount}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.quarterlyTaget.quarter3.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdatedPrompt(true)
                                setDataUpdated(true)
                                handleInputChangeYaerlyTarget()
                              }}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="Quarter 3" variant="outlined" margin="dense" />
                            {(defaultTargetData && defaultTargetData?.quarterlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData?.quarterlyTaget ? defaultTargetData?.quarterlyTaget?.quarter3?.amount === null ? 0 : defaultTargetData?.quarterlyTaget?.quarter3?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                          <div className="col-12 d-flex justify-content-between">
                            <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.Oct?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="october"
                              type={selectedTabData?.monthlyTaget?.Oct?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.oct ? disabledValue?.monthlyTaget.Oct?.amount : selectedTabData?.monthlyTaget?.Oct?.amount}
                              value={selectedTabData?.monthlyTaget?.Oct?.amount}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.Oct.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdated(true);
                                setDataUpdatedPrompt(true);
                                handleInputChangeQ4();
                              }}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="October" variant="outlined" margin="dense"
                            // disabled={disableMonths.oct} 
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData?.monthlyTaget ? defaultTargetData?.monthlyTaget?.Oct?.amount === null ? 0 : defaultTargetData?.monthlyTaget?.Oct?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.Nov?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="november"
                              type={selectedTabData?.monthlyTaget?.Nov?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.nov ? disabledValue?.monthlyTaget.Nov?.amount : selectedTabData?.monthlyTaget?.Nov?.amount}
                              value={selectedTabData?.monthlyTaget?.Nov?.amount}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.Nov.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdated(true)
                                setDataUpdatedPrompt(true)
                                handleInputChangeQ4();
                              }}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="November" variant="outlined" margin="dense"
                            // disabled={disableMonths.nov}  
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData && defaultTargetData?.monthlyTaget ? defaultTargetData?.monthlyTaget?.Nov?.amount === null ? 0 : defaultTargetData?.monthlyTaget?.Nov?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                            <TextField id="currenciesValues"
                              value={selectedTabData?.monthlyTaget?.Dec?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="december"
                              type={selectedTabData?.monthlyTaget?.Dec?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.dec ? disabledValue?.monthlyTaget.Dec?.amount : selectedTabData?.monthlyTaget?.Dec?.amount}
                              value={selectedTabData?.monthlyTaget?.Dec?.amount}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.monthlyTaget.Dec.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdated(true)
                                setDataUpdatedPrompt(true)
                                handleInputChangeQ4();
                              }}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="December" variant="outlined" margin="dense"
                            // disabled={disableMonths.dec}
                            />
                            {(defaultTargetData && defaultTargetData?.monthlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData?.monthlyTaget ? defaultTargetData?.monthlyTaget?.Dec?.amount === null ? 0 : defaultTargetData?.monthlyTaget?.Dec?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                          <div className="col-12 d-flex justify-content-between">
                            <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 4 Target</h6></div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                            <TextField
                              id="quarterCurrencies"
                              // value={selectedTabData?.quarterlyTaget?.quarter4?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="quarterFour"
                              type={selectedTabData?.quarterlyTaget?.quarter4?.amount > 0 ? "text" : "number"}
                              // value={resetToDefault && disableMonths.dec ? disabledValue?.quarterlyTaget?.quarter4?.amount : selectedTabData?.quarterlyTaget?.quarter4?.amount}
                              value={selectedTabData?.quarterlyTaget?.quarter4?.amount}
                              // disabled={disableMonths.dec}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.quarterlyTaget.quarter4.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdatedPrompt(true)
                                setDataUpdated(true)
                                handleInputChangeYaerlyTarget();
                              }}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="Quarter 4" variant="outlined" margin="dense" />
                            {(defaultTargetData && defaultTargetData?.quarterlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData && defaultTargetData?.quarterlyTaget ? defaultTargetData?.quarterlyTaget?.quarter4?.amount === null ? 0 : defaultTargetData?.quarterlyTaget?.quarter4?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3">
                          <div className="col-12 d-flex justify-content-between"></div>
                          <div className="col-sm-12 col-md-4 col-lg-4 mb-2 pr-0"></div>
                          <div className="col-sm-8 col-md-8 col-lg-8 mb-2 pr-3"> </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                          <div className="col-12 d-flex justify-content-between">
                            <div className="col-12"><h6 className="setTarget-yearly-target mb-2">Yearly Target</h6></div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                            <TextField
                              id="quarterCurrencies"
                              value={selectedTabData?.yearlyTaget?.currencyType}
                              disabled
                              defaultValue={currencies[0]}
                              size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                            <TextField id="yearlyTarget"
                              type={selectedTabData?.yearlyTaget?.amount > 0 ? "text" : "number"}
                              value={selectedTabData?.yearlyTaget?.amount}
                              // disabled={disableMonths.dec}
                              onChange={(e: any) => {
                                let updateValue = parseInt(e.target.value || 0);
                                let initialValue = selectedTabData
                                initialValue.yearlyTaget.amount = updateValue
                                setSelectedTabData({ ...initialValue });
                                setDataUpdatedPrompt(true)
                                setDataUpdated(true)
                              }}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              size="small" className="col-sm-9 commonRoundedInputs-textfield"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              label="Yearly" variant="outlined" margin="dense" />
                            {(defaultTargetData && defaultTargetData?.yearlyTaget) && (
                              <div className="col-12 d-flex justify-content-between">
                                <div className="col-12 px-0">
                                  <h6 className="info-sub-title-value-default mt-2">
                                    Default - AED {defaultTargetData?.yearlyTaget ? defaultTargetData?.yearlyTaget?.amount === null ? 0 : defaultTargetData?.yearlyTaget?.amount : '-'}</h6></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  )}
                  <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                    <div className="col-12 d-flex mt-2 mb-2 p-0">
                      <div className="col-6 px-4">
                        <Button variant="contained" onClick={() => {
                          let data: any;
                          let defaultTargets: any;
                          if (getDealerListSetTargetDetails?.data?.data?.defaultTargets?.targetsByOrgId && getDealerListSetTargetDetails?.data?.data?.defaultTargets?.targetsByOrgId.length > 0) {
                            data = getDealerListSetTargetDetails?.data?.data?.defaultTargets?.targetsByOrgId.filter((dt: any) => parseInt(dt?.orgID) === parseInt(selectedTabData?.brandId))
                          }
                          if (data && data.length > 0) {
                            defaultTargets = data[0];
                          }
                          let updateValueYearly = defaultTargets?.yearlyTaget ? defaultTargets?.yearlyTaget?.amount === null ? 0 : defaultTargets?.yearlyTaget?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.yearlyTaget?.amount
                          let updateValueJan = defaultTargets?.monthlyTaget?.Jan ? defaultTargets?.monthlyTaget?.Jan?.amount === null ? 0 : defaultTargets?.monthlyTaget?.Jan?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.Jan?.amount
                          let updateValueFeb = defaultTargets?.monthlyTaget?.Feb ? defaultTargets?.monthlyTaget?.Feb?.amount === null ? 0 : defaultTargets?.monthlyTaget?.Feb?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.Feb?.amount
                          let updateValueMar = defaultTargets?.monthlyTaget?.Mar ? defaultTargets?.monthlyTaget?.Mar?.amount === null ? 0 : defaultTargets?.monthlyTaget?.Mar?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.Mar?.amount
                          let updateValueApr = defaultTargets?.monthlyTaget?.Apr ? defaultTargets?.monthlyTaget?.Apr?.amount === null ? 0 : defaultTargets?.monthlyTaget?.Apr?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.Apr?.amount
                          let updateValueMay = defaultTargets?.monthlyTaget?.May ? defaultTargets?.monthlyTaget?.May?.amount === null ? 0 : defaultTargets?.monthlyTaget?.May?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.May?.amount
                          let updateValueJun = defaultTargets?.monthlyTaget?.Jun ? defaultTargets?.monthlyTaget?.Jun?.amount === null ? 0 : defaultTargets?.monthlyTaget?.Jun?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.Jun?.amount
                          let updateValueJul = defaultTargets?.monthlyTaget?.Jul ? defaultTargets?.monthlyTaget?.Jul?.amount === null ? 0 : defaultTargets?.monthlyTaget?.Jul?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.Jul?.amount
                          let updateValueAug = defaultTargets?.monthlyTaget?.Aug ? defaultTargets?.monthlyTaget?.Aug?.amount === null ? 0 : defaultTargets?.monthlyTaget?.Aug?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.Aug?.amount
                          let updateValueSept = defaultTargets?.monthlyTaget?.Sept ? defaultTargets?.monthlyTaget?.Sept?.amount === null ? 0 : defaultTargets?.monthlyTaget?.Sept?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.Sept?.amount
                          let updateValueOct = defaultTargets?.monthlyTaget?.Aug ? defaultTargets?.monthlyTaget?.Aug?.amount === null ? 0 : defaultTargets?.monthlyTaget?.Aug?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.Aug?.amount
                          let updateValueNov = defaultTargets?.monthlyTaget?.Nov ? defaultTargets?.monthlyTaget?.Nov?.amount === null ? 0 : defaultTargets?.monthlyTaget?.Nov?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.Nov?.amount
                          let updateValueDec = defaultTargets?.monthlyTaget?.Dec ? defaultTargets?.monthlyTaget?.Dec?.amount === null ? 0 : defaultTargets?.monthlyTaget?.Dec?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.monthlyTaget?.Dec?.amount
                          let updateValueQ1 = defaultTargets?.quarterlyTaget?.quarter1 ? defaultTargets?.quarterlyTaget?.quarter1?.amount === null ? 0 : defaultTargets?.quarterlyTaget?.quarter1?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.quarterlyTaget?.quarter1?.amount
                          let updateValueQ2 = defaultTargets?.quarterlyTaget?.quarter2 ? defaultTargets?.quarterlyTaget?.quarter2?.amount === null ? 0 : defaultTargets?.quarterlyTaget?.quarter2?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.quarterlyTaget?.quarter2?.amount
                          let updateValueQ3 = defaultTargets?.quarterlyTaget?.quarter3 ? defaultTargets?.quarterlyTaget?.quarter3?.amount === null ? 0 : defaultTargets?.quarterlyTaget?.quarter3?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.quarterlyTaget?.quarter3?.amount
                          let updateValueQ4 = defaultTargets?.quarterlyTaget?.quarter4 ? defaultTargets?.quarterlyTaget?.quarter4?.amount === null ? 0 : defaultTargets?.quarterlyTaget?.quarter4?.amount : getDealerListSetTargetDetails?.data?.data?.defaultTargets?.quarterlyTaget?.quarter4?.amount

                          let initialValue = selectedTabData
                          // if (!disableMonths.dec) {
                          initialValue.yearlyTaget.amount = updateValueYearly
                          // }
                          // if (!disableMonths.jan) {
                          initialValue.monthlyTaget.Jan.amount = updateValueJan
                          // }
                          // if (!disableMonths.feb) {
                          initialValue.monthlyTaget.Feb.amount = updateValueFeb
                          // }
                          // if (!disableMonths.mar) {
                          initialValue.monthlyTaget.Mar.amount = updateValueMar
                          // }
                          // if (!disableMonths.apr) {
                          initialValue.monthlyTaget.Apr.amount = updateValueApr
                          // }
                          // if (!disableMonths.may) {
                          initialValue.monthlyTaget.May.amount = updateValueMay
                          // }
                          // if (!disableMonths.jun) {
                          initialValue.monthlyTaget.Jun.amount = updateValueJun
                          // }
                          // if (!disableMonths.jul) {
                          initialValue.monthlyTaget.Jul.amount = updateValueJul
                          // }
                          // if (!disableMonths.aug) {
                          initialValue.monthlyTaget.Aug.amount = updateValueAug
                          // }
                          // if (!disableMonths.sep) {
                          initialValue.monthlyTaget.Sept.amount = updateValueSept
                          // }
                          // if (!disableMonths.Oct) {
                          initialValue.monthlyTaget.Oct.amount = updateValueOct
                          // }
                          // if (!disableMonths.nov) {
                          initialValue.monthlyTaget.Nov.amount = updateValueNov
                          // }
                          // if (!disableMonths.dec) {
                          initialValue.monthlyTaget.Dec.amount = updateValueDec
                          // }
                          // if (!disableMonths.mar) {
                          initialValue.quarterlyTaget.quarter1.amount = updateValueQ1
                          // }
                          // if (!disableMonths.jun) {
                          initialValue.quarterlyTaget.quarter2.amount = updateValueQ2
                          // }
                          // if (!disableMonths.sept) {
                          initialValue.quarterlyTaget.quarter3.amount = updateValueQ3
                          // }
                          // if (!disableMonths.dec) {
                          initialValue.quarterlyTaget.quarter4.amount = updateValueQ4
                          // }
                          setSelectedTabData({ ...initialValue });
                          setDataUpdatedPrompt(true)
                          setDataUpdated(true)
                          // setCount(count + 1);
                          // if (count <= 0) {
                          //   setSelectedTabData({...getDealerListSetTargetDetails.data.data.defaultTargets});
                          //   // setLabelDefaultValues(getDealerListSetTargetDetails.data.data.defaultTargets);
                          //   setResetToDefault(true);
                          //   setDisabledValue({...selectedTabData});
                          // }
                        }}
                          className={`resetToDefaultActionButton border-left rounded-pill ml-4 mt-3`}>Reset To Default</Button>
                      </div>
                      <div className="col-6 onboard-footer">
                        <div className="">
                          <Button
                            variant="contained" color="primary"
                            className={`blueActionButtonSave border-left rounded-pill mr-5 mt-2 mb-2`}
                            onClick={() => { setshowApproveDialog(true); setDataUpdated(false); setDataUpdatedPrompt(false); }}
                          > Save</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>
              </ThemeProvider>
            </div>
          ) : (
            <div className="col-12">
              <Alert severity="info">{getDealerListSetTargetDetails?.data?.data?.message}</Alert>
            </div>
          )}
        </>}
      </div>
      <Prompt when={dataUpdatedPrompt} message={"Do you wish to proceed without saving the data?"}></Prompt>
      <DeleteDialog
        open={confirmDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any) => {
          if (data === 'delete' || data === 'back') {
            setselectedTab(newValue);
            setSelectedTabData(JSON.parse(JSON.stringify({ ...getDealerListSetTargetDetails.data.data.targetData.brandTargets[newValue] })))
            handleopenClose(false);
            setDataUpdated(false);
          }
          if (!data) { handleopenClose(false); }
        }} />
      <SetTargetDialog
        open={showApproveDialog}
        openRequestSnackbar={openSnackbar}
        setTargetApiCall={setTargetApiCall}
        setDataUpdatedPrompt={setDataUpdatedPrompt}
        handleopenCloseSnackbar={handleopenCloseSnackbar}
        handleopenClose={(data: any) => {
          setshowApproveDialog(data ? true : false)
        }}
      />
    </Fragment >
  );
};

export default SetTargetDealerList;
